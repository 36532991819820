@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(product-plan-sticky-nav) {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  top: -80px;
  left: 0;
  width: 100%;
  height: 56px;
  transition: all 0.3s ease-in-out;
  z-index: 99999;
  opacity: 0;

  @include media-breakpoint-up(md) {
    top: -105px;
    height: 81px;
  }

  @include m(visible) {
    top: 0;
    opacity: 1;
  }

  .link__text {
    margin-top: 4px;
    margin-left: 10px;
    flex: 1 0;
    font-family: $ted-next;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $color-oxford-blue;
    @include media-breakpoint-only(sm) {
      font-size: 12px;
    }
  }

  @include e(inner) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: max-content minmax(max-content, 1fr);
    grid-template-areas: 'product actions';
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100%;
    max-width: 100vw;

    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
    @include media-breakpoint-up(md) {
      @include make-container();
      @include make-container-max-widths();

      min-width: 100%;
      padding-left: 0;
      padding-right: 0;

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        min-width: $md-min-width;
      }
    }

    @include media-breakpoint-up(lg) {
      min-width: $lg-min-width;
    }
  }

  @include e(left) {
    display: flex;
    height: 100%;
    grid-area: product;

    .msie &,
    .msedge & {
      flex-basis: auto;
    }
  }

  @include e(right) {
    display: flex;
    height: 100%;
    grid-area: actions;
    justify-content: flex-end;

    .msie &,
    .msedge & {
      flex-basis: auto;
    }

    .link {
      .link__linkblock {
        display: flex;
        align-items: center;
        .link__text {
          margin-left: 0;
        }
      }
      &:hover {
        .icon,
        .link__text {
          color: map-get($rogers-colors, primary-light-hover) !important;
        }
        .link__text {
          text-decoration: underline;
        }
      }
    }
  }

  @include e(logo) {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    img {
      max-width: 100%;
    }
  }

  @include e(product-line) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    padding-top: 4px;
    height: 100%;
    font-family: $ted-next;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: $color-oxford-blue;

    @include media-breakpoint-up(md) {
      margin-left: 50px;

      p {
        margin-top: 5px;
      }
    }
  }

  @include e(custom) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;

    .no-touch &:hover {
      .link__text {
        color: map-get($rogers-colors, link-light-hover);
      }

      .icon {
        color: map-get($rogers-colors, link-light-hover);
      }
    }
    @include media-breakpoint-only(lg) {
      flex-direction: row;
      align-items: center;
    }

    .link__text {
      white-space: nowrap;
      transform: translateY(2px);
      @include media-breakpoint-up(md) {
        transform: translateY(0);
      }
    }

    .icon {
      font-size: 20px;
      color: map-get($rogers-colors, link-light);
    }
  }

  @include e(chat) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    @include m(chat-unavailable) {
      pointer-events: none;
      opacity: 0.5;
    }

    .no-touch &:hover {
      .link__text {
        color: map-get($rogers-colors, link-light-hover);
      }

      .icon {
        color: map-get($rogers-colors, link-light-hover);
      }
    }

    @include media-breakpoint-only(lg) {
      flex-direction: row;
      align-items: center;
      // margin-right: 0;
    }

    .link__text {
      display: none;

      @include media-breakpoint-only(lg) {
        display: table-cell;
      }
    }

    .icon {
      font-size: 20px;
      color: $color-oxford-blue;
    }
  }

  @include e(phone) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 24px;

    .no-touch &:hover {
      .link__text {
        color: map-get($rogers-colors, link-light-hover);
      }

      .icon {
        color: map-get($rogers-colors, link-light-hover);
      }
    }

    .msie &,
    .msedge & {
      flex: 1 1 auto;
    }

    @include media-breakpoint-only(lg) {
      flex-direction: row;
      align-items: center;
      margin-right: 34px;
      flex-shrink: 0;
    }

    .link__text {
      display: none;

      @include media-breakpoint-only(lg) {
        display: table-cell;
      }
    }

    .icon {
      font-size: 20px;
      color: $color-oxford-blue;
    }

    svg {
      max-height: 22px;
    }
  }

  @include e(cta-container) {
    display: flex;
    margin-left: 9px;

    @include media-breakpoint-only(sm) {
      margin-left: 0;
    }
    @include media-breakpoint-only(lg) {
      margin-left: 40px;
    }
  }

  @include e(cta) {
    border: 0;
    width: 110px;
    background-color: map-get($rogers-colors, primary-light);
    color: white;
    font-family: $ted-next;
    font-weight: $demibold;
    font-size: 14px;
    line-height: 1.33;
    letter-spacing: 0.1px;
    text-align: center;
    cursor: pointer;
    padding-top: 0;
    border-radius: 0;

    .no-touch &:hover {
      background-color: map-get($rogers-colors, primary-light-hover);
      text-decoration: underline;
    }

    @include media-breakpoint-up(md) {
      width: 170px;
      font-size: 16px;
    }

    &.link {
      display: flex;
      justify-content: center;
      align-items: center;
      .link__text {
        letter-spacing: 0.1px;
        color: white;
      }
    }
  }
}
