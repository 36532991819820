// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Component styles
@include b(global-header) {
  $header-height: 46px;
  background-color: $default-bg-color;

  display: none;
  position: relative;
  z-index: 10001;
  @include m(scotch) {
    .no-touch & {
      &.global-header {
        .button--link {
          outline: none;
          line-height: 1em;
          &:hover {
            color: map-get($rogers-colors, primary-light);
          }
        }
        .global-header__tab {
          &:hover {
            color: map-get($rogers-colors, primary-light);
          }
        }
        .region-selector__location {
          color: map-get($rogers-colors, body);
          text-decoration: underline;
          outline: none;
          padding: 0;
          border: 0;
          transform: translateY(-1px);
          span {
            text-decoration: underline;
          }
          &:hover {
            color: map-get($rogers-colors, primary-light);
          }
        }
      }
    }
  }

  @include e(container) {
    @include make-container();
    @include make-container-max-widths();

    height: $header-height;
    display: flex;
    align-items: center;
    list-style-type: none;

    @include m(paddingless) {
      padding: 0;
    }
  }

  @include e(center) {
    flex: 1;
    margin: 0 24px;
  }

  @include e(tabs) {
    height: 100%;
    display: flex;
    margin-right: 30px;
    list-style-type: none;
  }

  @include e(tab) {
    height: 100%;
    padding-top: 14px;

    .button--link {
      color: $color-oxford-blue;
      padding: 0 20px;
      font-size: 14px;
      font-family: $ted-next;
      font-weight: $medium;
      text-decoration: none;

      .no-touch &:hover {
        text-decoration: underline;
        color: map-get($colors, secondary);
      }
    }

    @include m(active) {
      background-color: $color-white;
      .button--link {
        font-weight: 700;
        color: black;
        &:hover {
          color: map-get($colors, secondary);
        }
      }
    }
  }

  @include e(links) {
    display: flex;
    margin-top: 2px;
    text-decoration: none;
    list-style-type: none;

    &:last-child {
      flex: 1;
      justify-content: flex-end;
    }
  }

  @include e(link) {
    &:last-child {
      a {
        padding-right: 0;
      }
    }

    .button--link {
      color: $color-oxford-blue;
      padding: 0 10px;
      font-size: 14px;
      font-family: $ted-next;
      font-weight: $medium;
      text-decoration: none;
      line-height: 15px;

      &:hover {
        text-decoration: underline;
        color: map-get($colors, secondary);
      }

      &.is-active,
      &.button--is-active {
        font-family: $ted-next;
        font-weight: $demibold;
      }
    }

    & + & {
      border-left: 1px solid $color-oxford-blue;
    }

    @include m(active) {
      .button--link {
        color: black;
        font-weight: 700;
        &:hover {
          color: map-get($colors, secondary);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }
}
