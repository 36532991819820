@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

// Module styles
.global-alert-message {
  background-color: map-get($rogers-colors, primary-light );
  width: 100%;
  display: flex;

  @include e(container) {
    @include make-container();
    @include make-container-max-widths();
    padding-top: 12px;
    padding-bottom: 12px;
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-areas: 'icon message';
    grid-gap: 10px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    @include media-breakpoint-only(sm) {
      margin: auto;
      justify-self: center;
      p {
        width: auto;
        max-width: 98%;
        text-align: center;
        line-height: 1.3em;
      }
    }
  }

  @include e(icon) {
    grid-area: icon;
    display: flex;
    align-items: center;
    color: white;
    height: 100%;
    background-color: transparent;
    border: none;
    max-width: 24px;
  }

  @include e(label-container) {
    display: flex;
    align-items: baseline;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: center;
    }
  }

  @include e(message) {
    grid-area: message;
    color: $color-white;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;

    .button {
      color: $color-white;
      &:hover {
        color: $color-white !important;
        text-decoration: underline;
      }
    }

    .button--link-caret-white {
      font-weight: $demibold;
      &:after{
        font-family: 'shaw-icons' !important;
        content: '\e900';
        font-size: 16px;
        display: inline-block;
        text-decoration: none !important;
        transform: translateY(3px);
      }
    }

    .button--link-caret,
    .button--link-caret-contrast {
      font-size: 12px;
      margin-left: 3px;
      position: relative;
      white-space: nowrap;

      .button--icon {
        position: absolute;
        bottom: 1px;
        right: -28px;
      }
    }

    .icon-chevron {
      font-size: 5px;
      margin-left: 1px;
    }
  }

  &.bottom-alert {
    background-color: $color-alert;
    position: fixed;
    width: 100%;
    min-height: 40px;
    bottom: 0;
    z-index: 999;
    display: none;

    @supports (display: grid) {
      display: none;
    }

    &.show {
      display: block;
      .microsoft-edge & {
        bottom: auto;
        display: block;
        top: calc(100vh - 76px);
      }
      .microsoft-edge-18 & {
        bottom: 0;
        top: auto;
      }
    }
    &.hide {
      display: none;
    }

    &.reverse {
      .global-alert-message__container {
        flex-direction: row-reverse;
      }
      .global-alert-message__icon {
        margin-left: auto;
        margin-right: 0;
      }
    }

    .global-alert-message__container {
      display: flex;
      align-items: center;
    }

    .global-alert-message__icon {
      color: $color-black;
      font-size: 12px;
    }
    .global-alert-message__message {
      color: $color-black;
    }
  }
}
