// Application mixins
@import 'style/utils/bem';
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Module styles
@include b('basic-icon-boxes') {
  padding-bottom: 100px;

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();
  }

  @include e('intro') {
    @include make-container();

    background-color: $color-white;
    padding: 80px 0;
  }

  @include e('title') {
    @include make-col(8);

    color: $color-oxford-blue;
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
    font-family: $ted-next;
    font-weight: 700;
  }

  @include e('description') {
    @include make-col(10);

    color: $color-bluish-grey;
    margin: 20px auto;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
  }

  @include e('boxes') {
    padding: 80px 0 0;
  }

  @include e('box') {
    background-color: $color-white;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding-bottom: 40px;
    margin-bottom: 80px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .basic-icon-box__icon {
      position: absolute;
      top: -35px;
      left: calc(50% - 35px);
      width: 70px;
      height: 70px;
      color: $color-primary;
      display: flex;
      background: #fff;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .icon::before {
      font-size: 40px;
    }

    .basic-icon-box__title {
      font-family: $ted-next;
      font-size: 28px;
      margin: 75px 0 40px;
      min-height: unset;
      max-height: unset;
    }

    .basic-icon-box__description {
      min-height: unset;
      max-height: unset;
      max-width: 600px;
      margin: 0 auto;
    }

    .basic-icon-box__cta-button {
      margin-top: 40px;

      &.button--secondary {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
