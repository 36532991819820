// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';
@import 'app/components/IconBadge/IconBadge';
@import 'style/utils/bem';

// Module styles
@include b('outstanding-cta-with-text') {
  padding-top: 100px;
  display: grid;
  background-color: white;
  padding-bottom: 45px;
  max-width: 100vw;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 86px 988px 86px 1fr;
    grid-template-rows: 1fr 169px 371px;
  }

  @include media-breakpoint-only(md) {
    grid-template-columns: 30px 68px auto 68px 30px;
    grid-template-rows: 1fr 64px 208px;
  }

  @include media-breakpoint-only(sm) {
    grid-template-columns: 24px 20px auto 20px 24px;
    grid-template-rows: 1fr 72px 128px;
  }

  @include e('container') {
    grid-row: 1 / span 2;
    grid-column: 3 / 4;

    .card__content {
      max-width: 70%;
      margin-left: auto;
      margin-right: auto;

      .contact-card__title {
        margin-top: 30px;
        margin-bottom: 25px;
      }

      .contact-card__description {
        line-height: 1.6em;
      }

      @include media-breakpoint-only(sm) {
        max-width: none;

        .contact-card__title {
          padding-left: 15px;
          padding-right: 15px;
        }

        .contact-card__buttons {
          margin: 0;

          .contact-card__button {
            padding-left: 0;
            padding-right: 0;

            .button {
              width: auto;
              min-width: 200px;
            }
          }
        }
      }
    }
  }

  @include e('background') {
    grid-row: 2 / span 2;
    grid-column: 2 / span 3;
    display: flex;

    img {
      object-fit: cover;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
}
