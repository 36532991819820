// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Module styles
@include b('subscription-form') {
  background-color: $color-white;

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();

    padding: 80px 0;

    h3,
    h2 {
      font-family: $ted-next;
      font-size: 32px;
      color: $color-oxford-blue;
      margin-bottom: 20px;
    }

    fieldset {
      border: none;
    }

    fieldset label,
    fieldset input[type='text'] {
      display: block;
    }

    label {
      font-weight: normal;
      margin-bottom: 5px;
    }
  }

  @include e('form-container') {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    color: $color-storm-grey;

    p {
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block;
      padding: 0 20px;
    }
  }

  @include e('image-container') {
    width: 40%;
    display: inline-block;
    vertical-align: top;
    padding-left: 30px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  @include e('intro') {
    margin-bottom: 30px;
    line-height: 1.6em;
  }

  @include e('subscribe') {
    margin: 20px 0 30px;
  }
  @include e('footer') {
    margin: 10px 0 30px;
    padding-top: 20px;
    font-size: 12px;
    border-top: 1px solid #eee;
  }

  @include e('input-group') {
    margin-bottom: 20px;
  }

  @include e('form') {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  @include e('input-label') {
    display: flex;
    flex-direction: column;
    input {
      margin-top: 10px;
      max-width: 500px;
      font-size: 18px;
      border: 1px solid map-get($colors-neutral, light);
      padding: map-get($copy-spacing, sm);
      height: map-get($component-spacing, sm);
      padding-left: 15px;
      font-family: $ted-next;
      border-radius: 3px;

      &:focus-visible {
        outline: 1px solid black;
      }
    }
    @include m(checkbox) {
      display: grid;
      grid-template-columns: 20px 1fr;
      grid-template-rows: 1fr min-content;
      grid-template-areas: 'checkbox label' 'error error';
      align-items: center;

      input {
        width: 1.3em;
        height: 1.3em;
        grid-area: checkbox;
        margin-right: 10px;
        margin-top: 0;
        &:focus-visible {
          outline: none;
        }
      }
      .subscription-form__consent-label {
        grid-area: label;
      }
      .subscription-form__error {
        grid-area: error;
      }
    }
  }
  @include e('form-input') {
    width: 100%;
    padding: 5px 10px;
    font-size: 15px;
  }

  @include e('error') {
    display: block;
    margin-top: 5px;
    color: map-get($colors-alert, error);
    font-size: 12px;
  }
  @include e('submission-error') {
    display: block;
    margin-top: 5px;
    color: map-get($colors-alert, error);
    font-size: 12px;
  }

  @include e('consent-label') {
    display: inline-block;
    overflow: hidden;
    padding-left: 10px;
    vertical-align: top;
    width: calc(100% - 20px);

    p {
      display: inline;
      vertical-align: top;
    }
  }

  @include e('recaptcha-badge') {
    position: absolute;
    right: 0;
    @include m(success) {
      display: none;
      height: 0;
    }
  }
}
