// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.comparison-table {
  .c-comparison-product-list__select {
    white-space: break-spaces;
    line-height: 1.1em;
  }
  .css-suew8d-singleValue {
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
      font-size: 13px;
    }
  }
  .c-comparison-table__title {
    @include h2();
    margin-bottom: 30px;
  }
  .c-comparison-table__description {
    max-width: 640px;
    margin: auto;
  }
  @include media-breakpoint-up(md) {
    .c-comparison-table__features-row.header > * {
      text-align: center;
    }
  }
  @include media-breakpoint-only(sm) {
    .c-comparison-table__features-row.header {
      grid-column-gap: 0;
    }
  }
  .c-comparison-table__features-row.header .select-field {
    line-height: 1.3em;
  }
  @include m(offset) {
    .c-comparison-table__features-row.header--sticky {
      top: 56px;
      @include media-breakpoint-up(md) {
        top: 80px;
      }
    }
  }
  .c-comparison-table--scotch {
    .c-comparison-table__features-row.header {
      color: map-get($rogers-colors, body);
    }
  }
}
