// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

.c-accordion {
  .c-accordion__title {
    margin: map-get($copy-spacing, lg) 0;
  }

  .c-accordion__content {
    a {
      color: map-get($rogers-colors, link-light);
      // font-weight: normal;
      outline: none;
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      margin: map-get($copy-spacing, md);
      li {
        margin: map-get($copy-spacing, md) 0;
      }
    }
    p {
      margin: map-get($copy-spacing, md) 0;
    }

    img {
      max-width: 100%;
    }
  }
}
