// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

.support-article {
  color: $color-oxford-blue;
  .c-accordion {
    overflow: visible;
    padding: 0;
    padding-top: 46px;
    border-top: 1px solid map-get($colors-neutral, light) !important;
    .c-eyebrow {
      margin-bottom: 15px;
    }
    .c-accordion__title {
      font-family: $ted-next !important;
      font-weight: $bold;
      margin-bottom: 15px;
      line-height: 1.2em !important;
    }

    .c-accordion__description {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .c-accordion__accordions {
      margin-top: 20px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
    }
    .c-accordion-item {
      &:first-child {
        .c-accordion-item__header {
          border-top: 0;
        }
      }
    }

    .c-accordion-item--scotch-aqua .c-accordion__content {
      a {
        color: map-get($colors, primary);

        &:hover {
          color: map-get($colors, secondary);
        }
      }
    }
  }

  .scroll-to-top {
    grid-area: scroller;
    cursor: pointer;
    color: $color-white;
    position: absolute;
    position: sticky;
    right: -50px;
    top: 95%;
    transform: translate3d(40px, 0, 0);
    width: 86px;
    padding: 12px!important;
    max-height: 40px;
    padding: 0;
    transition: visibility 0.3s ease-in-out;
    @include media-breakpoint-down(md) {
      display: none;
    }
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        transform: rotate(180deg) translateY(2px);
        margin-right: 5px;
      }
    }
  }

  @include e('content-wrapper') {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 30px 1fr 30px;
    grid-template-areas: 'padding content scroller';
    position: relative;
    top: -60px;
    z-index: 9999;
    background-color: $color-white;
    max-width: 866px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    border: 1px solid map-get($colors-neutral, light);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down(sm) {
      grid-template-columns: 10px 1fr 10px;
    }
  }

  @include e('content') {
    padding: 30px;
    grid-area: content;

    @include media-breakpoint-down(sm) {
      padding: 30px 10px;
    }
  }

  @include e('eyebrow') {
    display: block;
    margin: 15px 20px 35px auto;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: left;
    line-height: 1;
  }

  @include e('breadcrumbs') {
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.14);
  }

  @include e('breadcrumbs-container') {
    $fontsize: 12px;
    $fontheight: 14px;

    font-size: 1.1em;
    padding: 20px 0 13px;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      margin-top: 3px;
    }

    li {
      display: inline-block;
      vertical-align: top;
      font-size: $fontsize;
      line-height: $fontheight;
      color: $color-bluish-grey;
    }

    li:last-of-type::after {
      content: none;
    }

    li > a {
      font-family: $ted-next;
      color: $color-azure;
      font-size: $fontsize;
      line-height: $fontheight;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 20px 20px 13px;
    }
  }

  @include e('content-intro') {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  @include e('description') {
    @include large-body();
    padding: 0 15px 30px 30px;
    width: 66%;
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-bottom: 30px;
      width: 100%;
    }
  }
  @include e('contents') {
    padding: 0 30px 0px 0;
    flex-basis: 260px;
    display: block;
    border-right: 1px solid map-get($colors-neutral, light);
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
      flex-basis: 0;
      padding-bottom: 20px;
      border: 0;
      border-bottom: 1px solid map-get($colors-neutral, light);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: block;
        margin-top: 20px;
        line-height: 1.6em;
        &:first-child {
          margin-top: 0;
        }
      }
      a {
        font-size: 14px;
        font-weight: $demibold;
        color: $color-primary;
        cursor: pointer;
        line-height: 1.3em;
        display: inline-block;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          color: map-get($colors, secondary);
        }
      }
    }
  }

  @include m(scotch) {
    .support-article__contents ul a {
      outline: none;
      color: map-get($rogers-colors, secondary-light);
      &:hover {
        color: map-get($rogers-colors, secondary-light-hover);
      }
    }
    .support-artice-section .rich-text-container a {
      outline: none;
      color: map-get($rogers-colors, secondary-light);
      &:hover {
        color: map-get($rogers-colors, secondary-light-hover);
      }
    }

    .scroll-to-top span svg {
      transform: rotate(180deg) translateY(0px);
    }
  }

  .c-accordion + .support-artice-section {
    margin-top: 30px;
  }
}
