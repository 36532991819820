// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';

@include b(support-outage-hero) {
  background-color: white;
  .support-search-bar__btn-search span {
    outline: none;
    background-color: #da291c;
  }
  .gradient-hero--with-breadcrumbs .hero .hero__heading {
    font-size: 34px;
  }

  .hero.hero--with-breadcrumbs {
    .support-article__breadcrumbs .support-article__breadcrumbs-container {
      background-color: #cac9c7;
      ul > li {
        line-height: 14px;
        a {
          color: #1f1f1f;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        color: #1f1f1f;
      }
    }
  }
}
