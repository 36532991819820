@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

.white {
  .product-plan-card-carousel__title,
  .product-plan-card-carousel__description {
    color: white;
  }

  .slider-arrow--disabled.slider-arrow--previous .icon::before,
  .slider-arrow--disabled.slider-arrow--next .icon::before {
    color: $color-fog-dark;
  }

  .slider-arrow--previous .icon::before,
  .slider-arrow--next .icon::before {
    color: $color-white;
  }
  .product-plan-card-carousel__cta {
    color: $color-white;
    border-color: $color-white;
    font-weight: $demibold;
  }
}
@include b(product-plan-card-carousel) {
  padding: map-get($component-spacing, sm) 0;
  background-color: transparent;
  @include media-breakpoint-up(md) {
    padding: map-get($component-spacing, md) 0;
  }
  @include media-breakpoint-up(lg) {
    padding: map-get($component-spacing, lg) 0;
  }
  .notification__container {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 1090px;
    margin-top: 14px;
    .notification {
      max-width: 100%;
      min-width: 100%;
    }
  }

  @include m(scotch) {
    .carousel__dots li {
      outline: none;
      border-color: map-get($rogers-colors, grey);
      button {
        outline: none;
      }
    }
    .carousel__dots li.slick-active {
      background-color: map-get($rogers-colors, primary-light);
    }
    .slider-arrow.slider-arrow--next {
      .icon.icon-Link_ChevronBlue:before {
        color: #1f1f1f;
      }
    }
    .slider-arrow.slider-arrow--previous {
      .icon.icon-Link_ChevronBlue:before {
        color: #1f1f1f;
      }
    }
    .slider-arrow--disabled.slider-arrow.slider-arrow--next {
      .icon.icon-Link_ChevronBlue:before {
        color: #d8d8d8;
      }
    }
    .slider-arrow--disabled.slider-arrow.slider-arrow--previous {
      .icon.icon-Link_ChevronBlue:before {
        color: #d8d8d8;
      }
    }
  }
  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  @include e(inner) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1024px;
    margin: 0 auto;
    position: relative;

    @include media-breakpoint-only(sm) {
      max-width: 100%;
      max-width: 100vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 1010px) {
      max-width: 708px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 1180px;
    }
  }

  @include e(title) {
    text-align: center;
    color: $color-oxford-blue;
    @include h2();

    @include media-breakpoint-only(sm) {
      max-width: 310px;
    }
  }

  @include e(description) {
    margin-top: 12px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: center;
    color: $color-bluish-grey;
    margin-bottom: calc(#{map-get($component-spacing, lg)} - 30px);
    @include media-breakpoint-only(md) {
      margin-bottom: calc(#{map-get($component-spacing, md)} - 30px);
    }
    @include media-breakpoint-only(sm) {
      margin-bottom: calc(#{map-get($component-spacing, sm)} - 30px);
    }

    @include media-breakpoint-only(sm) {
      max-width: 310px;
    }

    @include media-breakpoint-only(md) {
      margin-top: 16px;
    }
  }

  @include e(card-wrapper) {
    margin-left: 5px;
    margin-right: 5px;
    min-width: max-content;
  }

  @include e(carousel) {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;

    @include media-breakpoint-only(sm) {
      padding-left: 0;
      padding-right: 0;
    }

    .slick-slide {
      & > div {
        display: flex;
        justify-content: center;
      }
    }
  }

  @include e(card-group) {
    display: flex;
    justify-content: center;
    width: 100%;

    @include m(no-carousel) {
      margin-top: 30px;

      @include media-breakpoint-up(lg) {
        margin-top: 44px;
        justify-content: center;
      }
    }
  }

  .carousel__dots {
    margin-top: 32px;
  }

  @include e(cta) {
    line-height: 16px;
    margin-top: 28px;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: $demibold!important;

    &:hover {
      text-decoration: underline;
    }

    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }

    .link__text {
      display: flex;
      align-items: center;
      padding-left: 0;
      column-gap: 4px;
      @include media-breakpoint-only(sm) {
        text-align: center;
        line-height: 1;
      }

      svg {
        max-height: 40px;
        height: 20px;
      }

      &:hover {
        .product-plan-card-carousel__cta-icon {
          color: white;
          .product-plan-card-carousel--scotch & {
            color: inherit;
          }
        }

        .product-plan-card-carousel__cta-text {
          color: white;
          .product-plan-card-carousel--scotch & {
            color: inherit;
          }
        }
      }
    }
  }

  @include e(cta-icon) {
    display: inline-block;
    margin-left: 10px;
    height: auto;

    svg {
      height: 34px;
      width: 34px;
      display: block;
      margin-left: 10px;
    }
  }

  @include e(cta-text) {
    margin-top: 5px;
    font-weight: $demibold;
    .button__scotch-primary &,
    .button__scotch-secondary & {
      margin-top: 0;
    }
  }

  @include e(sticky-nav) {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    top: -60px;
    left: 0;
    width: 100%;
    height: 56px;
    transition: all 0.5s ease-in-out;
    z-index: 1;

    @include media-breakpoint-up(md) {
      top: -85px;
      height: 81px;
    }

    @include m(visible) {
      top: 0;
    }

    .link__text {
      margin-top: 4px;
      margin-left: 10px;
      flex: 1 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5;
      color: $color-primary;
    }
  }

  @include e(sticky-nav-inner) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100%;

    @include media-breakpoint-up(md) {
      @include make-container();
      @include make-container-max-widths();
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
      max-width: 998px;
    }
  }

  @include e(sticky-left) {
    display: flex;
    height: 100%;
    flex: 1 0;
  }

  @include e(sticky-right) {
    display: flex;
    height: 100%;
    flex: 1 0;
    justify-content: flex-end;
  }

  @include e(sticky-nav-logo) {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 170px;
    }

    img {
      max-width: 100%;
    }
  }

  @include e(sticky-nav-product-line) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    padding-top: 4px;
    height: 100%;
    font-family: $ted-next;
    font-size: 16px;
    font-weight: $demibold;
    line-height: 1.5;
    color: $color-oxford-blue;

    @include media-breakpoint-up(md) {
      margin-left: 50px;

      p {
        margin-top: 5px;
      }
    }
  }

  @include e(sticky-nav-chat) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 24px;

    @include media-breakpoint-only(lg) {
      flex-direction: row;
      align-items: center;
      margin-right: 40px;
    }

    .link__text {
      display: none;

      @include media-breakpoint-only(lg) {
        display: inline-block;
      }
    }

    .icon {
      font-size: 20px;
      color: $color-oxford-blue;
    }
  }

  @include e(sticky-nav-phone) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 24px;

    @include media-breakpoint-only(lg) {
      flex-direction: row;
      align-items: center;
      margin-right: 34px;
    }

    .link__text {
      display: none;

      @include media-breakpoint-only(lg) {
        display: inline-block;
      }
    }

    .icon {
      font-size: 20px;
      color: $color-oxford-blue;
    }
  }

  @include e(sticky-nav-cta) {
    border: 0;
    width: 110px;
    height: 100%;
    background-color: #00a6ef;
    color: white;
    font-family: $ted-next;
    font-weight: $medium;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.1px;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 170px;
      font-size: 16px;
    }
  }

  @include m(with-cta) {
    @include e(slider-arrow) {
      top: 54%;
    }
  }

  @include m(server) {
    .product-plan-card-carousel__card-group {
      min-width: 960px;
    }
  }
}
