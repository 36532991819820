// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(share-button) {
  position: relative;
  .at-resp-share-element.at-mobile.at-label {
    display: inline-block !important;
  }
  .at-share-btn {
    display: inline-flex;
    justify-content: center;
    background-color: map-get($colors, primary) !important;
    border-radius: 100px !important;
    width: 100px;
    .at-icon-wrapper {
      display: none;
    }
    .at-label {
      display: inline-block !important;
      visibility: hidden;
      margin-left: 10px !important;
      display: inline-block;
      padding: 0 !important;
      @include media-breakpoint-only(sm) {
        margin-left: 0 !important;
      }
      &:before {
        display: inline-block;
        transform: translateX(15px);
        content: 'Share';
        visibility: visible;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .at4-share-count-container {
      display: none;
    }
  }
  .share-button {
    border: 0;
    background-color: map-get($colors, primary);
    display: inline-flex;
    border-radius: 100px;
    padding: map-get($copy-spacing, sm) map-get($copy-spacing, xl);
    color: white;
    font-size: 16px;
    font-family: $ted-next;
    font-weight: 500;
    line-height: map-get($copy-spacing, lg);
    cursor: pointer;
  }
  @include e(share) {
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
    text-align: center;
    width: 200px;
    background: white;
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
    padding: map-get($copy-spacing, sm) map-get($copy-spacing, sm);
    @include m(on) {
      display: block;
    }
    @include m(off) {
      display: none;
    }

    .react-share__ShareButton {
      margin: 0 map-get($copy-spacing, sm);
      svg {
        color: map-get($colors, primary);
        width: 28px;
        height: 28px;
      }
    }
  }
}
