// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Module styles

@include b(theme-pack) {
  padding: 30px 15px;

  @include e(wrapper) {
    width: 100%;

    &:not(.child) {
      border-bottom: 1px solid $color-fog;

      &:last-child {
        border-bottom: 0;
      }
    }

    &.non-collapsable {
      .collapsable-card {
        .collapsable-card__container {
          background: $color-search-grey;
          border-top: 1px solid transparent;
        }
      }
    }

    .collapsable-card {
      margin: auto;

      .collapsable-card__container {
        margin: 0;
        box-shadow: none;
        border-radius: 0;
        border-top: 1px solid $color-geyser;
      }

      .collapsable-card__main-content {
        align-content: center;
        justify-content: space-between;
        padding: 30px 15px 30px 30px;

        .theme-pack__info {
          margin: auto 0;
          width: 100%;

          .theme-pack__theme-name {
            margin: auto;
            font-size: 18px;

            @include media-breakpoint-up(md) {
              font-size: 28px;
            }

            .theme-pack__theme-price {
              font-weight: 400;
            }

            .theme-pack__theme-price-sup {
              font-weight: 400;
              font-size: 0.5em;
            }
          }
        }
      }

      .collapsable-card__overflow-content {
        .theme-pack__pack {
          margin: 30px;
        }
      }

      .collapsable-card__toggle-container {
        position: relative;
        margin-left: 30px;

        .collapsable-card__toggle {
          transform: translate(0);

          &:hover {
            background: linear-gradient(
              90deg,
              map-get($colors-gradient, hover-start),
              map-get($colors-gradient, hover-end)
            );
            .chevron {
              &:before,
              &:after {
                background-color: white !important;
              }
            }
          }
        }
      }
    }
  }

  @include e(theme-name) {
    font-family: $ted-next;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @include e(theme-description) {
    color: $color-promise-grey;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
  }

  @include e(theme-link) {
    color: map-get($rogers-colors, link-dark);
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 400;
    span {
      padding: 0;
      border-bottom: 1.5px solid transparent;
    }
    &:hover {
      span {
        border-bottom-color: map-get($rogers-colors, link-dark);
      }
    }
  }

  @include m(premium) {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    grid-template-areas:
      'info channels'
      'info channels';
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      @include make-container-max-widths();

      padding: 50px 0;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'info'
        'channels';
    }

    @include e(theme-name) {
      margin-bottom: 7px;
      text-align: center;
      display: block;
    }

    @include e(theme-description) {
      margin-bottom: 15px;
    }

    @include e(info) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-area: info;
    }

    @include e(pack) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-area: channels;
      @include media-breakpoint-down(md) {
        margin: 0 auto;
      }

      img {
        height: 70px;
      }
    }

    @include e(channel) {
      margin: 30px 0;
      grid-template-columns: minmax(100px, min-content) 1fr;
    }

    @include e(channel-name) {
      font-family: $ted-next;
      margin-bottom: 0;
    }
  }

  @include e(pack) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;

    @include media-breakpoint-up(md) {
      grid-column-gap: 30px;
      grid-row-gap: 15px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);

      .theme-pack__wrapper.child & {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    img {
      height: 32px;
    }

    .button {
      grid-column: 1 / -1;
      align-self: center;
      justify-self: center;
      margin-top: 5px;
    }
  }

  @include e(channel) {
    display: grid;
    grid-template-columns: minmax(60px, min-content) 1fr;
    grid-column-gap: 10px;
    align-items: center;
    margin: 5px 0;

    @include media-breakpoint-only(md) {
      width: 80%;
    }
  }

  @include e(channel-name) {
    font-weight: 700;
    font-size: 18px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  @include e(channel-description) {
    color: $color-body;
    line-height: 1.4em;
  }

  @include e(channel-price) {
    color: $color-body;
    line-height: 1.4em;
  }
}
