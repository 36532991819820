// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

// Module styles
@include b(shaw-promise) {
  $title-font-size-mobile: 28px;
  $title-font-size: 36px;

  background-color: $default-bg-color;

  @include e(container) {
    @include make-container;
    @include make-container-max-widths();

    padding: map-get($component-spacing, lg) 19px;
    @include media-breakpoint-only(md) {
      padding: map-get($component-spacing, md) 19px;
    }
    @include media-breakpoint-only(sm) {
      padding: map-get($component-spacing, sm) 19px;
    }
  }

  @include e(title) {
    @include make-col(12);

    align-items: center;
    color: $color-promise-grey;
    display: block;
    font-family: $ted-next;
    font-size: $title-font-size-mobile;
    font-weight: 100;
    justify-content: center;
    margin: 0 auto;
    max-width: 80%;
    text-align: left;

    @media (min-width: 330px) {
      display: flex;
      max-width: 100%;
      text-align: center;
      word-wrap: break-word;
    }

    @include media-breakpoint-up(md) {
      font-size: 30px;
      span {
        font-size: 26px;
        transform: translateY(-12px);
      }
    }
  }

  @include e(description) {
    max-width: 84%;
    color: $color-bluish-grey;
    font-size: 18px;
    line-height: 1.5;
    margin: 0 auto 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      max-width: 75%;
      font-size: 16px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 55%;
    }
  }

  @include e(pre-svg) {
    display: block;
    text-align: center;
  }

  @include e(svg-container) {
    display: inline;
    padding-top: $title-font-size-mobile + 3px;
    padding-right: 100%;
    position: relative;
    margin: 0;
    left: calc(50% - 103px);

    > svg {
      position: absolute;
      max-width: $title-font-size-mobile * 6.8;
      bottom: 0;
      height: $title-font-size-mobile + 6px;
    }

    @media (min-width: 330px) {
      padding-right: $title-font-size-mobile * 6.8;
      margin: 0 6px;
      left: auto;
    }

    @include media-breakpoint-up(md) {
      margin-left: 12px;
      padding-top: $title-font-size + 4px;
      padding-right: $title-font-size-mobile * 8;

      > svg {
        bottom: 2px;
        height: $title-font-size + 5px;
        max-width: none;
      }
    }
  }
}
