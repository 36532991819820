// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';
@import 'style/utils/clearfix';

.white {
  .featured-solutions__content h2,
  .featured-solutions__content h3 {
    color: white;
  }
}

.featured-solutions {
  position: relative;
  background-color: transparent;
  padding: map-get($component-spacing, sm) map-get($copy-spacing, sm);

  @include media-breakpoint-up(md) {
    padding: map-get($component-spacing, md);
  }
  @include media-breakpoint-up(lg) {
    padding: map-get($component-spacing, lg);
  }

  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  @include e('content-wrapper') {
    @include make-container();
    @include make-container-max-widths();

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  @include e('items') {
    @include clearfix();

    text-align: center;
    margin: 0 -25px;

    @include media-breakpoint-down(md) {
      text-align: left;
      padding-top: 0;
      margin: 0;
    }
  }

  @include e('stacked') {
    display: flex;
    justify-content: center;

    > div {
      width: 33%;
      margin: 40px 0 0 0;
    }

    @include media-breakpoint-only(md) {
      > div {
        margin: 0 20px;
      }
    }
    @include media-breakpoint-down(md) {
      > div {
        width: 100%;
        max-width: 320px;
        margin-top: 0px;

        .featured-solutions__icon-box-body {
          width: 100%;
        }

        @include media-breakpoint-only(md) {
          @media only screen and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
            max-width: 280px;
          }

          @media only screen and (orientation: portrait) and (min-device-width: 834px) {
            max-width: 320px;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      > div {
        width: 100%;

        .featured-solutions__icon-box-body {
          width: 90%;
        }
      }
    }
  }

  @include e('icon-box-body') {
    display: block;
    width: 90%;
    margin: 42px auto 5px auto;
  }

  @include e('box') {
    background-color: $color-white;
    border: 1px solid #f5f8fb;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    padding: 40px 30px 30px !important;

    @include media-breakpoint-only(md) {
      @media only screen and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        padding: 30px 10px !important;
      }
    }

    &.basic-icon-box {
      grid-template-rows: minmax(60px, max-content) repeat(3, min-content);
      .basic-icon-box__icon {
        margin-top: 20px;
      }
    }
  }

  h2 {
    @include h2();
    text-align: center;
  }

  @include media-breakpoint-up(lg) {
    .slick-slide {
      width: 33%;
      vertical-align: top;
    }
  }

  .basic-icon-box__icon {
    width: 75px;
    height: 75px;
    border: 1px solid #d4dfe3;
    background-color: $color-white;
    position: absolute;
    left: calc(50% - 37px);
    top: -43px;
    padding: 16px;
    border-radius: 100%;
  }

  .basic-icon-box__title {
    font-family: $ted-next;
    color: $color-oxford-blue;
  }
}
