// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

@include b('hero-support-search') {
  $eyebrow-font-size: 20px;
  padding-bottom: 35px;
  background-color: $color-white;
  .eyebrow {
    margin-bottom: map-get($copy-spacing, sm);
    @include media-breakpoint-up(lg) {
      margin-bottom: map-get($copy-spacing, md);
    }
  }
  @include e(description) {
    text-align: center;
    font-family: $ted-next;
    margin: auto;
    margin-top: map-get($copy-spacing, sm);
    max-width: 90%;
    line-height: 1.6em;
    @include media-breakpoint-up(lg) {
      margin-top: map-get($copy-spacing, md);
      max-width: 800px;
    }
  }
  @include e('alt-layout') {
    .hero-support-search__aligned {
      text-align: center;
    }

    .hero-support-search__heading {
      margin: 0 auto 20px;

      @include h1();
    }

    .hero-support-search__description {
      p {
        font-size: 20px;
        margin: 0.5em 0;
        text-align: center;
        font-family: $ted-next;

        @include media-breakpoint-down(sm) {
          font-size: 22px;
        }
      }
    }

    .hero-support-search__search-bar-container {
      transform: translateY(50%);
      margin: 0;
      position: relative;
      z-index: 3;
      box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.23);
      width: 100%;

      @include media-breakpoint-down(md) {
        padding: 27px 15px;
      }
    }

    .hero-support-search__hero-bg {
      padding-top: 45px;
      height: auto;
    }
  }

  @include e('hero-bg') {
    height: 300px;
    color: $color-white;
    font-family: $ted-next;
    position: relative;
  }

  @include e('hero-body') {
    @include make-container();
    @include make-container-max-widths();

    align-items: center;
    justify-content: center;
    height: calc(100% + 35px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 70px;
    grid-template-areas: 'hero' 'search';
  }

  @include e('aligned') {
    width: 100%;
    grid-area: hero;
  }

  @include e('heading') {
    @include make-col(12);
    @include h1();

    height: 100%;
    margin: 0 auto;
    text-align: center;

    .msie & {
      max-width: 95%;
    }

    @include media-breakpoint-up(md) {
      margin: 0 auto;
    }

    @include media-breakpoint-up(lg) {
      @include make-col(9);
    }

    @include media-breakpoint-down(sm) {
      font-size: 32px;
    }
  }

  @include e('eyebrow') {
    display: block;
    height: 48px;
    margin: 0 auto;
    font-size: $eyebrow-font-size;
    font-weight: bold;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 100%;
      height: 24px;
      margin-bottom: 16px;
    }
  }

  @include e('search-bar-container') {
    grid-area: search;
    position: relative;
    top: 0;
    width: 90%;
    height: 70px;
    margin: 0 auto;
    padding: 27px 33px;
    vertical-align: middle;
    border-radius: 33.5px;
    background-color: $color-white;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.14);
    @include media-breakpoint-down(md) {
      @media screen and (orientation: landscape) {
        width: 100%;
      }
    }
  }

  @include e('input') {
    width: calc(100% - 50px);
    font-size: 16px;
    border: none;
    color: $color-oxford-blue;
    background-color: $color-transparent;
    font-family: $ted-next;
    font-size: 14px;
    @include media-breakpoint-only(lg) {
      font-size: 18px;
    }
    &::placeholder {
      color: $color-fog-dark;
    }
  }

  @include e('btn-search') {
    position: absolute;
    top: 10px;
    right: 11px;
    width: 50px;
    height: 50px;
    background-color: $color-azure;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 100%;
    text-align: center;
    color: $color-white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    span::before {
      font-size: 25px;
      font-weight: 300;
    }

    @include m('active') {
      box-shadow: none;
    }
  }

  @include m(scotch) {
    .hero-support-search__input {
      font-size: 14px;
      color: #989898;
      outline: none;
    }
    .hero-support-search__btn-search {
      outline: none;
      background-color: map-get($rogers-colors, primary-light);
    }

    .hero-support-search__btn-search--active span::before {
      color: white;
    }
  }
}
