// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Module styles
@include b(form-group) {
  @include make-container();
  @include make-container-max-widths();

  @include e(title) {
    color: #32434e;
    color: map-get($colors-scotch, grey);
    font-size: 22px;
    line-height: 1.55;
    font-weight: bold;
    font-family: $ted-next;

    @include media-breakpoint-only(sm) {
      padding: 0 10px;
    }

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  @include e(description) {
    color: #7e929c;
    color: map-get($colors-scotch, grey);
    font-size: 18px;
    line-height: 1.6em;
    margin-top: 3px;

    @include media-breakpoint-only(sm) {
      font-size: 16px;
      padding: 0 10px;
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
      margin-top: 9px;
      line-height: 1.5;
      letter-spacing: 0.1px;
    }
  }

  @include e(card) {
    margin-top: 25px;

    @include media-breakpoint-only(sm) {
      width: 100%;
    }
  }

  @include e(content) {
    padding: 40px;
    width: 100%;
    @include media-breakpoint-only(sm) {
      display: flex;
      flex-direction: column;
    }
  }

  @include e(group) {
    &:last-child {
      textarea {
        height: 90px;
        margin-bottom: 20px;
      }

      @include media-breakpoint-up(md) {
        textarea {
          height: 76px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
