// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.c-hero {
  overflow: hidden;
  max-width: none !important;
  h1 {
    @include h1();
  }
  .c-background {
    max-height: 575px;
  }
}

.c-hero .c-hero__body .c-content-box{
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }
}
.c-hero--contentLeft [data-testid=button] {
  position: relative;
}
.c-hero--narrow{
  background-color: $color-white;
  max-width: 1160px!important;
 }
.c-hero--contentTop {
  max-height: 800px;
  grid-template-rows: 1fr minmax(375px, auto);

  .safari & {
    @include media-breakpoint-up(lg) {
      max-height: 700px;
    }
  }
}
@include media-breakpoint-only(md) {
  .c-hero--contentLeft [data-testid=button] {
    position: relative!important;
  }
}
@include media-breakpoint-only(sm) {
  .c-hero--contentTop {
    grid-template-rows: 1fr 1fr !important;

    .c-hero__body {
      align-items: center;
    }
  }
  .c-hero--contentLeft .c-hero__body .c-content-box {
    display: flex;
    flex-direction: column;
    .c-button, [data-testid=button] {
      position: relative!important;
    }
  }
}
.gradient-hero {
  .hero__heading {
    max-width: 700px;
  }

  .hero__subtitle {
    color: white;
    p {
      max-width: 700px;
    }
    .c-disclaimer-cta,
    sup {
      font-size: 0.8em;
    }
  }
}
