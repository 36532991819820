// Application mixins
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/utils/bem';

// Component styles
@include b(global-nav-toggle) {
  width: 48px;
  height: 48px;
  border: none;
  z-index: 120;
  position: relative;
  background: none;
  cursor: pointer;
  padding: 25px;

  span {
    width: 18px;
    height: 2px;
    display: block;
    position: absolute;
    transform: rotate(0deg);
    background: $color-oxford-blue;
    transition: all 100ms ease-in-out;
    border-radius: 1px;
    transition-property: left, top, transform;
    left: 15px;

    &:nth-child(1) {
      top: 21px;
    }

    &:nth-child(2) {
      top: 29px;
    }
  }

  @include e(text) {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }

  @include m(open) {
    span {
      left: 15px;
      width: 17px;

      &:nth-child(1) {
        top: 23.5px;
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        top: 23.5px;
        transform: rotate(45deg);
      }
    }
  }
}
