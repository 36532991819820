@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

.image-selector {
  padding-top: 60px;
  max-width: 100vw;
  overflow: hidden;
  background-color: white;

  @include media-breakpoint-only(sm) {
    overflow: hidden;

    .image-switcher {
      /* stylelint-disable */
      height: 314px !important;

      &__image {
        height: inherit;

        img {
          height: inherit !important;
          max-width: 100%;
        }
      }
      /* stylelint-enable */
    }
  }

  @include media-breakpoint-only(md) {
    padding-top: 55px;

    .image-switcher {
      min-height: 460px;
      max-height: 488px;

      &__image {
        min-height: inherit;
        max-height: inherit;

        img {
          min-height: inherit;
          max-height: inherit;
        }
      }
    }
  }

  @include media-breakpoint-only(lg) {
    padding-top: 55px;

    .image-switcher {
      min-height: 560px;
      max-height: 710px;

      &__image {
        min-height: inherit;
        max-height: inherit;

        img {
          min-height: inherit;
          max-height: inherit;
        }
      }
    }
  }

  &__container {
    @include make-container();
    @include make-container-max-widths();
  }

  &__title {
    @include h2();
    text-align: center;
    color: $color-oxford-blue;
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }

    @include make-container();
  }

  &__description {
    font-size: 18px;
    line-height: 1.56;
    text-align: center;
    color: $color-storm-grey;
    margin-bottom: 30px;

    @include make-container();

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }

  &__collection-subcopy {
    margin: 30px 0;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;

    @include make-container();

    @include media-breakpoint-up(md) {
      display: none;
    }

    &--fade-out {
      opacity: 0;
    }

    &-container {
      font-size: 16px;
      line-height: 1.75;
      text-align: center;
      color: $color-storm-grey;
    }
  }

  &__content {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;

      &::after {
        visibility: hidden;
        display: block;
        content: '';
        clear: both;
        height: 0;
      }
    }
  }

  &__options-list {
    @include media-breakpoint-up(md) {
      width: 50%;
      float: left;
    }
  }

  &__image-container {
    max-width: 375px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      margin: inherit;
      max-width: none;
      width: 50%;
      float: left;
    }
  }

  &__image {
    & > img {
      max-width: 100%;
    }
  }

  &__button {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-flex;
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    .image-selector__button {
      @include media-breakpoint-down(md) {
        display: inline-flex;
      }
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &--fades-image {
    .image-switcher__image {
      transition: 0.2s opacity ease-in-out;
    }
  }
}
