@import '~normalize.css/normalize.css';

body {
  margin: 0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}
