// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

.white {
  .button-and-link__icon,
  .eyebrow,
  .button-and-link__title,
  .button-and-link__title *,
  .button-and-link__description {
    color: $color-white;
  }

  .button-and-link__links {
    a {
      color: $color-white;
    }
    &.button {
      svg {
        color: $color-white;
      }
      &:before {
        border-color: $color-white;
      }
      &:hover:before {
        border-color: transparent;
      }

      &.button--tertiary {
        color: map-get($colors, primary);
        &:hover {
          color: $color-white;
        }
      }
    }
  }
}
// Module styles
@include b('button-and-link') {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;

  }
  @include m(white) {
    background-color: #fff;

  }

  @include m(transparent) {

  }

  @include media-breakpoint-only(sm) {
    padding: map-get($copy-spacing, xl) 0;
  }

  @include m('narrow') {
    @include e(wrapper) {
      max-width: 870px;
      min-width: auto;
    }
    @include e(description) {
      line-height: 1.8em;
    }
  }

  @include m('bottom-margin') {
    margin-bottom: 80px;
    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }
  }
  @include m('no-padding') {
    padding: 0;
  }

  @include e('wrapper') {
    @include make-container();
    @include make-container-max-widths();

    @include media-breakpoint-only(sm) {
      width: 100%;
    }
  }

  @include e('container') {
    display: flex;
    flex-direction: column;
  }

  @include e('icon') {
    text-align: center;
    margin-bottom: 30px;
  }
  @include e('title') {
    margin-bottom: map-get($copy-spacing, md);
    text-align: center;
    > h2 {
      @include h2();
      color: $color-oxford-blue;
      max-width: 90%;
      display: inline-block;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto;

      @include media-breakpoint-up(lg) {
        font-size: 40px;
        line-height: 40px;
        max-width: 1000px;
        line-height: 1.1em;
      }

      br {
        display: none;
      }
    }
  }

  @include e('description') {
    color: $color-storm-grey;
    margin-top: 0;
    margin-bottom: map-get($copy-spacing, md);
    line-height: 1.6em;
    text-align: center;

    strong {
      font-weight: normal;
      font-family: Shaw, Arial, sans-serif;
    }
  }

  @include e('links') {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    a:hover {
      text-decoration: none;
    }

    svg {
      margin-left: 6px;
    }

    > a.button--link {
      margin-top: 1em;
      margin-bottom: 1em;
      display: flex;
      align-items: center;

      &:hover {
        .button__text {
          color: map-get($rogers-colors, link-light-hover);
          text-decoration: underline;
        }
      }
    }
  }

  @include m(scotch) {
    background: map-get($rogers-colors, body);
    @extend .white;
  }
}
