// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

$global-nav-height-small: 56px;
$global-nav-height-medium: 60px;
$global-nav-height-large: 80px;

// Module styles
@include b(global-nav) {
  background-color: $color-white;
  height: $global-nav-height-small;
  position: relative;
  z-index: 10000;
  border-bottom: 1px solid map-get($colors-neutral, soft);

  @include e(overlay) {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  @include e(background) {
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 110;
    position: absolute;
    // box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.14);
    background-color: $color-white;
  }

  @include e(container) {
    height: 100%;
    display: flex;
    z-index: 120;
    padding: 0 6px;
    position: relative;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      padding: 0 24px;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      @include make-container();
      @include make-container-max-widths();
    }
  }

  @include e(toggle) {
    padding: 0;
    padding-left: 5px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  @include e(logo) {
    // margin-top: 6px;
    padding: 0;
    text-align: center;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      margin-top: -4px;
      margin-right: 10px;
      transform: translateX(-15px);
      order: 1;
    }

    @include media-breakpoint-down(md) {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  @include e('expandables') {
    height: 100%;
    display: flex;
    align-items: center;
    background: #fff;
    z-index: 1;
    padding-left: 10px;
    justify-content: flex-end;
    order: 3;

    @include media-breakpoint-down(md) {
      &__search {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 999;
    }
  }

  @include e(search) {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  @include e(chat) {
    display: flex;
    align-items: center;
    margin-right: 12px;

    a {
      display: flex;
      outline: none;
      align-items: center;

      .no-touch &:hover,
      &:focus {
        color: $color-primary-cta;
      }
    }
  }

  @include e(extras) {
    display: flex;
    list-style-type: none;
    margin-left: 12px;
  }

  @include e(extra) {
    padding: 15px;
    font-size: 18px;

    a {
      display: flex;
      outline: none;
      align-items: center;

      .no-touch &:hover,
      &:focus {
        color: $color-primary-cta;
      }
      &.chat {
        pointer-events: none;
        &.chat-unavailable {
          opacity: 0.4;
        }
        &.chat-available {
          opacity: 1;
          pointer-events: all;
        }
      }

      @include media-breakpoint-only(sm) {
        &.chat-available {
          .link__text {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    height: $global-nav-height-medium;
  }

  @include media-breakpoint-up(lg) {
    height: $global-nav-height-large;
  }

  @include e('global-nav-menu') {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
    display: flex;
    flex: 1;
    height: 100%;
    order: 2;

    @include m('opened') {
      opacity: 0;
    }
  }
  .no-touch .global-nav--scotch .global-nav__chat a:hover,
  .global-nav--scotch .global-nav__chat a:focus {
    color: map-get($rogers-colors, primary-light-hover) !important;
  }
  @include m(scotch) {
    .global-nav__extras .global-nav__extra {
      .link {
        font-size: 16px;
        font-family: $ted-next;
        font-weight: 500;
        &:hover,
        &:focus {
          color: map-get($rogers-colors, primary-light-hover);
          text-decoration: underline;
        }
      }
    }
    .global-nav__expandables {
      .search-bar__input-container {
        @include media-breakpoint-up(lg) {
          width: 810px !important;
        }
        .search-bar__input {
          font-family: $ted-next;
        }
      }

      .chat-bar__button {
        outline: none;
        &:hover {
          .icon.chat-bar__icon {
            &::before {
              color: map-get($rogers-colors, primary-light-hover);
            }
          }
        }
      }
      .search-bar__button {
        outline: none;
        &:hover {
          .icon.search-bar__icon {
            &::before {
              color: map-get($rogers-colors, primary-light-hover);
            }
          }
        }
      }
      .search-bar__close-button {
        outline: none;
        &:hover {
          &::before,
          &::after {
            background-color: map-get($rogers-colors, primary-light-hover);
          }
        }
      }
    }
    .global-nav-menu__personal-site-link {
      color: map-get($rogers-colors, link-light);
      &:hover {
        color: map-get($rogers-colors, link-light);
        text-decoration: underline;
      }
    }
    .global-nav-toggle {
      outline: none;
    }
    .global-nav__logo {
      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-right: 10px;
        transform: translateX(0);
      }
      .global-nav__logo-link {
        outline: none;
        @include media-breakpoint-down(md) {
          padding-top: 10px;
        }
        img {
          max-width: 272px;
          max-height: 42px;

          @include media-breakpoint-up(lg) {
            max-width: 272px;
            max-height: 42px;
          }
        }
      }
    }
    .global-nav-dropdown__label {
      @include media-breakpoint-up(lg) {
        padding: 0 9px!important;
      }
    }
  }
}
