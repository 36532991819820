// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Module styles
@include b('youtube-player') {
  position: relative;
  background-color: white;

  @include e('container') {
    @include make-container();

    @include m('mx') {
      @include make-container-max-widths();
    }
    max-width: 100%;

    padding: 20px 0;

    .title {
      margin: 1em 0;
    }

    .intro {
      font-size: 16px;
      font-weight: 700;
    }

    .description {
      margin: 1em 0;
      font-size: 16px;
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: $ted-next;
      margin-bottom: 1em;
    }

    p {
      margin-bottom: 1.5em;
      line-height: 1.6em;
      font-size: 16px;
      color: $color-promise-grey;
    }

    ul,
    ol {
      margin-left: 1.5em;
      font-size: 16px;
      line-height: 1.4em;
      margin-bottom: 1.5em;
      color: $color-promise-grey;
    }

    a {
      position: relative;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    @include m('left') {
      text-align: left;
    }

    @include m('center') {
      text-align: center;

      .youtube-player__responsive-item {
        margin: auto;
      }
    }

    @include m('right') {
      text-align: right;

      .youtube-player__responsive-item {
        margin-left: auto;
      }
    }
  }

  @include e('responsive-item') {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    @include m('responsive-16by9') {
      padding-bottom: 56.25%;
    }

    @include m('responsive-4by3') {
      padding-bottom: 75%;
    }
  }
}
