// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

$caret-blue: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDUycHgiIGhlaWdodD0iMjU4cHgiIHZpZXdCb3g9IjAgMCA0NTIgMjU4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImFycm93LWRvd24tc2lnbi10by1uYXZpZ2F0ZSIgZmlsbD0iIzAwODJCQiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTIyNS45MjMsMjU3LjcwNiBDMjE3LjgyNSwyNTcuNzA2IDIwOS43MjgsMjU0LjYxNCAyMDMuNTU0LDI0OC40NDMgTDkuMjcsNTQuMTU3IEMtMy4wODksNDEuNzk4IC0zLjA4OSwyMS43NiA5LjI3LDkuNDA2IEMyMS42MjQsLTIuOTQ4IDQxLjY1OCwtMi45NDggNTQuMDE4LDkuNDA2IEwyMjUuOTIzLDE4MS4zMjEgTDM5Ny44MjksOS40MTIgQzQxMC4xODgsLTIuOTQyIDQzMC4yMiwtMi45NDIgNDQyLjU3Myw5LjQxMiBDNDU0LjkzOCwyMS43NjYgNDU0LjkzOCw0MS44MDQgNDQyLjU3Myw1NC4xNjMgTDI0OC4yOTIsMjQ4LjQ0OSBDMjQyLjExNSwyNTQuNjIxIDIzNC4wMTgsMjU3LjcwNiAyMjUuOTIzLDI1Ny43MDYgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==';
.support-product-articles {
  position: relative;
  color: $color-oxford-blue;
  z-index: 1;

  @include e('content-wrapper') {
    grid-area: content;
    @include make-container();
    @include make-container-max-widths();
    position: relative;
    z-index: 9999;

    margin-top: 120px;
    margin-bottom: 50px;
  }

  @include e('content') {
    padding: 0 80px;
    background-color: $color-white;
    border-radius: 4px;
    border: 1px solid map-get($colors-neutral, light);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down(sm) {
      padding: 0 10px;
    }
  }

  // @include e('breadcrumbs') {
  //   box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.14);
  // }

  @include e('breadcrumbs-container') {
    $fontsize: 12px;
    $fontheight: 14px;
    margin-right: auto;

    font-size: 1.1em;
    padding: 20px 0 13px;

    ul {
      padding: 0;
      list-style-type: none;
    }

    li {
      display: inline-block;
      vertical-align: top;
      font-size: $fontsize;
      line-height: $fontheight;
      color: $color-bluish-grey;
    }

    li > a {
      font-family: $ted-next;
      color: $color-azure;
      font-size: $fontsize;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 20px 20px 13px;
    }
  }

  @include e('title') {
    padding: 52px 0;

    h3 {
      text-align: center;
      @include h3();
      line-height: 1.4em;
    }
  }

  @include e('articles-types') {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 100px;

    @include media-breakpoint-down(md) {
      grid-gap: 30px;
      grid-template-columns: 1fr;
    }
  }

  @include e('article-type-container') {
    display: block;
    padding-bottom: 50px;

    @include media-breakpoint-down(md) {
      display: block;
      width: 80%;
      border-bottom: solid 1px #7f9398;
      margin: 0 auto;
      margin-bottom: 20px;
      padding-bottom: 30px;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 90%;
      margin: auto;
    }
  }

  @include e('article-type') {
    padding: 30px 0;

    h4 {
      font-family: $ted-next;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $color-black;
      margin-bottom: 20px;
      text-align: left;
    }

    ul {
      list-style-type: none;
      padding: 0;
      text-align: left;
    }

    li {
      padding: 5px 0;
    }

    li > a.button--link {
      word-wrap: break-word;
      font-size: 14px;
      line-height: 1.4em;
      display: block;
      margin-bottom: 7px;

      span {
        display: inline;
        line-height: 1.4em;
      }

      &:after {
        content: '';
        display: inline-block;
        transform: translateX(5px) rotate(-90deg);
        height: 10px;
        width: 10px;
        transition: transform 0.1s ease-in-out;
        background-size: 8px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url($caret-blue);
      }
      &:hover {
        color: map-get($colors, secondary);
        &:after {
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDUycHgiIGhlaWdodD0iMjU4cHgiIHZpZXdCb3g9IjAgMCA0NTIgMjU4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkNhcmV0IiBmaWxsPSIjOTQ2NEZEIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cGF0aCBkPSJNMjI1LjkyMywyNTcuNzA2IEMyMTcuODI1LDI1Ny43MDYgMjA5LjcyOCwyNTQuNjE0IDIwMy41NTQsMjQ4LjQ0MyBMOS4yNyw1NC4xNTcgQy0zLjA4OSw0MS43OTggLTMuMDg5LDIxLjc2IDkuMjcsOS40MDYgQzIxLjYyNCwtMi45NDggNDEuNjU4LC0yLjk0OCA1NC4wMTgsOS40MDYgTDIyNS45MjMsMTgxLjMyMSBMMzk3LjgyOSw5LjQxMiBDNDEwLjE4OCwtMi45NDIgNDMwLjIyLC0yLjk0MiA0NDIuNTczLDkuNDEyIEM0NTQuOTM4LDIxLjc2NiA0NTQuOTM4LDQxLjgwNCA0NDIuNTczLDU0LjE2MyBMMjQ4LjI5MiwyNDguNDQ5IEMyNDIuMTE1LDI1NC42MjEgMjM0LjAxOCwyNTcuNzA2IDIyNS45MjMsMjU3LjcwNiBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');

          transform: translateX(7px) rotate(-90deg);
        }
      }
    }
  }
  @include m(scotch) {
    background-color: map-get($rogers-colors, background-medium);
    .support-product-articles__article-type {
      li {
        .button--link {
          outline: none;
          color: map-get($rogers-colors, secondary-light);
          span {
            outline: none;
          }
          &:focus {
            outline: none !important;
          }
          &:hover {
            color: map-get($rogers-colors, secondary-light-hover);
            &:after {
              background-image: url($caret-blue);
            }
          }
        }
      }
    }

    .support-product-articles__title {
      padding-bottom: 20px;
    }

    .support-product-articles__articles-types {
      margin-top: 32px;
    }

    .notification {
      margin-bottom: 30px;
    }
  }
}
