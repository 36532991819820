// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';

// Component styles
@include b(feature-block) {
  @include m(white) {
    background-color: $color-white;
  }

  @include e(feature) {
    @include make-container();
    @include make-container-max-widths();
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(2, 50%);
    grid-template-areas: 'image text' 'copy copy';

    @media screen and (orientation: landscape) {
      min-width: $md-max-width;
    }

    .responsive-image {
      grid-area: image;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .button-and-link {
      grid-area: copy;
      width: 100%;
    }

    @include media-breakpoint-only(sm) {
      grid-template-columns: 100%;
      grid-template-areas: 'image' 'text' 'copy';
      .button-and-link {
        padding: 30px 15px;

        .button-and-link__title {
          width: 80%;
          margin: auto;
          text-align: center;
        }

        .button-and-link__wrapper {
          width: 100%;
        }
      }
    }

    @include media-breakpoint-only(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include e(feature-copy) {
    grid-area: text;
    background-color: $color-search-grey;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .rich-text {
      text-align: center;
      max-width: 70%;

      @include media-breakpoint-only(sm) {
        max-width: none;
      }
      h2,
      h3 {
        margin: 1em 0;
      }
      p {
        line-height: 1.6em;
      }
    }
  }
}
