// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(support-outage-list) {
  background: white;

  .support-outage-article + .support-outage-article {
    margin-top: map-get($copy-spacing, lg);
  }
  @include e(intro) {
    @include make-container();
    padding: map-get($copy-spacing, xxl) 0;
    max-width: map-get($widths, narrow);
    text-align: center;
    line-height: 1.5em;
  }
  @include e(filter-container) {
    background-color: map-get($colors-neutral, light);
  }
  @include e(filter) {
    @include make-container();
    @include make-container-max-widths();
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: map-get($copy-spacing, md);
    padding: map-get($copy-spacing, lg) 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
  @include e(filter-title) {
    font-family: $ted-next;
    font-weight: 500;
    display: flex;
    align-items: center;
    @include media-breakpoint-only(sm) {
      margin-bottom: map-get($copy-spacing, md);
      margin-left: map-get($copy-spacing, xl);
    }
  }
  @include e(filter-box) {
    margin-left: map-get($copy-spacing, xl);
    font-family: $ted-next;
    font-weight: 500;
    display: flex;
    align-items: center;
    @include media-breakpoint-only(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    .select-box {
      position: relative;
      z-index: 9999;
      width: 320px;
      margin-left: map-get($copy-spacing, sm);
      @include media-breakpoint-only(sm) {
        width: 90%;
        margin-left: 0;
        margin-top: map-get($copy-spacing, sm);
      }
    }
  }
  @include e(outages-container) {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      min-height: 155px;
    }
    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      min-height: 500px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding-top: map-get($component-spacing, sm);
    padding-bottom: map-get($component-spacing, sm);
  }
  @include e(no-outages) {
    text-align: center;
  }
  @include e(outages) {
    @include make-container();
    @include make-container-max-widths();
  }
}
