// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';
@import 'app/components/IconBadge/IconBadge';
@import 'style/utils/bem';

// Module styles
@include b(expanding-product-card) {
  position: relative;
  display: grid;
  grid-auto-rows: minmax(100px, max-content);
  background-color: transparent;

  @include media-breakpoint-only(sm) {
    max-width: 100vw;
    grid-template-columns: 15px 20px auto 20px 15px;
    padding-bottom: map-get($component-spacing, sm);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 30px 50px auto 50px 30px;
    padding-bottom: map-get($component-spacing, md);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: minmax(120px, auto) 86px 988px 86px minmax(
        120px,
        auto
      );
    grid-template-columns: 1fr 86px 988px 86px 1fr;
    padding-bottom: map-get($component-spacing, lg);
  }

  @include m(primary) {
    background-color: map-get($colors, primary);
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
  }
  @include m(dark) {
    background-color: $color-dark-blue;
  }
  @include m(black) {
    background-color: black;
  }

  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  @include m('nothing-to-expand') {
    padding-bottom: 100px;
  }

  @include e(full-bleed-background) {
    position: absolute;
    width: 100%;
    height: auto;
    overflow: hidden;
    grid-row: 1 / span 1;
    grid-column: 2 / span 3;

    @include media-breakpoint-only(sm) {
      height: auto;
      min-height: 300px;
    }

    .msie & {
      width: 100%;
    }
  }

  @include e(full-bleed-background-image) {
    @include media-breakpoint-only(sm) {
      height: 100%;
      min-height: 300px;
    }

    & > img {
      vertical-align: top;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 500px;

      @include media-breakpoint-only(sm) {
        min-height: 300px;
      }

      .msie & {
        width: 100%;
        max-width: none;
        min-width: 0;
      }
    }
  }

  @include e(content-container) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    grid-row: 1 / span 2;
    grid-column: 3 / span 1;
  }

  @include e(title) {
    position: relative;
    color: $color-white;
    max-width: 500px;
    margin-top: 80px;
    @include h2();
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-top: 47px;
    }

    @include media-breakpoint-only(sm) {
      max-width: 300px;
    }
  }

  @include e(collapsable-card) {
    background-color: transparent;
    overflow: visible;
    margin-top: 36px;
    padding: 0;
    max-width: 100%;

    @include media-breakpoint-only(lg) {
      margin-top: 71px;
      padding: 0;
      width: 100%;
    }

    @include media-breakpoint-only(md) {
      margin-top: 42px;
      padding: 0;
      width: 100%;
      max-width: $md-width;
    }

    @include e(container) {
      margin: 0;
    }
  }

  @include e(text-box-container) {
    display: flex;
    flex: 1;
    align-items: center;
    min-height: 314px;
    padding: 44px 55px 47px;

    @include media-breakpoint-only(sm) {
      padding: 35px 15px 36px;
    }

    .basic-text-box__title {
      font-size: 24px;
      line-height: 1.4em;
    }

    @include media-breakpoint-only(lg) {
      .basic-text-box {
        flex: 1;
      }

      .basic-text-box__title {
        line-height: 1;
      }
    }

    @include media-breakpoint-down(md) {
      margin: 0 auto;
      justify-content: center;

      // base BasicTextBoxStack has different breakpoints for box alignment, etc.  need to override here
      .basic-text-box {
        text-align: center;
        margin-right: 0;
        margin-bottom: 40px;
        max-width: 100%;

        &::before {
          display: none;
        }
      }

      .basic-text-box-stack__content--row {
        flex-direction: column;
      }
    }

    @include media-breakpoint-only(md) {
      padding: 44px 145px 47px;
    }

    @include media-breakpoint-only(lg) {
      padding: 0 77px;
    }
  }

  @include e(hardware-feature-list) {
    width: 100%;
  }

  @include e('cta-block') {
    margin: 80px auto;
    text-align: center;
    // grid-row: 3 / -1;
    grid-column: 3 / span 1;

    .button {
      @include media-breakpoint-only(sm) {
        width: 100%;
      }
    }
  }

  @include m(scotch) {
    .collapsable-card__toggle {
      outline: none;
      &:hover {
        background: white;
        border: 1px solid #f6f6f6;
        .chevron.chevron--blueChevron:before,
        .chevron.chevron--blueChevron:after {
          background-color: map-get($rogers-colors, primary-light) !important;
        }
      }
      .chevron.chevron--blueChevron:before,
      .chevron.chevron--blueChevron:after {
        background-color: map-get($rogers-colors, primary-light);
      }
    }
  }
}
