// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Module styles

@include b(theme-pack-container) {
  @include make-container();

  position: relative;
  z-index: 3;
  padding: 0;
  display: grid;
  margin-top: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 50px;
  }

  & + .button-and-link {
    margin-top: 60px;
  }

  @include m(grey) {
    .theme-pack-container__content {
      background: white;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }
  }

  @include m(white) {
    background: white;
    width: 100%;
    padding: 0;
    margin: 50px 0 0;
    display: block;

    .theme-pack-container__content {
      @include make-container();
      @include make-container-max-widths();
    }
  }

  @include e(cta-container) {
    text-align: center;
  }

  @include e(cta) {
    margin-bottom: 50px;
  }

  @include e(intro) {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    transform: translateY(-30px);
  }

  @include m(with-summary) {
    grid-template-rows: repeat(3, minmax(100px, min-content));

    @include media-breakpoint-only(sm) {
      margin-top: 0;
      grid-template-columns:
        minmax(15px, auto) minmax(15px, auto) minmax(200px, auto)
        minmax(15px, auto) minmax(15px, auto);
    }
    // prettier-ignore
    @include media-breakpoint-up(md) {
      margin-top: 30px;
      grid-template-columns:
        minmax(15px, auto) minmax(30px, auto) minmax(auto, $md-width)
        minmax(30px, auto) minmax(15px, auto);
    }
    // prettier-ignore
    @include media-breakpoint-up(lg) {
      margin-top: 50px;
      grid-template-columns:
        minmax(30px, auto) minmax(auto, 70px) minmax(930px, $lg-min-width)
        minmax(auto, 70px) minmax(30px, auto);
    }

    @include e(title) {
      grid-row: 1 / span 1;
      grid-column: 3 / 4;
      z-index: 99;
    }

    .theme-pack-container__themepacks {
      border-top: 1px solid $color-fog;
    }

    .theme-pack-container__themepacks--open {
      border-bottom: 1px solid $color-fog;
    }
  }

  .full-bleed-background {
    grid-row: 1 / span 3;
    grid-column: 1 / -1;
  }

  .theme-pack__wrapper {
    &:last-child {
      .collapsable-card__container {
        border-color: transparent;
      }
    }
  }

  @include e(content) {
    grid-row: 2 / span 3;
    grid-column: 3 / 4;
    position: relative;
    z-index: 999;
    max-width: $lg-min-width;

    .basic-cta__container {
      margin: 0;
      width: 100%;

      .basic-cta__button {
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .basic-cta__title {
      max-width: 70%;
    }

    @include m(with-summary) {
      display: grid;
      grid-template-rows: repeat(3, max-content);
      grid-template-columns: minmax(min-content, auto);
      margin: 0 auto;
      width: 100%;

      .theme-pack-container__themepacks {
        grid-column: 1 / -1;
        grid-row: 3 / 4;
        height: 0;
        display: flex;
        flex-direction: column;

        &.theme-pack-container__themepacks--open {
          height: auto;
        }

        .theme-pack__wrapper.child {
          padding-top: 30px;
          padding-left: 15px;
          padding-right: 15px;
          background: $color-search-grey;

          @include media-breakpoint-up(md) {
            padding-left: 30px;
            padding-right: 30px;
          }

          @include media-breakpoint-up(lg) {
            padding-left: 60px;
            padding-right: 60px;
          }

          .theme-pack__theme-name {
            @include media-breakpoint-only(sm) {
              justify-content: center;
            }
          }
        }
      }

      .basic-cta {
        grid-column: 1 / -1;
        grid-row: -1;
        max-width: 100%;
        padding-top: 20px;
        padding-bottom: 50px;

        &.__container {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .theme-pack-container__toggle-container {
        grid-column: 1 / -1;
        grid-row: 3 / 4;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        justify-self: center;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 5;
        transform: translateY(-50%);
        background-color: $color-pale-grey;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);

        button {
          border: 0;
          background: transparent;
          outline: none;
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
  }

  @include e(title) {
    padding: 50px 0;
    max-width: 64%;
    margin: auto;
    text-align: center;
    @include h2();

    @include media-breakpoint-only(sm) {
      max-width: 80%;
    }
  }

  @include e(summary) {
    display: grid;
    margin: 30px auto;
    background: $color-fog;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: 1px;

    @include media-breakpoint-up(md) {
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-column-gap: 1px;
    }
  }

  @include e(summary-item) {
    background: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 60px;

    h3 {
      font-family: $ted-next;
      font-size: 28px;
      font-weight: 700;
    }

    p {
      text-align: center;
      color: $color-promise-grey;
    }
  }

  @include e(collapse-label) {
    display: flex;
    margin: 0 auto 50px;
    color: $color-primary;
    font-family: $ted-next;
    font-weight: 500;
    font-size: 18px;
  }

  @include e(themepacks) {
    border-collapse: collapse;

    .theme-pack__wrapper {
      animation-timing-function: ease-in-out;
      animation-play-state: paused;
      animation: collapse $secondary-timing forwards;
      overflow: hidden;
    }

    @include m(open) {
      // border-bottom: 1px solid $color-fog;

      .theme-pack__wrapper {
        animation: open $secondary-timing forwards;
      }
    }
  }
}

// animations

@keyframes open {
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    height: 100%;
    opacity: 1;
  }
}

@keyframes collapse {
  0% {
    height: 100%;
    opacity: 1;
  }

  100% {
    height: 0;
    opacity: 0;
  }
}
