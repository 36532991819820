// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

.case-study-article-chapter {
  position: relative;
  background-color: $color-white;
  color: $color-oxford-blue;

  @include e('content-wrapper') {
    @include make-container();
    @include make-container-max-widths();
  }

  @include e('content') {
    padding: 30px 100px;

    @include media-breakpoint-down(sm) {
      padding: 30px 10px;
    }

    @include m('full-width') {
      padding: 30px 0;

      @include media-breakpoint-down(sm) {
        padding: 30px 0;
      }
    }
  }

  h1 {
    @include h1();
    margin: 20px 0;
  }

  h2 {
    @include h2();
    margin: 20px 0;
  }

  h3 {
    @include h3black();
    margin: 20px 0;
  }

  p,
  .rich-text {
    line-height: 35px;
    margin-bottom: 2em;
  }

  a {
    color: $color-primary;
    &:hover {
      text-decoration: none;
    }
  }
}
