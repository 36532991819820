// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';

@include b(support-search-bar) {
  display: flex;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
  z-index: 3;

  @include e(container) {
    padding: map-get($copy-spacing, sm);
    background-color: white;
    border-radius: 100px;
    box-shadow: 0px 2px 11px #91919180;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    min-width: 320px;
    width: 100%;
    max-width: calc(
      #{map-get($widths, narrow)} - #{map-get($copy-spacing, xxl)}
    );

    @include media-breakpoint-down(md) {
      width: 90%;
    }
  }
  @include e(input) {
    width: 100%;
    border: 0;
    font-family: $ted-next;
    font-size: 15px;
    padding: 0 15px;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
  @include e(btn-search) {
    border: 0;
    background: none;
    cursor: pointer;
    span {
      line-height: 0;
      width: 41px;
      height: 41px;
      background-color: map-get($colors, primary);
      color: white;
      border: 0;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      padding: 0;
      line-height: 1;
    }
  }
}
