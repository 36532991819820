// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Module styles
@include b('images-in-row') {
  position: relative;
  background-color: white;

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();
  }

  @include e('images-row') {
    display: flex;
    padding: 20px 0;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  @include e('image-item') {
    display: inline-block;
    vertical-align: middle;

    img {
      display: inline-block;
      width: calc(100% - 10px);
      height: auto;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      margin-bottom: 10px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      @include m('first') {
        div.responsive-image {
          text-align: center;
        }
      }

      @include m('middle') {
        div.responsive-image {
          text-align: center;
        }
      }

      @include m('last') {
        div.responsive-image {
          text-align: center;
        }
      }
    }
  }
}
