@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';

.footer {
  &__copyright-bar {
    background-color: $color-white;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }

    .footer__container {
      @include media-breakpoint-up(lg) {
        padding: 23px 0;
      }
    }
  }

  &__copyright-text {
    font-size: 12px;
    line-height: 2.33;
    letter-spacing: 0.1px;
    text-align: center;
    color: $color-black;

    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 2;
    }

    @include media-breakpoint-up(lg) {
      float: right;
      margin-right: 20px;
    }
  }

  &__image {
    height: 93px;
    width: 87px;
    float: right;
    margin-top: -84px;
    margin-right: 50px;
    position: absolute;
    right: -20px;
    top: 15px;

    @include media-breakpoint-up(lg) {
      position: static;
    }
  }
}
