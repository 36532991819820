// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';

@include b('long-distance-rates') {
  text-align: center;
  font-family: $ted-next;
  width: 100%;

  @include m(primary) {
    background-color: map-get($colors, primary);
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
  }
  @include m(dark) {
    background-color: $color-dark-blue;
  }
  @include m(black) {
    background-color: black;
  }

  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  @include e('rates') {
    margin: map-get($component-spacing, sm) auto;
    @include media-breakpoint-up(md) {
      max-width: 100%;
      width: calc(1024px - 30px);
      margin: map-get($component-spacing, md) auto;
    }

    @include media-breakpoint-up(lg) {
      width: calc(1160px - 30px);
      margin: map-get($component-spacing, lg) auto;
    }
  }
  @include e('title') {
    @include make-col(8);

    color: $color-oxford-blue;
    flex: 0;
    margin: 0 auto;
    margin-top: 32px;
    @include h3black();

    @include media-breakpoint-only(sm) {
      flex-basis: auto;
    }

    @include media-breakpoint-up(md) {
      margin-top: 34px;
    }
  }

  @include e('description') {
    margin: 15px;
    font-size: 16px;
    color: $color-storm-grey;
  }

  @include e('search-container') {
    display: table;
    margin: 30px auto 40px;
    width: 650px;

    @include media-breakpoint-down(sm) {
      width: 90%;
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  @include e('search-input-icon') {
    position: absolute;
    top: 10px;
    left: 0;
    color: map-get($rogers-colors, grey);

    @include media-breakpoint-down(sm) {
      top: 0;
    }
  }

  &__search-input-icon--focused {
    position: absolute;
    color: map-get($rogers-colors, secondary-light-hover);
  }

  @include e('search-input') {
    display: inline-block;
    width: 100%;
    border: none;
    border-bottom: solid 2px #828282;
    padding: 4px 10px 6px 30px;
    font-size: 15px;
    &:focus,
    &:focus-within {
      outline-offset: 4px;
    }
  }

  @include e('search-input-container') {
    width: 55%;
    display: table-cell;
    vertical-align: middle;
    position: relative;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
    }
  }

  @include e('search-button') {
    width: 45%;
    display: table-cell;
    padding-left: 15px;
    vertical-align: middle;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      margin-top: 30px;
      padding-left: 0;
    }
  }

  @include e('button') {
    margin: 0;
  }

  @include e('info-block') {
    margin: 0 auto;
    padding: 20px 0;
    transition: ease-in-out all 0.2s;
  }

  @include e('price-block') {
    display: table;
    margin: 0 auto;
  }

  @include e('half-block') {
    display: table-cell;
    width: 50%;
    padding: 20px 50px;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
  }

  &__half-block--right-border {
    border-right: solid 1px #f5f6f7;
  }

  @include e('type-title') {
    display: block;
    margin-bottom: 15px;
    font-family: $ted-next;
    font-size: 20px;
    color: $color-oxford-blue;
    white-space: nowrap;
  }

  @include e('notes') {
    margin-top: 20px;
    font-size: 14px;
    color: $color-storm-grey;

    @include media-breakpoint-down(sm) {
      width: 80%;
      text-align: center;
      margin: 0 auto;
    }
  }

  @include e('dial-instructions') {
    margin-top: 20px;
    font-size: 15px;
    color: $color-storm-grey;
  }

  @include e('rate-value') {
    display: table;
    margin: 0 auto;
    color: $color-oxford-blue;
    font-weight: 700;
  }

  @include e('rate-crcy') {
    display: table-cell;
    font-size: 13px;
    vertical-align: top;
    position: relative;
    top: 3px;
  }

  @include e('rate-price') {
    display: table-cell;
    font-size: 28px;
    vertical-align: middle;
  }

  @include e('rate-per') {
    display: table-cell;
    font-size: 13px;
    vertical-align: bottom;
    position: relative;
    bottom: 3px;
  }

  @include e('rate-error') {
    text-align: center;
    font-size: 14px;
    color: $color-storm-grey;

    @include media-breakpoint-down(sm) {
      width: 80%;
      margin: 0 auto;
    }
  }

  @include e('result-block') {
    $transition-slide: 300ms;
    $transition-fade: 100ms;

    position: relative;
    overflow: hidden;
    height: 0;
    transition: height $transition-slide ease-in-out;
  }

  @include e('rates-pdf') {
    margin-top: 28px;
    font-family: $ted-next;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.1px;
    color: map-get($rogers-colors, secondary-light);

    &:hover {
      color: map-get($rogers-colors, secondary-light-hover);
      .long-distance-rates__rates-pdf-text {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }

    .link__text {
      display: flex;
      align-items: center;

      .no-touch &:hover {
        .product-plan-card-carousel__cta-icon {
          color: white;

          svg {
            .st0 {
              stroke: $color-azure-dark;
            }

            .st1 {
              fill: $color-azure-dark;
            }
          }
        }

        .product-plan-card-carousel__cta-text {
          text-decoration: underline;
          color: $color-primary;
          font-weight: 700;
        }
      }
    }
  }

  @include e('rates-pdf-icon') {
    display: inline-block;
    margin-right: 6px;
    height: auto;
    color: $color-primary;

    svg {
      height: 34px;
      width: 34px;
      display: block;
    }
  }

  @include e('rates-pdf-text') {
    margin-top: 5px;
  }
}

.long-distance-rates div.card__content {
  padding-bottom: 30px;
  position: relative;

  .msie & {
    display: block;
    flex: none;
  }
}

.react-autosuggest {
  &__suggestions-container {
    position: absolute;
    left: 0;
    top: 37px;
    width: 100%;
    border: solid 1px #d4dfe3;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 2px 1px rgba($color-black, 0.1);
    z-index: 20;
    display: none;

    @include m('open') {
      display: block;
    }
  }

  &__suggestions-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
  }

  &__suggestion {
    padding: 10px 30px;
    cursor: pointer;

    @include m('highlighted') {
      background-color: #d4dfe3;
    }
  }
}
