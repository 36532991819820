// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

.related-articles {
  background-color: $color-white;

  @include e('container') {
    padding: 30px;
    display: block;

    @include media-breakpoint-down(sm) {
      max-width: 80%;
    }
  }

  @include e('title') {
    h3 {
      @include header-three();
      margin-top: map-get($copy-spacing, md);
      margin-bottom: map-get($copy-spacing, md);
      padding-bottom: map-get($copy-spacing, md);
      border-bottom: 1px solid #d4dfe3;

      @include media-breakpoint-up(md) {
        margin-top: 12px;
        letter-spacing: -1px;
      }

      @include media-breakpoint-up(lg) {
        letter-spacing: 0;
      }
    }
  }

  @include e('items') {
    ul {
      padding: 0;
    }

    li {
      padding: 0.5em 0;
      color: $color-primary;
    }
  }
}
