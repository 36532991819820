// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'app/components/IconBadge/IconBadge';
@import 'style/utils/bem';
@import 'style/base/type';

// Component styles
@include b(product-offer-full) {
  color: $color-oxford-blue;
  background-color: $color-pale-grey;
  max-width: 100vw;
  overflow: hidden;

  @include e(container) {
    @include make-container();
    @include make-container-max-widths();
  }

  @include e(offer-container) {
    @include make-col(12);

    display: flex;
    justify-content: center;

    @include media-breakpoint-only(sm) {
      padding-top: 50px;
    }

    @include media-breakpoint-only(md) {
      padding-top: 71px;
    }

    .button--primary {
      padding: 0 20px;
    }

    .button--secondary {
      padding: 0 10px;
    }
  }

  @include e(add-on-container) {
    .product-offer-add-on-card {
      padding-top: 5px;
      padding-bottom: 50px;

      @include media-breakpoint-only(md) {
        padding-top: 0;
        padding-bottom: 80px;
      }

      @include media-breakpoint-only(lg) {
        padding-top: 72px;
        padding-bottom: 72px;
      }
    }
  }

  @include e(image-container) {
    display: none;
  }

  @include e(image) {
    & > img {
      float: right;
      clear: right;
    }
  }

  @include e(product-offer-container) {
    @include make-col(10);

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 60px;

    @include media-breakpoint-only(md) {
      @include make-col(8);
    }

    @include media-breakpoint-up(md) {
      margin: 0 0 80px;
      padding: 0;
    }

    @include media-breakpoint-only(lg) {
      @include make-col(12);

      margin: 0;
      justify-content: flex-start;
    }
  }

  @include e(product-offer) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .msie &,
    .msedge & {
      width: 100%;
    }

    @include media-breakpoint-only(lg) {
      flex-direction: row;
      margin: 123px 0 133px;
      width: 100%;
    }
  }

  @include e(price-points) {
    display: flex;
    justify-content: center;

    @include media-breakpoint-only(sm) {
      flex-direction: column;
      align-items: center;
    }

    @include media-breakpoint-up(md) {
      height: 60px;
    }
  }

  @include e(block) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    .msie &,
    .msedge & {
      flex: auto;
    }

    @include media-breakpoint-only(lg) {
      margin-left: 150px;

      &::before {
        content: '';
        position: absolute;
        left: -75px;
        background-color: $color-geyser;
        width: 1px;
        top: 0;
        bottom: 0;
      }

      &:first-child {
        margin-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  @include e(limited-offer-flag-container) {
    &.no-image-flag {
      position: absolute;
      display: none;
      top: 17px;

      .msie & {
        left: calc(50% - 82px);
      }

      @include media-breakpoint-only(lg) {
        display: table;
      }
    }

    &.image-flag {
      position: absolute;
      display: table;
      top: -32px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);

      .msie & {
        margin: 0 auto;
      }

      @include media-breakpoint-only(lg) {
        display: none;
      }
    }
  }

  @include e(limited-offer-text) {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1px;
    text-align: center;
    color: $color-pale-grey;
    text-transform: uppercase;
  }

  @include e(limited-offer-flag) {
    position: relative;
    width: auto;
    white-space: nowrap;
    height: 32px;
    padding: 0 32px;
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include e(new-customer-label) {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: -0.2px;
    text-align: center;
    color: $color-bluish-grey;
    margin: 17px 0 0;
  }

  @include e(title-container) {
    position: relative;
    margin-top: 15px;
  }

  @include e(title) {
    margin-bottom: 16px;
    text-align: center;
    @include h3black();

    @include media-breakpoint-only(lg) {
      line-height: normal;
    }
  }

  @include e(short-description) {
    max-width: 300px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $color-storm-grey;
    margin-bottom: 34px;

    @include media-breakpoint-only(md) {
      max-width: 100%;
      margin-bottom: 33px;
    }

    @include media-breakpoint-only(lg) {
      margin-bottom: 37px;
    }
  }

  @include e(features) {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    margin-bottom: 35px;

    @include media-breakpoint-down(md) {
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        border-top: 1px solid $color-geyser;
        width: 100%;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    @include media-breakpoint-only(lg) {
      margin-bottom: 0;
    }
  }

  @include e(features-list) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0 38px;

    @include media-breakpoint-up(md) {
      max-width: 400px;
    }

    @include media-breakpoint-only(lg) {
      padding: 0;
    }

    .product-feature-item {
      margin-bottom: 22px;
      flex-shrink: 0;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 34px;
      }
    }
  }

  @include e(button-container) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    margin-top: 45px;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
    }
  }

  @include e(button) {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 211px;
    }

    &:first-child {
      margin: 0 0 30px;
    }

    @include media-breakpoint-up(md) {
      &:first-child {
        margin: 0 24px 0 0;
      }
    }
  }

  @include e(price-block) {
    @include price-bug();

    position: relative;
    height: 100%;

    @include media-breakpoint-only(sm) {
      margin-top: 44px;
      width: 90px;
      height: auto;
    }

    @include media-breakpoint-up(md) {
      max-width: 90px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -22px;
      left: 0;
      right: 0;
      height: 1px;
      opacity: 0.2;
      background-color: $color-cloud;
    }

    &:first-child {
      margin-top: 0;

      &::before {
        content: '';
        display: none;
      }
    }

    @include media-breakpoint-up(md) {
      margin: 0 0 0 34px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -17px;
        width: 1px;
        height: 100%;
        opacity: 0.2;
        background-color: $color-cloud;
      }

      &:first-child {
        margin: 0;

        &::before {
          content: '';
          display: none;
        }
      }
    }
  }

  // modifiers

  @include m(image) {
    @include e(image-container) {
      @include media-breakpoint-only(lg) {
        @include make-col(6);

        display: flex;
        justify-content: flex-end;
        align-items: center;

        .msie & {
          transform: translate3d(-100%, 0, 0);
        }
      }
    }

    @include e(product-offer-container) {
      @include media-breakpoint-only(lg) {
        @include make-col(6);

        margin: 0;
        padding: 46px 0;
        justify-content: flex-start;
      }
    }

    @include e(product-offer) {
      width: 100%;
      flex-direction: column;
      margin: 0;

      @include media-breakpoint-only(lg) {
        margin: 50px 0;
      }
    }

    @include e(block) {
      justify-content: flex-start;
      margin-left: 0;

      &::before {
        display: none;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    @include e(features) {
      margin-bottom: 35px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        border-top: 1px solid $color-geyser;
        width: 100%;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    @include e(features-list) {
      padding: 30px 0 38px;
    }

    @include e(price-block) {
      @include media-breakpoint-only(lg) {
        margin-bottom: 0;
      }
    }

    @include e(limited-offer-flag-container) {
      padding-top: 2px;
      margin-bottom: 20px;

      &.no-image-flag {
        display: none;
      }

      &.image-flag {
        @include media-breakpoint-only(lg) {
          display: table;
        }
      }
    }
  }

  @include m(white) {
    background: $color-white;
  }

  @include m(imageRight) {
    @include e(offer-container) {
      flex-direction: row-reverse;
    }

    @include media-breakpoint-only(lg) {
      @include e(product-offer-container) {
        justify-content: flex-end;
      }

      @include e(image-container) {
        justify-content: flex-start;

        .msie &,
        .msedge & {
          transform: translate3d(0, 0, 0);
        }
      }

      @include e(image) {
        & > img {
          float: left;
          clear: left;
        }
      }
    }
  }

  @include m(hasAddOns) {
    @include e(product-offer-container) {
      @include media-breakpoint-only(lg) {
        padding: 46px 0 58px;
      }
    }
  }
}
