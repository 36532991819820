// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/globals/animations';
@import 'style/grid/grid';

// Component styles
@include b(single-link) {
  padding: 32px 15px;
  display: flex;
  justify-content: center;
  .link__text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $ted-next;
    font-weight: 500;
    &:hover {
      color: map-get($colors, secondary);
    }
  }

  @include media-breakpoint-up(md) {
    padding: 36px 15px;
  }
  @include media-breakpoint-up(lg) {
    padding: 48px 15px;
  }

  @include m(white) {
    background-color: $color-white;
    .link__text {
      color: $color-primary;
    }
  }
  @include m(black) {
    background-color: $color-black;
    .link__text {
      color: $color-white;
    }
  }
  @include m(grey) {
    background-color: $color-body-bg;
    .link__text {
      color: $color-primary;
    }
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    .link__text {
      color: $color-white;
      &:hover {
        color: $color-body-bg;
        text-decoration: underline;
      }
    }
  }
  @include m(primary) {
    background-color: $color-primary;
    .link__text {
      color: $color-white;
      &:hover {
        color: $color-body-bg;
        text-decoration: underline;
      }
    }
  }
  @include m(secondary) {
    background-color: $color-secondary;
    .link__text {
      color: $color-white;
      &:hover {
        color: $color-body-bg;
        text-decoration: underline;
      }
    }
  }

  .link__linkblock > .icon {
    display: none;
  }

  @include e(button-text) {
    display: inline-block;
    transform: translateY(2px); // remove this for non shaw-font!
    padding-left: 5px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }
}
