// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Module styles
@include b(basic-cta) {
  padding-top: 60px;
  padding-bottom: 70px;

  @include e(container) {
    @include make-container();
    @include make-container-max-widths();
  }

  @include e(title) {
    @include make-col(10);

    color: $color-oxford-blue;
    margin: 0 auto;
    text-align: center;
    @include h2();

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }

  @include e(description) {
    @include make-col(10);

    color: $color-storm-grey;
    margin: 0 auto;
    display: block;
    font-size: 18px;
    text-align: center;
    line-height: 1.56;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      @include make-col(8);

      margin-top: 14px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 18px;
    }
  }

  @include e(button) {
    display: block;
    margin-top: 34px;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 44px;
    }
  }

  @include m(white) {
    background: $color-white;
  }

  @include media-breakpoint-up(md) {
    padding-top: 70px;
    padding-bottom: 74px;
  }
}
