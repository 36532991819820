@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(product-plan-cards-grid) {
  margin-top: map-get($component-spacing, lg);
  @include media-breakpoint-only(md) {
    margin-top: map-get($component-spacing, md);
  }
  @include media-breakpoint-only(sm) {
    margin-top: map-get($component-spacing, sm);
  }

  @include m(reset-top-spacing) {
    margin-top: 0;
    @include media-breakpoint-only(md) {
      margin-top: 0;
    }
    @include media-breakpoint-only(sm) {
      margin-top: 0;
    }
  }
  @include m(reset-both-spacing) {
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-only(md) {
      margin-top: 0;
      margin-bottom: 0;
    }
    @include media-breakpoint-only(sm) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .product-plan-cards-grid__plan-cards {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  @include m(white) {
    background-color: $color-white;
  }
  @include m(grey) {
    background-color: $color-pale-grey;
  }

  @include e(container) {
    @include make-container();
    @include make-container-max-widths();

    @include media-breakpoint-only(md) {
      @media screen and (orientation: landscape) {
        min-width: $md-max-width;
      }
    }

    @include media-breakpoint-only(sm) {
      padding: 0 5px;
    }
    padding-bottom: 2px;
  }

  @include e(title) {
    margin-bottom: 16px;
    font-family: $ted-next;
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
  }

  @include e(description) {
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: 0.1px;
    color: $color-storm-grey;
    text-align: center;
    & + .product-plan-cards-grid__plan-cards--vertical {
      margin-top: calc(#{map-get($component-spacing, lg)} - 30px);
      @include media-breakpoint-only(md) {
        margin-top: calc(#{map-get($component-spacing, md)} - 30px);
      }
      @include media-breakpoint-only(sm) {
        margin-top: calc(#{map-get($component-spacing, sm)} - 30px);
      }
    }
  }

  @include e(plan-cards) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: map-get($component-spacing, lg);
    margin-bottom: map-get($component-spacing, lg);
    @include media-breakpoint-only(md) {
      margin-top: map-get($component-spacing, md);
      margin-bottom: map-get($component-spacing, md);
    }
    @include media-breakpoint-only(sm) {
      margin-top: map-get($component-spacing, sm);
      margin-bottom: map-get($component-spacing, sm);
    }

    @include m(vertical) {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(310px, 346px));
      grid-template-rows: repeat(auto-fit, 1fr);
      margin-right: auto;
      margin-left: auto;
      justify-content: center;

      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(auto-fit, 310px);
      }
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
