// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(horizontal-plan-card) {
  @include make-container();
  @include make-container-max-widths();

  min-height: 386px;
  min-width: auto !important;
  padding: 0 !important;
  background-color: $color-white;
  box-shadow: 0px 0px 4px map-get($colors-overlay, shadow);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(96px, max-content) 1fr;
  grid-template-areas:
    'header header'
    'description prices';

  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: 100vw;
    min-width: auto !important;
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'description'
      'prices';
  }

  @include media-breakpoint-down(sm) {
    // display: flex;
    // flex-direction: column;
    max-width: 320px;
    // max-width: 100%;
    .price-information-new.price-information-new--is-promo {
      margin: 0;

      .price-information-new__price {
        transform: translateX(4px);
      }
      .price-information-new__offer {
        align-self: flex-start;
        margin-top: 40px;
      }
    }
  }
  & + .horizontal-plan-card {
    margin-top: map-get($component-spacing, lg);
    @include media-breakpoint-down(md) {
      margin-top: map-get($component-spacing, md);
    }
    @include media-breakpoint-down(sm) {
      margin-top: map-get($component-spacing, sm);
    }
  }
  & + & + .product-plan-card__scene {
    margin: 0;
    margin-bottom: map-get($component-spacing, lg);
  }

  @include m(scotch) {
    .horizontal-plan-card__product-name {
      font-family: $ted-next;
      text-transform: none;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
    .price-information-new__term-disclaimer {
      font-family: $ted-next;
    }
    .price-information-new__price {
      font-family: $ted-next;
    }
    .price-information-new__term {
      padding-bottom: 0;
    }
    .horizontal-plan-card__header .horizontal-plan-card__banner {
      height: 22px;
      .rich-text {
        margin: 0;
        transform: translateY(1px);
      }
      p {
        padding: 0;
        margin: 0;
      }
    }

    &.horizontal-plan-card--red {
      .horizontal-plan-card__header .horizontal-plan-card__banner {
        background: map-get($colors-scotch, red);
      }
      .price-information-new--is-promo .price-information-new__offer {
        color: map-get($colors-scotch, red);
        border: 3px solid map-get($colors-scotch, red);
        background: transparent;
      }
    }
    &.horizontal-plan-card--aqua {
      .horizontal-plan-card__header .horizontal-plan-card__banner,
      .price-information-new--is-promo .price-information-new__offer {
        background: map-get($colors-scotch, aqua);
        color: black;
      }
    }

    .horizontal-plan-card--white {
      .horizontal-plan-card__header .horizontal-plan-card__banner {
        background: map-get($colors-scotch, white);
        color: map-get($colors-scotch, grey);
      }
      .price-information-new--is-promo .price-information-new__offer {
        background: map-get($colors-scotch, white);
        color: map-get($colors-scotch, grey);
        border: 1px solid map-get($colors-scotch, grey);
      }
    }
    .horizontal-plan-card--medium-aqua {
      .horizontal-plan-card__header .horizontal-plan-card__banner,
      .price-information-new--is-promo .price-information-new__offer {
        background: map-get($colors-scotch, medium-aqua);
        color: black;
      }
    }
    .horizontal-plan-card--light-aqua {
      .horizontal-plan-card__header .horizontal-plan-card__banner,
      .price-information-new--is-promo .price-information-new__offer {
        background: map-get($colors-scotch, light-aqua);
        color: map-get($colors-scotch, grey);
      }
    }
    .horizontal-plan-card--grey {
      .horizontal-plan-card__header .horizontal-plan-card__banner,
      .price-information-new--is-promo .price-information-new__offer {
        background: map-get($colors-scotch, grey);
      }
    }

    .horizontal-plan-card--medium-grey {
      .horizontal-plan-card__header .horizontal-plan-card__banner,
      .price-information-new--is-promo .price-information-new__offer {
        background: map-get($colors-scotch, medium-grey);
      }
    }
    .horizontal-plan-card--light-grey {
      .horizontal-plan-card__header .horizontal-plan-card__banner,
      .price-information-new--is-promo .price-information-new__offer {
        background: map-get($colors-scotch, light-grey);
        color: black;
      }
    }
    .horizontal-plan-card--yellow {
      .horizontal-plan-card__header .horizontal-plan-card__banner,
      .price-information-new--is-promo .price-information-new__offer {
        background: map-get($colors-scotch, yellow);
        color: black;
      }
    }
    .horizontal-plan-card--green {
      .horizontal-plan-card__header .horizontal-plan-card__banner,
      .price-information-new--is-promo .price-information-new__offer {
        background: map-get($colors-scotch, green);
      }
    }
  }

  @include e(banner) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    color: white;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    min-height: 24px;
    display: flex;
    align-items: center;
    padding-left: 23px;
    padding-right: 10px;
    transform: translateX(-24px);
    margin-bottom: 10px;
    margin-right: auto;
    font-weight: bold;
    .rich-text {
      font-size: 10px;
      transform: translateY(2px);
    }
  }
  @include e(header) {
    grid-area: header;
    font-family: $ted-next;
    font-weight: $demibold;
    text-align: left;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    color: $color-white;
    background-color: $color-black;
    min-height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;

    @include m(primary) {
      background-color: map-get($colors, primary);
      .horizontal-plan-card__banner {
        background: $color-black;
      }
    }

    @include m(grey) {
      background-color: $color-pale-grey;
      color: $color-black;
    }

    @include m(dark) {
      background-color: $color-dark-blue;
      .horizontal-plan-card__banner {
        background: map-get($colors, primary);
      }
    }
  }

  @include e(product-name) {
    display: flex;
    @include h3black();
    .rich-text {
      display: flex;
    }
  }

  @include e(description-container) {
    grid-area: description;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    margin: 18px 0;
    border-right: 1px solid map-get($colors-neutral, soft);

    @include media-breakpoint-down(sm) {
      border-right: 0;
      margin-bottom: 0;
      padding: 0 14px;
    }
  }
  @include e(image) {
    max-width: 200px;
    margin: map-get($copy-spacing, md) 0;
    img {
      max-width: 300px;
      max-height: 60px;
      object-fit: contain;
    }
  }
  @include e(description) {
    line-height: 24px;
    @include media-breakpoint-down(sm) {
      padding: 0 10px;
    }
  }
  @include e(price-information) {
    grid-area: prices;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-down(sm) {
      padding-top: 0px;
    }

    .price-information-new {
      margin: 0;
      border-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include e(buttons) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;
    @include media-breakpoint-down(sm) {
      align-items: center;
    }
  }
  @include e(product-plan-link) {
    margin-top: 24px;
    @include media-breakpoint-down(sm) {
      transform: translateX(10px);
    }
  }

  .price-information-new__price {
    max-width: 190px;
    margin-top: 24px;
    .price-information-new__promo-text {
      max-width: 150px;
    }
    &.price-information-new--is-promo .price-information-new__offer {
      margin-right: 0;
    }
  }

  .product-feature-list {
    margin: 0;
    margin-bottom: 24px;
  }
}
