// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';
.white {
  .icon-box-carousel__title,
  .product-add-on-icon-box__name,
  .product-add-on-icon-box__description,
  .product-add-on-icon-box__price-block,
  .product-add-on-icon-box__price-prefix,
  .product-add-on-icon-box__price-suffix,
  .product-add-on-icon-box__cta .button {
    color: white;
  }
  .product-add-on-icon-box__description,
  .product-add-on-icon-box__price-prefix,
  .product-add-on-icon-box__price-suffix {
    opacity: 0.7;
  }
}
// Module styles
.icon-box-carousel {
  background-color: transparent;
  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  // Grid Container
  &__container {
    @include make-container();

    padding-top: map-get($component-spacing, sm);
    padding-bottom: map-get($component-spacing, sm);

    @include media-breakpoint-up(md) {
      padding-top: map-get($component-spacing, md);
      padding-bottom: map-get($component-spacing, md);
    }

    @include media-breakpoint-up(lg) {
      @include make-container-max-widths();

      padding-top: map-get($component-spacing, lg);
      padding-bottom: map-get($component-spacing, lg);
    }

    @include media-breakpoint-up(md) {
      max-width: 678px;
    }
    @media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      max-width: $md-max-width;
    }
    @media screen and (max-width: 1024px) {
      max-width: $md-min-width;
    }
    @include media-breakpoint-up(lg) {
      max-width: 1100px;
    }
  }
  .slick-track {
    @include media-breakpoint-up(md) {
      width: 100% !important;
      display: flex;
      justify-content: center;
    }
  }
  // Grid Row
  .slick-slide {
    min-width: 300px;

    @include media-breakpoint-up(lg) {
      @include make-row();
      display: flex !important;
    }
  }

  .msie &,
  .msedge & {
    .slick-slide {
      margin-left: 0;
      margin-right: 0;
      display: flex !important;
    }
  }

  // Grid Columns
  &__icon-box-wrapper {
    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        border-right: $border-divider;
      }
    }

    &--count-1 {
      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }
    }

    &--count-2 {
      @include media-breakpoint-up(lg) {
        // @include make-col(6);

        width: 50%;
      }
    }

    &--count-3 {
      @include media-breakpoint-up(lg) {
        // @include make-col(4);
      }
    }
  }

  &__title {
    @include h2();
    text-align: center;
    margin-bottom: 38px;

    @include media-breakpoint-up(md) {
      margin-bottom: 44px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 56px;
    }
  }

  &__carousel {
    margin: 0 auto;
    position: relative;
  }

  .carousel__dots {
    margin-top: 50px;
  }

  .product-add-on-icon-box {
    padding: 0;
    max-width: 280px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      padding: 10px;
    }
  }

  @include m(noPrices) {
    .product-add-on-icon-box {
      &__price-block {
        display: none;
      }
    }
  }

  @include m(single-box) {
    @include media-breakpoint-down(md) {
      padding-bottom: 75px;

      // Grid Container
      .icon-box-carousel__container {
        @include make-container-max-widths();
      }

      // Grid Row
      .slick-slide {
        @include make-row();
        display: flex;
      }

      // Grid Column
      .icon-box-carousel__icon-box-wrapper {
        @include make-col-ready();
        @include make-col(12);
      }
    }
  }

  @include m(white) {
    background-color: white;
  }

  @include m('mini-padding') {
    .product-add-on-icon-box__name {
      min-height: unset;
      max-height: unset;
    }

    .product-add-on-icon-box__description {
      min-height: unset;
      max-height: unset;
    }
  }
}
