// Application mixins
// RENAME Three-Up Module
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

.white {
  .icon-box-static__title,
  .icon-box-static__description,
  .basic-icon-box__title,
  .basic-icon-box__description {
    color: white;
  }
  .basic-icon-box__icon,
  .basic-icon-box__icon .icon:before {
    color: white !important;
  }

  .button--link {
    color: white;
    font-weight: bold;
  }
}

// Module styles
.icon-box-static {
  padding-top: map-get($component-spacing, md);
  padding-bottom: map-get($copy-spacing, lg);
  background-color: $color-pale-grey;
  @include media-breakpoint-up(md) {
    padding-top: map-get($component-spacing, lg);
    padding-bottom: map-get($copy-spacing, xl);
  }

  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }
  @include m(icon-small) {
    .basic-icon-box {
      svg {
        max-height: 36px;
      }
    }
  }
  @include m(icon-medium) {
    .basic-icon-box {
      svg {
        max-height: 80px;
      }
    }
  }
  @include m(icon-large) {
    .icon-box-static__container {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .basic-icon-box {
      svg {
        max-height: 140px;
      }
    }
  }

  &__container {
    @include make-container();
    @include make-container-max-widths();
  }

  &__title {
    @include make-col(8);
    @include h2();
    color: $color-oxford-blue;
    margin: 0 auto map-get($component-spacing, md);
    text-align: center;

    @include media-breakpoint-only(sm) {
      margin: 0 auto map-get($copy-spacing, sm);
    }
  }

  &__description {
    color: $color-bluish-grey;
    margin: 0 auto;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    @include media-breakpoint-up(md) {
      padding-bottom: 30px;
    }
  }

  .button--link {
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
  }

  &__datasheet {
    margin: map-get($copy-spacing, xl) auto;
    text-align: center;
  }

  &__datasheet {
    .link:hover {
      .icon-box-static__datasheet-link {
        color: map-get($colors, secondary);
      }
    }
  }

  &__datasheet-link {
    font-weight: 700;
    color: $color-azure;
  }

  &__video-container {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 80px;
    text-align: center;
  }

  &__video {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
  }

  &__video > iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &__footer {
    text-align: center;

    + .icon-box-static__cta {
      margin-top: 20px;
    }

    h4 {
      font-weight: normal;
      font-size: 18px;
      margin-bottom: 0.3em;
      color: $color-oxford-blue;
    }

    .rich-text {
      font-size: 32px;
      font-family: $ted-next;
      color: $color-oxford-blue;

      sup {
        display: inline-block;
        font-size: 20px;
        transform: translateY(3px);
      }
    }
  }

  .basic-icon-box {
    padding-top: 0;
    @include media-breakpoint-only(sm) {
      padding-top: 30px;
    }

    &__icon {
      min-height: 94px;
    }
    &__title {
      margin-top: 15px;
      margin-bottom: 0;
      @include large-body();
      font-weight: 700;
      display: flow-root;
    }
    &__description {
      @include large-body();
    }
  }

  &__basic-icon-box-groups {
    margin: map-get($copy-spacing, md) auto;

    @include media-breakpoint-only(sm) {
      // max-width: 250px;
    }

    @include media-breakpoint-up(lg) {
      margin: map-get($copy-spacing, lg) auto;
    }
  }

  &__basic-icon-box-group {
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;

    & + & {
      @include media-breakpoint-up(md) {
        margin-top: map-get($component-spacing, md);
      }
    }

    &--one-item {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }

    &--two-items {
      @include media-breakpoint-up(md) {
        @include make-col(10);
      }
    }
    &--four-items {
      max-width: none;
      display: grid;
      gap: 15px;
      margin-left: 24px;
      margin-right: 24px;
      @include media-breakpoint-only(sm) {
        grid-template-columns: 1fr;
      }
      @include media-breakpoint-only(md) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
        @media screen and (orientation: landscape) {
          grid-template-columns: repeat(4, 1fr);
        }
        @media (min-width: 1024px) {
          grid-template-columns: repeat(4, 1fr);
        }
      }
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, 1fr);
      }
      .basic-icon-box {
        padding: 0;
        text-align: left;
        .basic-icon-box__title {
          margin-top: 0;
          font-size: 18px;
        }
        .basic-icon-box__icon {
          margin: 0;
          min-height: auto;
          justify-content: flex-start;
          svg {
            max-height: 64px;
            max-width: 64px;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__basic-icon-box {
    flex: 1;

    @include media-breakpoint-up(md) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  &__cta {
    text-align: center;
  }

  &--showDividers {
    .icon-box-static__basic-icon-box {
      @include media-breakpoint-down(sm) {
        border-top: $border-divider;

        &:first-child {
          border-top: none;
        }
      }

      @include media-breakpoint-up(md) {
        border-left: $border-divider;

        &:first-child {
          border-left: none;
        }
      }
    }
  }

  &--noBottomPadding {
    padding-bottom: 0;
  }

  @include m(scotch) {
    &.icon-box-static--showDividers {
      svg.icon {
        max-height: 36px;
      }
    }

    .icon-box-static__description {
      color: map-get($rogers-colors, body);
    }
    .icon-box-static__footer {
      .rich-text,
      h4 {
        font-family: $ted-next;
        color: map-get($rogers-colors, body);
      }
    }
    .basic-icon-box__icon {
      .icon {
        &:before {
          color: map-get($rogers-colors, primary-light);
        }
      }
    }
    .basic-icon-box__title {
      font-family: $ted-next;
      font-weight: $demibold;
      color: map-get($rogers-colors, body);
      font-size: 20px;
    }
    .basic-icon-box__description {
      color: map-get($rogers-colors, body);
    }
    .icon-box-static__datasheet {
      display: flex;
      justify-content: center;

      .link__linkblock {
        display: grid;
        align-items: center;
        grid-template-columns: 40px max-content;
        grid-template-areas: 'icon link';
        max-width: 100vw;

        .icon {
          grid-area: icon;
        }
        .link__text {
          grid-area: link;
          word-break: break-all;

          .icon-box-static__datasheet-link {
            max-width: 82vw;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
          }
        }
      }
      a.link,
      a.link--inherit {
        .icon-ProductFeatures_DownloadPDF:before {
          color: map-get($rogers-colors, secondary-light);
        }
        outline: none;
        color: map-get($rogers-colors, secondary-light);
        .icon-box-static__datasheet-link {
          color: map-get($rogers-colors, secondary-light);
        }
        &:hover {
          .icon-box-static__datasheet-link {
            color: map-get($rogers-colors, secondary-light-hover);
            text-decoration: underline;
          }
          .icon-ProductFeatures_DownloadPDF:before {
            color: map-get($rogers-colors, secondary-light-hover);
          }
        }
      }
    }
  }
}

.msie {
  .icon-box-static {
    &__basic-icon-box {
      flex-basis: 33%;
    }
  }
}
