$color-duck-egg-blue: #b9e2f7;
$color-light-blue: #a1dbf7;
$color-azure-light: #00d3f9;
$color-lightish-blue: #3cf;
$color-medium-blue: #1da1f2;
$color-azure: #007cba;
$color-azure-contrast: $color-azure;
$color-azure-dark: $color-azure;
$color-ocean-blue: #0078ad;
$color-oxford-blue: #000; // #33444e
$color-oxford-blue-dark: #2c3b45;
$color-search-grey: #f6f6f6;
$color-promise-grey: #696b72;
$color-bluish-grey: #32434e;
$color-dust-grey: #9b9b9b;
$color-ocean-blueish: #465d68;
$color-pale-grey: $color-search-grey;
$color-light-border: #efefef;
$color-geyser: #d5dfe3;
$color-fog-dark: #d8d8d8;
$color-fog: #dde3e7;
$color-medium-mist: #f7f9fb;
$color-mist: #fafbfd;
$color-white: #fff;
$color-cloud: #a5bbc5;
$color-black: #000;
$color-dark-gray: #222;
$color-feature-bgr: #f4f8fb;
$color-transparent: transparent;
$color-fuchsia: #e90063;
$color-outline: #b0e7ff;
$color-alert: #ec9f26;
$color-dark-blue: #1f1f1f;

$color-secondary: $color-fuchsia;
$color-storm-grey: $color-bluish-grey;
$color-primary: $color-azure;
$color-primary-cta: $color-azure;
$color-primary-hover: $color-fuchsia;
$color-secondary-cta: $color-transparent;
$color-secondary-hover: $color-azure-dark;
$color-grey: $color-promise-grey;
$color-lightest-grey: $color-fog-dark;
$color-light-grey: $color-dust-grey;

$color-heading: $color-black;
$color-body: #32434e;
$color-body-bg: $color-pale-grey;
$color-red: #ff334c;

$color-gradient-1: $color-light-blue;
$color-gradient-2: $color-azure;
$color-gradient-3: $color-ocean-blue;

$gradient-shaw-sm: linear-gradient(
  239deg,
  $color-gradient-1,
  $color-gradient-2 38%,
  $color-gradient-3
);
$gradient-shaw-md: linear-gradient(
  247deg,
  $color-gradient-1,
  $color-gradient-2 38%,
  $color-gradient-3
);
$gradient-shaw-lg: linear-gradient(
  254deg,
  $color-gradient-1,
  $color-gradient-2 38%,
  $color-gradient-3
);
$gradient-recommended: linear-gradient(
  261deg,
  $color-medium-blue,
  $color-lightish-blue
);

$border-divider: 1px solid $color-geyser;

$default-bg-color: $color-pale-grey;

$stylized-name-color: $color-oxford-blue;
$feature-text-color: $color-bluish-grey;
$feature-divider-color: $color-cloud;
$feature-spec-color: $color-oxford-blue;

$colors: (
  primary: #007cba,
  secondary: #7e4de7,
  // tertiary: #da00b2, // TODO new brand
  tertiary: #e90063,
  quaternary: #027c99,
  quinary: #051b57
);

$colors-accent: (
  primary: #00aed9,
  secondary: #9464fd,
  tertiary: #fa4fc1,
  quaternary: #00c6d5,
  quinary: #323ec7
);

$colors-alert: (
  error: #ff334c,
  warning: #ec9f26,
  success: #01ae36,
  error-dark: #a81520,
  success-dark: #00721a
);

$colors-neutral: (
  black: #000000,
  dark: #32434e,
  mediumdark: #31434e,
  medium: #989898,
  mediumlight: #dadada,
  light: #ededed,
  soft: #f6f6f6,
  white: #ffffff
);

$colors-overlay: (
  dark: rgba(0, 0, 0, 0.7),
  light: rgba(0, 0, 0, 0.4),
  shadow: #0000001a,
  line: #7e919c
);
$colors-gradient: (
  tertiary-start: #f8006a,
  tertiary-end: #c40053,
  hover-start: #da00b2,
  hover-end: #6c22e2,
  hero-start: #007cba,
  hero-end: #323ec7
);

$colors-scotch: (
  red: #da291c,
  aqua: #00a0b7,
  white: #fff,
  medium-aqua: #68cada,
  light-aqua: #bbe4ec,
  grey: #414042,
  medium-grey: #808284,
  light-grey: #bbbdc0,
  grey-accent: #cccccc,
  yellow: #ffbf3f,
  green: #00985f,
  dark: #222222,
  body: #1f1f1f
);

$rogers-colors: (
  primary-light: #da291c,
  primary-light-hover: #da291c,
  secondary-light: #007cba,
  secondary-light-hover: #007cba,
  link-light: #007cba,
  link-light-hover: #007cba,
  link-dark: #222222,
  link-dark-hover: #222222,
  dark: #222222,
  white: #ffffff,
  body: #1f1f1f,
  background-light: #f6f6f6,
  background-medium: #f2f2f2,
  grey: #989898,
  medium-grey: #808284,
  light-grey: #bbbdc0,
  grey-accent: #cccccc,
);
