// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Module styles
.basic-contact {
  padding-top: map-get($component-spacing, sm);
  padding-bottom: map-get($component-spacing, sm);

  @include media-breakpoint-up(md) {
    padding-top: map-get($component-spacing, md);
    padding-bottom: map-get($component-spacing, md);
  }
  @include media-breakpoint-up(lg) {
    padding-top: map-get($component-spacing, lg);
    padding-bottom: map-get($component-spacing, lg);
  }

  background-color: transparent;
  @include m(primary) {
    background-color: map-get($colors, primary);
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
  }
  @include m(dark) {
    background-color: $color-dark-blue;
  }
  @include m(black) {
    background-color: black;
  }

  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  &__contact-card {
    @include make-container();
    @include make-container-max-widths();

    @include media-breakpoint-only(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
