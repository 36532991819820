// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(promotional-plan-card-container) {
  background: transparent;

  @include m(primary) {
    background-color: map-get($colors, primary);
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
  }
  @include m(dark) {
    background-color: $color-dark-blue;
  }
  @include m(black) {
    background-color: black;
  }

  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }
  @include m(scotch) {
    background-color: transparent;
    .price-information__limited-offer-flag {
      background: map-get($rogers-colors, primary-light);
    }
  }
}

@include b(promotional-plan-card-content) {
  @include make-container();
  @include make-container-max-widths();
}

@include b(promotional-plan-card) {
  @include make-container();
  @include make-container-max-widths();

  padding-top: 40px;
  padding-bottom: 48px;
  background-color: $color-white;

  @include m(featured) {
    margin-top: 36px;
    margin-bottom: 36px;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    // box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.13);
    box-shadow: 0px 0px 4px #0000001a;
    margin: 48px auto;
    border-radius: 3px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include media-breakpoint-only(lg) {
    padding: 60px 0 68px;
  }

  @include e(half) {
    @include media-breakpoint-up(md) {
      width: 50%;
      padding: 0 30px;
    }

    @include media-breakpoint-only(lg) {
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &::after {
    content: '';
    height: 70%;
    width: 1px;
    background-color: $color-fog-dark;
    position: absolute;
    left: 50%;
    top: 45px;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  @include e(header) {
    text-align: center;
    color: $color-oxford-blue;
    margin-bottom: 15px;
    @include h3black();
  }

  @include e(description) {
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 0.2px;
    text-align: center;
    color: $color-storm-grey;
    margin-bottom: 24px;

    @include media-breakpoint-only(sm) {
      max-width: 345px;
      margin: 0 auto 48px;
    }

    @include media-breakpoint-only(md) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 1.5;
    }

    @include media-breakpoint-only(lg) {
      max-width: 285px;
      text-align: center;
    }
  }

  @include e(prices) {
    margin-top: 40px;

    @include make-col(12);

    @include media-breakpoint-up(md) {
      margin-top: 8px;
    }
  }

  @include e(buttons) {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 98px;
    justify-content: space-between;
    padding-top: 10px;

    @include make-col(12);

    @include media-breakpoint-up(md) {
      height: auto;
    }

    @include media-breakpoint-down(md) {
      gap: 15px;
    }
  }

  @include e(product-plan-link-sm) {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  @include e(product-plan-link-md) {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      text-align: center;
    }
  }
}

///
