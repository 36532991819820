// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(outage-live-update) {
  border: 1px solid map-get($colors-neutral, light);
  display: flex;
  font-size: 12px;

  @include media-breakpoint-only(sm) {
    align-items: stretch;
    display: grid;
    gap: 1px;
    grid-template-columns:
      minmax(40%, 100px),
      minmax(25%, 60px) 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'date time copy';
    background-color: map-get($colors-neutral, light);
  }

  @include e(date) {
    font-weight: bold;
    padding: map-get($copy-spacing, xs) map-get($copy-spacing, md);

    @include media-breakpoint-only(sm) {
      padding: map-get($copy-spacing, xs) 2vw;
      grid-area: date;
      border: 0;
      background-color: white;
      display: flex;
      align-items: center;
    }
  }
  @include e(time) {
    font-weight: bold;
    padding: map-get($copy-spacing, xs) map-get($copy-spacing, md);
    border-left: 1px solid map-get($colors-neutral, light);
    @include media-breakpoint-only(sm) {
      padding: map-get($copy-spacing, xs) 2vw;
      grid-area: time;
      border: 0;
      background-color: white;
      display: flex;
      align-items: center;
    }
  }
  @include e(description) {
    padding: map-get($copy-spacing, xs) map-get($copy-spacing, md);
    border-left: 1px solid map-get($colors-neutral, light);
    @include media-breakpoint-only(sm) {
      padding: map-get($copy-spacing, xs) 2vw;
      grid-area: copy;
      border: 0;
      background-color: white;
      display: flex;
      align-items: center;
    }
  }
}
