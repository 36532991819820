// Application mixins
@import 'style/base/type';
@import 'style/globals/animations';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/utils/bem';

// Component styles
@include b(global-nav-dropdown) {
  font-family: $ted-next;
  position: relative;
  outline: none !important;

  .chevron {
    &::before,
    &::after {
      background-color: $color-black !important;
      width: 16px;
    }
    &::before {
      left: -1px;
    }
    &::after {
      right: -1px;
    }
  }

  &:hover {
    @include media-breakpoint-up(lg) {
      &:after {
        position: absolute;
        bottom: 0;
        left: 40%;
        transform: translateX(-40%);
        z-index: 99;
        content: '';
        width: calc(100% - 32px);
        height: 1px;
      }
    }
  }

  .collapsable__container {
    @include media-breakpoint-up(lg) {
      background-color: $color-white;
      padding-bottom: 0;
      padding-top: map-get($copy-spacing, lg);
      border-radius: 0 0 4px 4px;
      border-bottom: 4px solid map-get($colors, primary);

      .global-nav-dropdown__links + .global-nav-dropdown__links {
        border-left: 1px solid map-get($colors-neutral, light);
      }
    }
  }

  @include m(align-right) {
    .global-nav-dropdown__drawer {
      left: auto;
      right: 0;
    }
  }

  @include e(flex-columns) {
    @include media-breakpoint-up(lg) {
      padding-top: map-get($copy-spacing, lg);
      display: flex;
      align-items: stretch;
    }
  }

  @include e(column) {
    flex-shrink: 0;
    padding: 0;
    margin: 0;

    // &:first-child {
    //   .global-nav-dropdown__links {
    //     padding-top: map-get($copy-spacing, xl);
    //   }
    // }

    @include media-breakpoint-up(lg) {
      & + .global-nav-dropdown__column {
        border-left: 1px solid map-get($colors-neutral, light);
      }
      min-width: 200px;
      max-width: 280px;
      margin-bottom: map-get($copy-spacing, lg);
    }

    .global-nav-dropdown__links + .global-nav-dropdown__links {
      @include media-breakpoint-up(lg) {
        padding-top: map-get($copy-spacing, xl);
      }
    }
  }
  @include e(row) {
    flex-shrink: 0;
    flex-grow: 4;
  }

  @include e(label) {
    border: none;
    background: none;
    font-family: $ted-next;
    font-weight: $demibold;
    outline: none !important;

    &:focus,
    &:active {
      outline: none !important;
    }

    @include m(collapsed) {
      border-bottom: 0;
    }
    @include m(expanded) {
      font-weight: $demibold;
      border-bottom: 1px solid map-get($colors-neutral, light);
    }

    @include media-breakpoint-up(lg) {
      border-bottom: 0;
      padding: 0 9px !important;
    }
  }

  @include e(group-title) {
    font-family: $ted-next;
    font-size: 15px;

    padding: 5px 24px;
    @include media-breakpoint-up(md) {
      padding: map-get($copy-spacing, sm) map-get($copy-spacing, xxl);
    }
  }

  @include e(link) {
    color: map-get($colors, primary);

    .link__text {
      padding-left: 0;
    }

    a.is-active {
      font-weight: $bold;
      color: map-get($colors, secondary);
    }
    .link {
      &:hover {
        color: map-get($colors, secondary);
        font-weight: $demibold;
        text-decoration: underline;
      }
    }
  }

  // Desktop styles
  @include media-breakpoint-up(lg) {
    height: 100%;
    position: relative;
    z-index: 1;

    @include e(label) {
      color: $color-black;
      height: 100%;
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr 25px;
      grid-template-rows: 1fr;
      grid-template-areas: 'label chevron';
      outline: none;
      padding: 0 9px;
      font-size: 16px;
      font-family: $ted-next;
      font-weight: $medium;
      align-items: center;
      justify-content: center;

      .chevron {
        grid-area: chevron!important;
      }
      .text {
        grid-area: label;
        padding: 0 1px;
        &::after {
          grid-area: label;
          content: attr(data-text);
          content: attr(data-text) / '';
          height: 0;
          width: 100%;
          padding: 0;
          line-height: 0;
          font-size: 0;
          visibility: hidden;
          overflow: hidden;
          user-select: none;
          pointer-events: none;
          font-weight: $demibold;
          z-index: -1;
        }
      }

      &:hover,
      &:focus {
        color: $color-black;
        font-weight: $demibold;
        outline: none !important;

        .text {
          padding: 0!important;
        }
      }

      @include m(is-active) {
        font-weight: $bold !important;
        .text {
          padding: 0!important;
        }
      }

      .icon {
        &::before {
          font-size: 16px;
          color: $color-black;
        }
      }

      &:hover {
        .text {
          padding: 0!important;
        }
        .icon {
          &::before {
            color: $color-primary-cta;
          }
        }
      }
    }

    @include e(drawer) {
      display: none;
      top: 100%;
      left: 0;
      position: absolute;
      white-space: nowrap;
      z-index: 1;
      @include media-breakpoint-up(lg) {
        left: -20px;
      }
    }

    @include e(chevron) {
      display: inline-block;
      padding: 4px;
      position: relative;
      margin-left: 2px;
      transform: scale(0.4);
      height: 11px;
    }

    @include e(links) {
      color: $color-black;
      font-size: 14px;
      list-style-type: none;
    }

    @include e(group-title) {
      border: 0;
      background: none;
    }

    @include e(link) {
      a {
        cursor: pointer;
        display: block;
        outline: none;
        color: map-get($colors, primary);
        padding: map-get($copy-spacing, sm) map-get($copy-spacing, xxl);
        font-weight: $medium;
        text-decoration: none;
        letter-spacing: 0.07px;

        &:hover,
        &:focus {
          color: map-get($colors, secondary);
          font-weight: bold;
          text-decoration: underline;
          letter-spacing: 0;
          p {
            text-decoration: underline;
          }
        }

        .link__text {
          padding-left: 0;
        }
      }

      &:hover,
      &:focus {
        &:last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      @include m(extra) {
        display: none;
      }
    }

    @include m(selected) {
      @include e(label) {
        color: $color-black;
        font-weight: $demibold;
        outline: none;
        // @include media-breakpoint-up(lg) {
        //   &:after {
        //     position: absolute;
        //     content: '';
        //     height: 3px;
        //     width: calc(100% - 40px);
        //     bottom: 0;
        //     background: map-get($colors, primary);
        //   }
        // }
      }

      @include e(drawer) {
        display: block;
        border-top: 1px solid map-get($colors-neutral, light);
        background-color: white;
        border-bottom: 4px solid map-get($colors, primary);
        border-radius: 0 0 5px 5px;
      }
    }
  }

  // Touch styles
  @include media-breakpoint-down(md) {
    @include e(label) {
      color: $color-black;
      width: 100%;
      display: flex;
      padding: map-get($copy-spacing, lg) map-get($copy-spacing, lg);
      font-size: 18px;
      font-weight: $medium;
      justify-content: space-between;
      cursor: pointer;

      @include media-breakpoint-only(md) {
        padding: 24px 40px 22px;
      }

      @include m(expanded) {
        font-weight: $demibold;
      }
      @include m(is-active) {
        font-weight: $demibold !important;
      }
    }

    @include e(chevron) {
      margin-left: auto;
      margin-right: 0;
    }

    @include e(drawer) {
      @include media-breakpoint-down(md) {
        height: 0;
        overflow: hidden;
        will-change: height;
        transition: height 200ms ease-in-out;
      }
    }

    @include e(links) {
      background: $color-search-grey;
      padding-top: map-get($copy-spacing, md);
      padding-bottom: map-get($copy-spacing, md);
      list-style-type: none;
    }

    @include e(group-title) {
      border: 0;
      background: none;
    }

    @include e(link) {
      color: $color-black;
      font-size: 14px;

      a {
        display: block;
        padding: map-get($copy-spacing, sm) map-get($copy-spacing, lg);
        color: map-get($colors, primary);
        text-decoration: none;
        font-weight: $medium;
        margin-top: 3px;
        margin-bottom: 3px;

        &.active {
          color: map-get($colors, secondary);
          font-weight: $demibold;
        }
      }

      &:hover {
        font-weight: $demibold;
        text-decoration: underline;
      }

      @include m(extra) {
        color: $color-black;

        &:hover {
          font-weight: $demibold;
          text-decoration: underline;
        }
      }

      @include media-breakpoint-only(md) {
        a {
          padding: map-get($copy-spacing, sm) map-get($copy-spacing, xxl);
        }
      }
    }
  }
  @include m(scotch) {
    .global-nav-dropdown__links {
      @include media-breakpoint-down(md) {
        background-color: map-get($rogers-colors, background-medium);
      }
    }
    .global-nav-dropdown__link a {
      color: map-get($rogers-colors, body);
      font-family: $ted-next;
      margin-top: 0;
      &:focus {
        outline: none !important;
        color: map-get($rogers-colors, primary-light-hover);
      }
      &.is-active {
        color: map-get($rogers-colors, primary-light);
      }
      &:hover,
      &:active {
        text-decoration: underline;
        color: map-get($rogers-colors, primary-light);
      }
    }
  }
}
