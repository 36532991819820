// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';
$padding-top-sm: 24px;
$padding-bottom-sm: 22px;
$padding-left-right-sm: 5px;
$padding-left-right-md: 40px;

.support-search {
  position: absolute;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  max-width: 1160px;
  height: 68px;

  @include media-breakpoint-only(sm) {
    width: 98%;
  }

  @include m(opened) {
    .support-search {
      &__input-wrapper {
        &::after {
          width: 100%;
        }
      }

      &__input-wrapper > .support-search__submit-button {
        @include media-breakpoint-down(md) {
          .search-results & {
            display: block;
          }
        }
      }

      &__label {
        display: none;
      }
    }
  }
  @include e(input-wrapper) {
    width: 100%;
    padding: 0 10px !important;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    align-items: center;
    background-color: $color-white;
    border-radius: 100px;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.14);
  }

  @include e(icon) {
    font-size: 15px;
    margin-right: 0;
  }

  @include e(label) {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  @include e(button) {
    color: $color-white;
    background-color: $color-primary;
    border: 0;
    cursor: pointer;
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding: $padding-top-sm $padding-left-right-sm $padding-bottom-sm;
    text-align: left;
    width: 100%;

    .no-touch &:hover {
      color: $color-azure;
    }

    @include media-breakpoint-down(lg) {
      color: $color-search-grey;

      @include m(has-value) {
        color: $color-primary-cta;
        cursor: pointer;
        position: absolute;
        width: 10%;
        z-index: 999;

        .no-touch &:hover {
          color: $color-primary-hover;
        }
      }
    }

    @include media-breakpoint-only(lg) {
      color: $color-oxford-blue;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }

  @include e(input) {
    color: $color-oxford-blue;
    font-family: $ted-next;
    background-color: $color-transparent;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    min-width: 200px;
    height: 68px;
    border: none;
    padding-left: 15px;
    padding-top: 5px;

    &:focus,
    &:focus-visible {
      outline: none !important;
    }

    @include media-breakpoint-only(lg) {
      font-size: 18px;
      width: 100%;
    }

    &::placeholder {
      color: $color-fog-dark;
    }
  }

  @include e(submit-button) {
    background-color: $color-primary;
    border: 0;
    border-radius: 50%;
    line-height: 0;
    padding: 10px;
    height: 50px;
    width: 50px;
    color: $color-oxford-blue;
    align-self: center;
    justify-self: center;
    display: inline-block;

    .icon {
      color: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      padding: 0;
      margin: 0;
      &::before {
        font-size: 24px;
      }
    }

    @include m(has-value) {
      color: $color-primary-cta;
      cursor: pointer;

      &:hover {
        color: $color-primary-hover;
      }
    }
  }

  @include m(scotch) {
    .support-search__submit-button {
      cursor: pointer;
      outline: none;
      background-color: map-get($rogers-colors, primary-light);
      &:hover {
        background-color: map-get($rogers-colors, primary-light-hover);
      }
    }
    .support-search__input {
      font-size: 14px;
      color: #989898;
    }
  }
}
