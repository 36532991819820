// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(support-outage-details) {
  @include e(container) {
    @include make-container();
  }

  @include e(intro) {
    @include make-container();
    padding: map-get($copy-spacing, xxl) 0;
    max-width: map-get($widths, narrow);
    text-align: center;
    margin-bottom: map-get($copy-spacing, lg);
  }
}
