// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

.c-full-width-image-banner {
  clear: both;
  min-height: 300px;
  a {
    display: block;
  }
  img {
    min-height: 300px;
  }
}
