// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

.c-four-up-promo-container {
  background-color: white;
  @include m(primary) {
    background-color: map-get($colors, primary);
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
  }
  @include m(dark) {
    background-color: $color-dark-blue;
  }
  @include m(black) {
    background-color: black;
  }

  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }
  @include m(no-margin) {
    .c-four-up-promo {
      padding: 0;
    }
  }
  .c-four-up-promo {
    @include media-breakpoint-only(sm) {
      margin-left: map-get($copy-spacing, lg);
      margin-right: map-get($copy-spacing, lg);
    }
    .c-four-up-item {
      background-color: #f6f6f6;

      display: grid;
      grid-template-rows: auto 1fr;
      .c-responsive-image {
        align-self: flex-start;
      }
      .c-four-up-item__content {
        display: flex;
        flex-direction: column;

        .c-four-up-item__title {
          margin-bottom: 15px;
        }
        .c-four-up-item__description {
          margin-bottom: map-get($copy-spacing, lg);
          ul,
          ol {
            margin: 10px 15px;
          }
        }
        .c-link {
          margin-top: auto;
          outline: none !important;
          &:active,
          &:focus {
            outline: none !important;
          }
        }
      }
    }
  }
}
