// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(support-outage-article) {
  background-color: white;
  width: 100%;
  padding: map-get($copy-spacing, xl);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid map-get($colors-overlay, line);
  border-radius: map-get($copy-spacing, xs);
  max-width: map-get($widths, narrow);
  margin: auto;

  @include media-breakpoint-only(sm) {
    text-align: center;
  }

  .outage-live-update + .outage-live-update {
    border-top: 0;
  }

  @include m(details) {
    border-bottom: none;
    margin-bottom: map-get($component-spacing, xl);

    @include media-breakpoint-only(sm) {
      text-align: left;
      .support-outage-article__summary {
        text-align: left;
      }
    }

    .support-outage-article__status {
      margin: 0;
    }
  }
  @include e(title) {
    font-family: $ted-next;
    font-weight: 200;
    width: 100%;
    > h2 {
      font-weight: 500;
      flex-basis: 74%;
      @include media-breakpoint-only(sm) {
        text-align: center;
        border-bottom: 1px solid map-get($colors-neutral, medium);
        padding-bottom: map-get($copy-spacing, xl);
      }
    }

    @include media-breakpoint-only(sm) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  @include e(status) {
    margin: 0 map-get($copy-spacing, sm);
    background-color: map-get($colors-alert, error);
    display: inline-flex;
    border-radius: 100px;
    padding: map-get($copy-spacing, sm) map-get($copy-spacing, xl);
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    line-height: map-get($copy-spacing, lg);
    flex-grow: none;

    @include media-breakpoint-only(sm) {
      margin: 0;
      margin-bottom: map-get($copy-spacing, md);
      width: auto;
    }

    @include media-breakpoint-up(md) {
      margin-left: auto;
    }
    @include m(resolved) {
      background-color: map-get($colors-alert, success);
    }

    @include m(archived) {
      background-color: map-get($colors-neutral, medium);
    }
  }

  @include e(summary) {
    margin: map-get($copy-spacing, xl) 0;
    font-size: 12px;
    @include media-breakpoint-only(sm) {
      text-align: center;
      font-size: 16px;
    }
  }
  @include e(live-updates) {
    @include media-breakpoint-only(sm) {
      text-align: left;
    }
  }
  @include e(live-updates-title) {
    font-size: 12px;
    margin-bottom: map-get($copy-spacing, md);
    @include media-breakpoint-only(sm) {
      font-size: 16px;
    }
  }

  @include e(more-details) {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    margin-top: map-get($copy-spacing, md);
    @include media-breakpoint-only(sm) {
      font-size: 16px;
    }

    &:after {
      content: '';
      display: inline-block;
      transform: translate(5px, 3px) rotate(-90deg);
      height: 10px;
      width: 10px;
      transition: transform 0.1s ease-in-out;
      background-size: 8px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDUycHgiIGhlaWdodD0iMjU4cHgiIHZpZXdCb3g9IjAgMCA0NTIgMjU4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImFycm93LWRvd24tc2lnbi10by1uYXZpZ2F0ZSIgZmlsbD0iIzAwODJCQiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTIyNS45MjMsMjU3LjcwNiBDMjE3LjgyNSwyNTcuNzA2IDIwOS43MjgsMjU0LjYxNCAyMDMuNTU0LDI0OC40NDMgTDkuMjcsNTQuMTU3IEMtMy4wODksNDEuNzk4IC0zLjA4OSwyMS43NiA5LjI3LDkuNDA2IEMyMS42MjQsLTIuOTQ4IDQxLjY1OCwtMi45NDggNTQuMDE4LDkuNDA2IEwyMjUuOTIzLDE4MS4zMjEgTDM5Ny44MjksOS40MTIgQzQxMC4xODgsLTIuOTQyIDQzMC4yMiwtMi45NDIgNDQyLjU3Myw5LjQxMiBDNDU0LjkzOCwyMS43NjYgNDU0LjkzOCw0MS44MDQgNDQyLjU3Myw1NC4xNjMgTDI0OC4yOTIsMjQ4LjQ0OSBDMjQyLjExNSwyNTQuNjIxIDIzNC4wMTgsMjU3LjcwNiAyMjUuOTIzLDI1Ny43MDYgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
    }
    &:hover {
      &:after {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDUycHgiIGhlaWdodD0iMjU4cHgiIHZpZXdCb3g9IjAgMCA0NTIgMjU4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkNhcmV0IiBmaWxsPSIjOTQ2NEZEIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cGF0aCBkPSJNMjI1LjkyMywyNTcuNzA2IEMyMTcuODI1LDI1Ny43MDYgMjA5LjcyOCwyNTQuNjE0IDIwMy41NTQsMjQ4LjQ0MyBMOS4yNyw1NC4xNTcgQy0zLjA4OSw0MS43OTggLTMuMDg5LDIxLjc2IDkuMjcsOS40MDYgQzIxLjYyNCwtMi45NDggNDEuNjU4LC0yLjk0OCA1NC4wMTgsOS40MDYgTDIyNS45MjMsMTgxLjMyMSBMMzk3LjgyOSw5LjQxMiBDNDEwLjE4OCwtMi45NDIgNDMwLjIyLC0yLjk0MiA0NDIuNTczLDkuNDEyIEM0NTQuOTM4LDIxLjc2NiA0NTQuOTM4LDQxLjgwNCA0NDIuNTczLDU0LjE2MyBMMjQ4LjI5MiwyNDguNDQ5IEMyNDIuMTE1LDI1NC42MjEgMjM0LjAxOCwyNTcuNzA2IDIyNS45MjMsMjU3LjcwNiBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');

        transform: translate(7px, 3px) rotate(-90deg);
      }
    }
  }
  @include e(share) {
    margin-top: map-get($copy-spacing, xl);
    display: flex;
    .share-button {
      margin-left: auto;
      @include media-breakpoint-only(sm) {
        margin: 0;
        width: 100%;
        text-align: center;

        > button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
