// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';
@import 'app/components/IconBadge/IconBadge';
@import 'style/utils/bem';

@include b(final-message) {
  text-align: center;
  max-width: 566px;
  margin: 40px auto;

  @include e(title) {
    font-family: $ted-next;
    font-weight: $demibold;
    font-size: 40px;
    margin: 40px 0;
  }
  @include e(message) {
    margin-bottom: 50px;
    line-height: 24px;
    font-size: 16px;

    a {
      font-weight: bold;
    }
  }
  @include e(go-back) {
    border: 0;
    background: none;
    font-size: 16px;
    color: map-get($colors, primary);
    display: flex;
    align-items: center;
    justify-self: center;
    align-self: center;
    margin: 20px auto 15px auto;
    cursor: pointer;

    &:hover > span {
      text-decoration: underline;
    }

    &:before {
      content: '\e927';
      font-family: 'shaw-icons';
      font-size: 30px;
      margin-right: 7px;
    }
  }
}
