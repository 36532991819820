// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.white {
  .eyebrow,
  .basic-text-box__title,
  .basic-text-box__description,
  .image-static__title {
    color: white;
  }
  .image-static__text-box-container {
    .basic-text-box {
      ul > li:before {
        background: url(../../../components/SVG/checkmark-white.png) no-repeat
          left 12px;
      }
    }
  }
}

@include b(image-static) {
  background-color: transparent;
  clear: both;

  @include m(title-small) {
    .basic-text-box__title {
      font-size: 24px;
      line-height: 1.2em;
    }
  }
  @include m(title-medium) {
    .basic-text-box__title {
      font-size: 32px;
      line-height: 1.2em;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }
  }
  @include m(title-large) {
    .basic-text-box__title {
      font-size: 40px;
      line-height: 1.2em;
      @include media-breakpoint-down(md) {
        font-size: 32px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }
  }


  @include m(dark) {
    background-color: map-get($rogers-colors, body);
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }
  @include m(red) {
    background-color: map-get($rogers-colors, primary-light);
    @extend .white;
  }
  @include m(scotch) {
    .image-static__text-box-container {
      .basic-text-box {
        ul > li:before {
          background: url(../../../components/SVG/checkmark-dark.svg) no-repeat
            left 12px;
        }
      }
    }
  }

  @include m(imageRight) {
    @include e(container) {
      grid-template-areas: 'title title' 'text image';

      @include media-breakpoint-down(sm) {
        grid-template-rows: min-content min-content auto;
        grid-template-columns: 1fr;
        grid-template-areas:
          'title'
          'image'
          'text';
      }
    }
  }
  @include m(grid) {
    @include e(container) {
      grid-template-areas: 'title title' 'text text' 'image image';
      padding-bottom: 0;

      .basic-text-box-stack__content--grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, min-content);
        justify-items: center;
        grid-gap: 80px;
        grid-row-gap: 50px;
        max-width: 650px;

        @include media-breakpoint-down(sm) {
          grid-template-columns: 1fr;
          justify-content: center;
        }
      }

      .image-static__text-box-container {
        margin: auto;
        .basic-text-box {
          text-align: center;
          margin-bottom: 0;
        }
      }
      .image-static__image-container {
        margin-bottom: 0;
      }
    }
  }
  //
  @include m(row) {
    @include e(container) {
      grid-template-areas: 'title title' 'text text' 'image image';

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-template-areas: 'title' 'text' 'image';
      }

      .basic-text-box-stack__content--row {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        justify-content: center;

        .image-static__button {
          justify-self: center;
          align-self: center;
          grid-row: 2/3;
          grid-column: 1/-1;
          text-align: center;
          margin-bottom: 30px;
        }
        @include media-breakpoint-down(md) {
          display: flex;
          justify-content: center;
          .basic-text-box,
          .image-static__button {
            margin-left: auto;
            margin-right: auto;
          }
        }
        @include media-breakpoint-down(sm) {
          .basic-text-box {
            text-align: center;
          }
        }
        .basic-text-box {
          @include media-breakpoint-up(lg) {
            margin-left: 45px;
            margin-right: 45px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  @include e(container) {
    @include make-container();
    @include make-container-max-widths();
    padding-top: 50px;
    padding-bottom: 50px;
    display: grid;
    grid-column-gap: 60px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content min-max(min-content, auto);
    grid-template-areas: 'title title' 'image text';

    @include media-breakpoint-down(md) {
      grid-column-gap: 30px;
      padding-left: 50px;
      padding-right: 50px;
    }

    @include media-breakpoint-down(sm) {
      padding: 30px;
      grid-gap: 30px;
      grid-template-rows: max-content repeat(2, min-max(min-content, auto));
      grid-template-columns: 1fr;
      grid-template-areas: 'title' 'image' 'text';
    }

    @include m(narrow) {
      width: $md-max-width;
      padding-top: 70px;
      padding-bottom: 70px;

      @include media-breakpoint-down(sm) {
        padding: 30px;
      }
    }

    @include m(wide) {
      grid-column-gap: 60px;
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;

      @include media-breakpoint-only(sm) {
        margin-bottom: 0;
        padding-top: 0;
      }
      @include media-breakpoint-only(md) {
        padding-left: 30px;
        padding-right: 30px;
      }

      .basic-text-box {
        max-width: 100%;
        margin-bottom: 0;

        .eyebrow + .basic-text-box__title {
          @include h3black();
          text-align: left;
        }
      }
      .basic-text-box-stack__content--row {
        .basic-text-box {
          .basic-text-box__title {
            @include h3();
            text-transform: none;
            text-align: center;
          }
        }
      }
      .image-static__image-container .responsive-image {
        max-width: 100%;
      }

      .basic-text-box-stack__buttons {
        grid-area: image;
        margin-left: auto;
        margin-right: auto;
        grid-column: 1 / -1;
      }

      &.image-static__imageTop,
      &.image-static__imageBottom {
        .basic-text-box-stack__buttons {
          margin-left: 0;
          margin-right: 0;

          @include media-breakpoint-only(sm) {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }

  @include e(desktop-imageTop) {
    padding-top: 0;
    .image-static__image-container {
      margin-top: 0;
    }
  }

  @include e(desktop-imageBottom) {
    padding-bottom: 0;
    .image-static__image-container {
      margin-bottom: 0;
    }
  }
  @include e(imageBottom) {
    padding-bottom: 0;
    .image-static__image-container {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      grid-template-areas: 'title' 'text' 'image' !important;
      .image-static__text-box-container {
        margin-bottom: 30px;
      }
    }
  }

  @include e(title) {
    grid-area: title;
    margin: 45px auto;
    text-align: center;
    @include h2();
    font-size: 40px;
    line-height: 1.2em;

    @include media-breakpoint-only(sm) {
      margin-left: 15px;
      margin-right: 15px;
      font-size: 32px;
    }
  }
  @include e(image-container) {
    grid-area: image;
    margin-top: auto;
    margin-bottom: auto;

    .responsive-image {
      text-align: center;
      max-width: 100%;
    }
    img {
      max-width: 100%;
    }
  }
  @include e(text-box-container) {
    grid-area: text;
    margin-top: auto;
    margin-bottom: auto;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
    .basic-text-box-stack__content {
      @include media-breakpoint-down(sm) {
        text-align: left;
        align-items: flex-start;
        .image-static__button {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .basic-text-box {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .rich-text {
        margin-bottom: 1em;
      }
      ul {
        list-style: none;
        margin: 1em 0;

        li {
          line-height: 2em;
          display: flex;

          @include media-breakpoint-only(sm) {
            text-align: left;
          }

          &:before {
            content: '';
            height: 25px;
            display: flex;
            background: url(../../../components/SVG/checkmark.png) no-repeat
              left 12px;
            background-size: 50%;
            flex-basis: 30px;
            flex-grow: 0;
            flex-shrink: 0;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        .eyebrow {
          text-align: center !important;
        }
      }

      .eyebrow + .basic-text-box__title {
        font-size: 32px;
        @include media-breakpoint-down(sm) {
          text-align: center;
        }
      }
    }
  }

  @include m(scotch) {
    &.image-static--white {
      @include e(text-box-container) {
        .basic-text-box {
          ul li {
            &:before {
              background: url(../../../components/SVG/checkmark-dark.svg)
                no-repeat left 12px;
            }
          }
        }
      }
    }
  }

  .basic-text-box-stack__buttons {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-only(sm) {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
  }
}
