@import '../../assets/fonts/fonts.scss';
@import '../globals/_colors.scss';
@import 'style/grid/grid';
@import 'style/utils/bem';

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@mixin h1 {
  font-style: normal;
  font-stretch: normal;
  line-height: 40px;
  font-size: 40px;
  font-family: $ted-next;
  font-weight: $bold;

  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }
}

@mixin h2 {
  font-style: normal;
  font-stretch: normal;
  line-height: 32px;
  font-size: 32px;
  font-family: $ted-next;
  font-weight: $bold;

  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

@mixin h3black {
  font-style: normal;
  font-stretch: normal;
  font-size: 24px;
  line-height: 32px;
  font-family: $ted-next;
  font-weight: $bold;

  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}
@mixin h3 {
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  font-size: 24px;
  font-family: $ted-next;
  font-weight: $bold;

  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

@mixin h4 {
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  font-size: 16px;
  font-family: $ted-next;
  font-weight: $demibold;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

@mixin hero-heading {
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  text-align: center;
  font-size: 40px;
  font-family: $ted-next;
  font-weight: $demibold;

  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }
}

@mixin hero-subheading {
  font-family: $ted-next;
  font-weight: $medium;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  text-align: center;

  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.1px;
  }
}

@mixin module-heading {
  font-family: $ted-next;
  font-weight: $demibold;
  font-size: 36px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  text-align: center;
  color: $color-oxford-blue;
  margin: 0 auto 20px;

  @include media-breakpoint-down(md) {
    font-size: 28px;
  }
}

@mixin section-heading {
  font-family: $ted-next;
  font-weight: $demibold;
  font-size: 28px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  text-align: center;
  color: $color-oxford-blue;
}

@mixin black {
  font-family: $ted-next;
  font-weight: $bold;
}
@mixin bold {
  font-family: $ted-next;
  font-weight: $demibold;
}
@mixin medium {
  font-family: $ted-next;
  font-weight: $medium;
}
@mixin xxl-header {
  @include black();
  font-size: 100px;
  line-height: 88px;
}
@mixin xl-header {
  @include black();
  font-size: 80px;
  line-height: 68px;
}
@mixin large-header {
  @include black();
  font-size: 52px;
  line-height: 48px;
}
@mixin header-one {
  @include bold();
  font-size: 40px;
  line-height: 40px;
}
@mixin header-one-black {
  @include black();
  font-size: 40px;
  line-height: 40px;
}
@mixin header-two {
  @include bold();
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
}
@mixin header-two-black {
  @include black();
  font-size: 32px;
  line-height: 32px;
}
@mixin header-three {
  @include bold();
  font-size: 24px;
  line-height: 24px;
}
@mixin header-three-black {
  @include black();
  font-size: 24px;
  line-height: 32px;
}
@mixin header-four {
  @include bold();
  font-size: 16px;
  line-height: 24px;
}
@mixin header-four-black {
  @include black();
  font-size: 16;
  line-height: 24px;
}
@mixin eyebrow {
  @include bold();
  font-size: 14px;
  line-height: 24px;
}

@mixin body {
  font-family: $ted-next;
  font-weight: $medium;
}
@mixin xxl-body {
  @include body();
  font-size: 24px;
  line-height: 32px;
}
@mixin xl-body {
  @include body();
  font-size: 20px;
  line-height: 28px;
}
@mixin large-body {
  @include body();
  font-size: 16px;
  line-height: 24px;
}
@mixin primary-body {
  @include body();
  font-size: 14px;
  line-height: 24px;
}
@mixin medium-body {
  @include body();
  font-size: 12px;
  line-height: 24px;
}
@mixin small-body {
  @include body();
  font-size: 10px;
  line-height: 16px;
}

@mixin label-large {
  @include body();
  font-size: 18px;
  line-height: 24px;
}
@mixin label-medium {
  @include body();
  font-size: 16px;
  line-height: 24px;
}
@mixin label {
  @include body();
  font-size: 12px;
  line-height: 24px;
}

@mixin price-bug {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &--bold {
    font-weight: bold;
  }

  &__price {
    display: flex;
    font-family: $ted-next;
    font-weight: $demibold;
    font-size: 28px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.6px;
    text-align: center;
    color: $color-oxford-blue;
    margin-bottom: 5px;
    position: relative;
  }

  &__price-symbol {
    font-size: 11px;
    font-weight: bold;
    position: relative;
    line-height: 16px;
  }

  &__price-cents {
    font-size: 11px;
    font-weight: bold;
    position: relative;
    margin-right: 5px;
  }

  &__cents-per-container {
    display: flex;
    flex-direction: column;
    margin-left: 2px;
    align-items: flex-start;
    justify-content: flex-end;
  }

  &__per-text {
    font-size: 11px;
    font-weight: bold;
    align-self: flex-start;
    line-height: 16px;
    position: relative;
    display: flex;

    .c-disclaimer-cta {
      font-size: 0.9em;
      transform: translate(0px, -0.4em);
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__text-below {
    font-family: $ted-next;
    font-weight: $medium;
    font-size: 11px;
    line-height: 1.33;
    color: $color-bluish-grey;
    width: 95%;
    text-align: center;
  }
}

@mixin stylized-name {
  font-family: $ted-next;
  font-weight: $demibold;
  font-size: 26px;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: 0.1px;
  text-align: center;
  color: $stylized-name-color;
}

a.chat-unavailable {
  color: $color-light-grey;
}

a.chat-unavailable:hover {
  color: $color-light-grey !important;
}

.hidden {
  display: none !important;
}

.transparency {
  transition: all ease-in-out 0.5s;
}

.non-transparent {
  opacity: 1;
}

.transparent {
  opacity: 0;
}
