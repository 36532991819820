// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';
.white {
  .testimonial__quote,
  .eyebrow {
    color: white;
  }
  .testimonial__quote-left,
  .testimonial__quote-right {
    color: white;
    opacity: 0.4;
  }
}
@include b('case-study-testimonial') {
  background-color: transparent;
  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }
}
