// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.c-superpromo--white {
  .c-superpromo__title {
    sup {
      position: static;
      transform: none;
      vertical-align: top;
      text-decoration: underline;
    }
  }
}
.c-superpromo {
  max-height: none;
  display: flex !important;
  flex-direction: column-reverse;
  @include media-breakpoint-only(sm) {
    overflow: hidden;
  }
  @include media-breakpoint-up(md) {
    display: grid !important;

    // max-height: 400px;
  }

  .c-superpromo__content-container {
    max-width: 100%;
    @include media-breakpoint-only(md) {
      padding-top: map-get($component-spacing, sm);
      padding-bottom: map-get($component-spacing, sm);
    }
    @include media-breakpoint-only(sm) {
      .c-superpromo__title {
        span {
          /* These are technically the same, but use both */
          overflow-wrap: break-word;
          word-wrap: break-word;

          -ms-word-break: break-all;
          /* This is the dangerous one in WebKit, as it breaks things wherever */
          word-break: break-all;
          /* Instead use this non-standard one: */
          word-break: break-word;

          /* Adds a hyphen where the word breaks, if supported (No Blink) */
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto;
          font-size: 8.9vw;
          line-height: 8.9vw;
        }
      }
    }
    @include media-breakpoint-up(md) {
      min-height: 400px;

      .c-eyebrow {
        text-align: left;
      }
    }
    @include media-breakpoint-up(lg) {
      min-height: 560px;

      .c-superpromo__title {
        font-size: 52px;
        line-height: 52px;
      }
      .c-superpromo__description {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .c-superpromo__image-container--center {
    .c-responsive-image {
      img {
        @include media-breakpoint-up(md) {
          object-fit: contain;
        }
      }
    }
  }

  .c-responsive-image {
    display: flex;
    align-self: stretch;
    img {
      height: 100%;
      max-width: 100vw;
      @include media-breakpoint-down(sm) {
        width: 100vw;
      }

      @include media-breakpoint-up(md) {
        height: 100%;
      }
      // @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      //   max-height: 400px;
      // }

      @include media-breakpoint-up(lg) {
        height: 100%;
      }
    }
  }
  .c-superpromo__button-container {
    a:first-child {
      @include media-breakpoint-up(md) {
        margin-left: 0;
      }
    }
    @include media-breakpoint-only(sm) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .button {
        width: max-content;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
