// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

// Module styles
@include b('hero-item-tiles') {
  display: flex;
  flex-flow: column;
  width: 100%;
  @include e(intro) {
    background-color: $color-white;
    text-align: center;
    padding: 100px 15px 30px 15px;
    font-size: 16px;
    font-family: $ted-next;
    font-weight: 400;
    .rich-text {
      @include make-container();
      @include make-container-max-widths();
    }
  }

  @include e(notification-container) {
    background-color: white;
    .notification {
      margin: 0 auto;
      max-width: 980px;
    }
  }

  .hero-item-tiles__intro {
    font-family: $ted-next;
    line-height: 1.6em;
  }
}
