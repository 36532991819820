// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Module styles
.get-in-touch {
  width: 100%;

  @include e('address') {
    margin: 0 auto 80px;
    text-align: center;
    color: $color-bluish-grey;

    h3 {
      @include h3black();
      color: $color-oxford-blue;
      margin: 0 0 20px;
    }

    h4 {
      @include h4();
      color: $color-oxford-blue;
      margin: 0 0 10px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }
  }

  @include e('address-card') {
    @include make-container();
    @include make-container-max-widths();

    @include media-breakpoint-down(md) {
      width: 95%;
    }
  }

  @include e('address-title') {
    h3 {
      margin: 20px;
      @include h3black();
    }
  }

  @include e('address-description') {
    padding: 0 30px 30px;
  }

  @include e('footer-text') {
    @include make-container();
    @include make-container-max-widths();

    @include media-breakpoint-down(lg) {
      width: 95%;
    }
  }

  @include e('footer-body') {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    color: $color-bluish-grey;
    line-height: 1.47em;

    h2 {
      font-family: $ted-next;
      font-size: 36px;
      font-weight: normal;
      color: $color-oxford-blue;
      margin: 0 0 30px;

      img {
        vertical-align: middle;
      }
    }

    h3 {
      @include h3black();
      color: $color-oxford-blue;
      margin: 0 0 20px;
    }


    @include media-breakpoint-down(sm) {
      width: 100%;

      h2 {
        img {
          max-width: 168px;
        }
      }

      h3 {
        font-size: 18px;
        line-height: normal;
      }
    }
  }

  @include e('cta-container') {
    margin: 20px 0 60px;
  }

  @include e('block') {
    padding: 50px 0;

    &:last-of-type {
      padding-bottom: 0;
    }

    h3 {
      @include h3black();
      text-align: left;
      color: $color-oxford-blue;
      padding: 0 20px 20px;
      margin: 20px 0;
      border-bottom: 1px solid $color-fog-dark;
    }

    @include m('even') {
      background-color: $color-white;
    }

    @include media-breakpoint-down(md) {
      h3 {
        padding: 0 0 20px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  @include e('block-body') {
    @include media-breakpoint-only(lg) {
      @include make-container();
      @include make-container-max-widths();
    }

    width: 100%;
    display: table;

    @include media-breakpoint-down(md) {
      width: 70%;
      margin: 0 auto;
    }

    @include media-breakpoint-down(sm) {
      width: 90%;
      margin: 0 auto;
    }
  }

  @include e('block-half') {
    width: 50%;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top;
    padding: 0 30px;

    img {
      width: 100%;
      height: auto;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      display: block;

      @include m('img') {
        display: none;
      }
    }
  }

  @include e('contacts') {
    padding: 10px;
  }

  @include e('contacts-group') {
    margin-bottom: 30px;
  }

  @include e('contact') {
    margin-bottom: 15px;
  }

  @include e('contact-name') {
    font-size: 16px;
    line-height: 24px;
    color: $color-bluish-grey;
    margin-bottom: 5px;
    font-weight: 500;
  }

  @include e('contact-value') {
    @include media-breakpoint-down(sm) {
      a {
        font-size: 0.9em;
      }
    }
  }

  @include e('group-icon') {
    display: inline-block;
    vertical-align: top;
    padding-right: 20px;

    span::before {
      font-size: 32px;
      color: $color-oxford-blue;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      padding: 20px 0;
    }
  }

  @include e('group-items') {
    display: inline-block;
    vertical-align: top;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}
