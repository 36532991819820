// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';
@import 'style/grid/breakpoints';

$underline-width: 3px;

// Module styles
@include b('sticky-nav') {
  position: relative;

  @include e('sticky-nav') {
    width: 100%;
    height: 91px;
    background-color: $color-white;

    @include m('gray') {
      background-color: $color-pale-grey;
    }
  }

  @include e('items') {
    @include make-container;
    @include make-container-max-widths();

    overflow: hidden;
    ul {
      list-style-type: none;
      padding: 0;
      white-space: nowrap;
      display: flex;
      justify-content: center;

      > li {
        margin-left: 30px;
        margin-right: 30px;
      }
    }
    @include media-breakpoint-down(md) {
      width: 100%;

      ul {
        justify-content: flex-start;
        > li {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    @media only screen and (min-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      ul {
        justify-content: center;
        > li {
          margin-left: 30px;
          margin-right: 30px;
        }
      }
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      ul {
        > li {
          margin-left: 30px;
          margin-right: 30px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .msie &,
      .msedge & {
        -ms-overflow-style: none;
      }

      .firefox & {
        overflow: -moz-scrollbars-none;
      }

      ul {
        display: block;
      }

      ul > li {
        padding-right: 30px;
      }
    }

    @include m(narrow) {
      ul {
        justify-content: center;
      }
    }
  }

  @include e('item') {
    display: inline-block;
    vertical-align: top;

    a {
      color: $color-oxford-blue;
      font-family: $ted-next;
      font-size: 1.2em;
      font-weight: 500;
      display: block;
      padding: 3px;
      margin: 30px 0;
      text-decoration: none;
    }

    a:focus,
    a:hover,
    a:active,
    a:visited {
      color: $color-oxford-blue;
      text-decoration: none;
      border-bottom: solid $underline-width $color-white;
    }

    a:hover {
      border-bottom: solid $underline-width $color-azure;
    }

    @include m('active') {
      a,
      a:focus,
      a:active,
      a:visited {
        font-weight: bold;
        border-bottom: solid $underline-width $color-azure-contrast;
      }
    }
  }
}

.sticky.fixed > div.hero-sticky-nav__sticky-nav {
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.14);
}
