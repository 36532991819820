// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.c-superhero {
  background: white;
  overflow: hidden;
  max-width: 100vw;

  @include media-breakpoint-up(lg) {
    min-height: 736px !important;
  }
  .c-superhero__video {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .c-superhero__content-container {
    position: relative;
    z-index: 5;
    padding-top: 48px;
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .c-superhero__title {
    font-family: $ted-next;
    font-weight: 900;
    margin-top: 0;
    padding-top: 0;
    sup {
      top: -0.25em;
      left: 2px;
      width: auto;
    }
  }

  .c-superhero__image-container {
    .c-responsive-image {
      img {
        width: 100%;
        max-width: 494px;
        margin-top: 0;
        @include media-breakpoint-down(md) {
          width: 100%;
          max-width: 340px;
          margin-top: 0;
        }
        @include media-breakpoint-down(sm) {
          width: 320px;
          max-width: 90%;
          margin-top: 0;
        }
      }
    }
  }
  .c-superhero__crescent {
    transform: translateY(1px);
  }
  .c-background {
    max-height: none;
  }
}
