// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Module styles
@include b(form) {
  padding-bottom: 56px;
  .c-disclaimer-cta {
    font-size: 12px;
  }

  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    .form__desciption-body h2,
    .form-group__title {
      color: white;
    }
  }
  @include m(primary) {
    background-color: $color-primary;
    .form__desciption-body h2,
    .form-group__title {
      color: white;
    }
  }
  @include m(secondary) {
    background-color: $color-secondary;
    .form__desciption-body h2,
    .form-group__title {
      color: white;
    }
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    .form__desciption-body h2,
    .form-group__title {
      color: white;
    }
  }
  @include m(black) {
    background-color: black;
    .form__desciption-body h2,
    .form-group__title {
      color: white;
    }
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }

  @include e(group-required) {
    @include make-container();
    @include make-container-max-widths();

    margin-top: 15px;
    margin-bottom: 15px;

    p {
      color: $color-storm-grey;
    }
  }

  @include e(form-el) {
    margin: 53px 0 0;
  }

  .hero {
    height: 317px;

    @include media-breakpoint-only(md) {
      height: 300px;
    }

    @include media-breakpoint-only(lg) {
      height: 268px;
    }
  }

  .form-group--default + .form-group--default {
    margin-top: 52px;

    @include media-breakpoint-only(md) {
      margin-top: 60px;
    }

    @include media-breakpoint-only(lg) {
      margin-top: 70px;
    }
  }

  .form-group--narrow + .form-group--default {
    margin-top: 52px;

    @include media-breakpoint-only(md) {
      margin-top: 60px;
    }

    @include media-breakpoint-only(lg) {
      margin-top: 70px;
    }
  }

  @include e(recaptcha-group) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 50px;
  }

  @include e(button) {
    margin-top: 56px;
    text-align: center;
  }

  @include e(error) {
    color: #ff334c;
    margin: 26px auto 0;
    font-size: 16px;
    max-height: 400px;
    text-align: center;
    line-height: 1.5;

    .icon {
      margin-right: 8px;
    }
  }

  // email opt in block
  @include e('email-opt-in') {
    display: flex;
    max-width: 700px;
    margin: auto;
    text-align: center;
    justify-content: center;
    padding: 30px 15px 0;

    .checkbox {
      display: flex;
      margin: auto;
    }
  }

  @include e('email-opt-in-checkbox') {
    margin: 0 100px;

    @include media-breakpoint-down(md) {
      margin: 0 20px;
    }
  }

  @include e('description') {
    @include make-container();
    @include make-container-max-widths();
  }

  @include e('desciption-body') {
    padding: 50px 10px 0;
    color: $color-storm-grey;
    font-size: 16px;
    line-height: 28px;
    text-align: center;

    h2 {
      color: $color-oxford-blue;
      font-family: $ted-next;
      font-size: 28px;
      font-weight: normal;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }

  @include e('extra-checkboxes') {
    @include make-container();
    @include make-container-max-widths();

    padding: 30px 15px 0;
  }

  @include e('extra-checkboxes-body') {
    margin: 0 85px;

    fieldset {
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(md) {
      margin: 0 20px;
    }
  }
}

.rc-anchor-error-msg-container {
  font-size: 11px !important;
}
