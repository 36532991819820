// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/globals/animations';
@import 'style/grid/grid';

// Component styles
@include b(support-artice-section) {
  display: flex;
  flex-direction: column;
  border-top: 1px solid map-get($colors-neutral, light);
  padding: 40px 0;
  max-width: 100%;

  .rich-text-container,
  .rich-text {
    max-width: 866px;
  }

  h2 {
    @include header-two();
    text-align: left;
    margin-top: map-get($copy-spacing, md);
    margin-bottom: map-get($copy-spacing, md);
  }
  h3 {
    @include header-three();
    margin-top: map-get($copy-spacing, md);
    margin-bottom: map-get($copy-spacing, md);
    text-align: left;
  }
  hr {
    margin: 30px 0;
  }
  pre {
    max-width: 100%;
  }
  p {
    @include primary-body();
    margin-bottom: map-get($copy-spacing, md);
    clear: both;

    img {
      display: inline-flex;
      max-width: 100%;
      min-height: 100%;
    }

    a {
      &:hover {
        text-decoration: underline;
        color: map-get($colors, secondary);
      }
    }
  }
  ol,
  ul {
    padding-left: 30px;
    margin: 15px 0;
    max-width: 100%;

    li {
      @include primary-body();
      ul,
      ol {
        margin: 0;
      }
      padding-top: 7px;
      padding-bottom: 7px;
      img {
        display: inline-flex;
        margin: 15px 0;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  .rich-text {
    @include primary-body();
  }
  .rich-text-container {
    max-width: 866px;
    overflow-y: hidden;

    a {
      color: map-get($colors, primary);
      text-decoration: none;
      &:hover {
        color: map-get($colors, secondary);
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(sm) {
      overflow-x: auto;
    }

    .rich-text {
      max-width: calc(100vw - 40px);

      img {
        max-width: 100%;
        display: inline-flex;
        &[align='left'] {
          display: inline-block;
          margin-right: map-get($copy-spacing, sm);
        }
        &[align='right'] {
          display: inline-block;
          margin-left: map-get($copy-spacing, sm);
        }
      }
    }
  }

  table {
    margin-bottom: 30px;
    border-collapse: collapse;
    table-layout: fixed;
    &.border {
      border: 1px solid;
    }
    &.left-align {
      td,
      th {
        text-align: left;
      }
    }
    tbody,
    thead {
      @include media-breakpoint-down(sm) {
        overflow-x: auto;
      }
      max-width: calc(100vw - 80px);
    }
    tr.odd,
    tr.odd td {
      background-color: white;
    }
    tr.even,
    tr.even td {
      background-color: #f9f9f9;
    }

    tr.black,
    tr.black th,
    tr.black td {
      background-color: #000;
      color: white;
    }
    td,
    th {
      font-size: 14px;
      background-color: $color-white;
      word-wrap: break-word;
      padding: 10px;
      text-align: center;
      vertical-align: middle;
    }
    td {
      font-size: 12px;
      img {
        max-width: 60%;
      }
    }
  }

  img {
    max-width: 100%;
  }

  .youtube-player__responsive-item {
    margin-bottom: map-get($copy-spacing, md);
  }

  .youtube-player__container {
    max-width: 866px;
    padding: 0;
    margin: 0;

    .youtube-player__responsive-item {
      max-width: 806px;
    }
  }
}
