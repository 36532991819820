// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Module styles

@include b(hero-and-cards) {
  $negative-margin-md: -152px;
  $negative-margin-lg: -230px;

  background: $color-pale-grey;

  .msie &,
  .msedge & {
    overflow-x: hidden;
  }

  .gradient-hero {
    @include media-breakpoint-up(md) {
      padding-bottom: $negative-margin-md * -1;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: $negative-margin-lg * -1;
    }
  }

  @include e(main-cards-container) {
    @include make-container();
    @include make-container-max-widths();
  }
  @include e(container) {
    @include make-row();

    @include media-breakpoint-only(sm) {
      &:first-child {
        .hero-and-cards__card-wrapper {
          &:first-child {
            margin-top: 0;
          }
        }
      }

      &:last-child {
        padding-bottom: 48px;
      }
    }

    @include media-breakpoint-up(md) {
      margin-top: 60px;

      &:first-child {
        margin-top: $negative-margin-md;
      }

      &:last-child {
        padding-bottom: 36px;
      }
    }

    @include media-breakpoint-only(lg) {
      margin-top: 90px;

      &:first-child {
        margin-top: $negative-margin-lg;
      }
    }
  }
  @include e(card-wrapper) {
    @include make-col-ready();
    @include make-gutters();

    padding: 0;
    height: 100%;
    .card {
      height: 100%;
    }

    @include media-breakpoint-only(sm) {
      margin-top: 75px;
    }
  }
  @include e(card-container) {
    display: flex;
    justify-content: center;

    .hero-and-cards__card-wrapper {
      @include make-col(12);
    }
  }
  @include e(cards-container) {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      .product-offer-card__short-description {
        height: 82px;
      }
    }

    .hero-and-cards__card-wrapper {
      @include make-col(12);

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }
  }
}
