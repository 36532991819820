// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';
@import 'app/components/IconBadge/IconBadge';
@import 'style/utils/bem';

@include b(form-section) {
  display: grid;
  gap: map-get($copy-spacing, sm);
  grid-template-columns: 40px 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-areas:
    'step title'
    'step container';

  // @include media-breakpoint-only(sm) {
  //   grid-template-areas:
  //     'step title'
  //     'container container';
  // }

  @include m(completed) {
  }
  @include e(step) {
    grid-area: step;
    z-index: 5;
    span {
      color: white;
      font-weight: bold;
      display: inline-block;
      line-height: 1;
      background-color: map-get($rogers-colors, primary-light);
      padding: map-get($copy-spacing, xs) map-get($copy-spacing, sm);
      border-radius: map-get($copy-spacing, xs);
    }

    @include m(completed) {
      span {
        background-color: map-get($colors-neutral, light);
        color: black;
      }
      &.form-section__step--border {
        &:after {
          grid-area: step;
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background-color: map-get($colors-neutral, light);
          transform: translateX(12px);
          z-index: 2;
        }
      }
    }
  }
  @include e(title) {
    grid-area: title;
    font-size: 24px;
    font-family: $ted-next;
    font-weight: $demibold;
    transform: translateY(2px);
    margin-bottom: map-get($copy-spacing, md);
  }
  @include e(section-title) {
    grid-area: title;
    font-size: 20px;
    font-family: $ted-next;
    font-weight: $demibold;
    transform: translateY(2px);
    margin-bottom: map-get($copy-spacing, md);
    color: map-get($colors-neutral, mediumdark);

    .form-section__container--completed & {
      margin-bottom: 0;
    }

    @include media-breakpoint-only(sm) {
      padding-left: map-get($copy-spacing, md);
    }
  }

  @include e(container) {
    z-index: 5;
    grid-area: container;
    background-color: white;
    border-radius: map-get($copy-spacing, xs);
    border: 1px solid map-get($colors-neutral, light);
    box-shadow: 0px 2px 4px #0000001a;
    padding: map-get($copy-spacing, xxl);
    margin-bottom: map-get($component-spacing, sm);

    @include media-breakpoint-only(md) {
      @media (orientation: portrait) {
        grid-column: 1 / -1;
      }
    }
    @include media-breakpoint-only(sm) {
      grid-column: 1 / -1;
      margin-bottom: map-get($component-spacing, xs);
      padding: map-get($copy-spacing, xl) map-get($copy-spacing, lg);
    }

    @include m(completed) {
      display: flex;
      align-items: center;
      .form-section__completed-value {
        max-width: 390px;
        @include media-breakpoint-down(md) {
          max-width: 290px;
          padding-left: 10px;
        }
      }

      @include media-breakpoint-only(sm) {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 'title title' 'value button';
        justify-content: flex-start;

        .form-section__section-title {
          grid-area: title;
          padding: 0;
          margin: 0 map-get($copy-spacing, sm);
        }
        .form-section__completed-value {
          max-width: none;
          grid-area: value;
          padding-left: 0;
          margin: 0 map-get($copy-spacing, sm);
        }
        .form-section__edit {
          grid-area: button;
        }
      }
    }
  }

  @include e(content) {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));

    @include m(desktop-6) {
      grid-template-columns: repeat(auto-fit, minmax(16.66%, 1fr));
    }
    @include m(desktop-5) {
      grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    }
    @include m(desktop-4) {
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    }
    @include m(desktop-3) {
      grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
    }
    @include m(desktop-2) {
      grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    }

    @include m(tablet-4) {
      @include media-breakpoint-only(md) {
        grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
      }
    }
    @include m(tablet-3) {
      @include media-breakpoint-only(md) {
        grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
      }
    }
    @include m(tablet-2) {
      @include media-breakpoint-only(md) {
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
      }
    }

    @include m(mobile-1) {
      @include media-breakpoint-only(sm) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      }
    }
  }

  @include e(completed-value) {
    margin-left: auto;
    margin-right: 15px;
  }
  @include e(edit) {
    border: 0;
    padding: map-get($copy-spacing, sm) map-get($copy-spacing, md);
    background-color: map-get($colors-neutral, light);
    color: map-get($colors, primary);
    font-family: $ted-next;
    font-weight: $demibold;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  @include e(save) {
    border: 0;
    padding: map-get($copy-spacing, sm) map-get($copy-spacing, md);
    margin-top: map-get($copy-spacing, md);
    background-color: map-get($rogers-colors, primary-light);
    color: white;
    font-family: $ted-next;
    font-weight: $demibold;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
