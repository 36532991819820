// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';
@import 'style/utils/bem';

input[type='text']::-ms-input-placeholder {
  opacity: 0;
}
input[type='text']:focus::-ms-input-placeholder {
  opacity: 1;
}

@include b(request-support-form) {
  @include e(container) {
    @include make-container();
    @include make-container-max-widths();
  }

  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );

    .request-support-form__description,
    .request-support-form__description h1,
    .request-support-form__description h2,
    .request-support-form__description h3,
    .request-support-form__description h4,
    .request-support-form__description h5,
    .request-support-form__description h6,
    .request-support-form__description p,
    .request-support-form__form legend,
    .request-support-form__form .required,
    .request-support-form__info-box {
      color: white;
    }
  }
  @include m(primary) {
    background-color: $color-primary;

    .request-support-form__description,
    .request-support-form__description h1,
    .request-support-form__description h2,
    .request-support-form__description h3,
    .request-support-form__description h4,
    .request-support-form__description h5,
    .request-support-form__description h6,
    .request-support-form__description p,
    .request-support-form__form legend,
    .request-support-form__form .required,
    .request-support-form__info-box {
      color: white;
    }
  }
  @include m(secondary) {
    background-color: $color-secondary;

    .request-support-form__description,
    .request-support-form__description h1,
    .request-support-form__description h2,
    .request-support-form__description h3,
    .request-support-form__description h4,
    .request-support-form__description h5,
    .request-support-form__description h6,
    .request-support-form__description p,
    .request-support-form__form legend,
    .request-support-form__form .required,
    .request-support-form__info-box {
      color: white;
    }
  }
  @include m(dark) {
    background-color: $color-dark-blue;

    .request-support-form__description,
    .request-support-form__description h1,
    .request-support-form__description h2,
    .request-support-form__description h3,
    .request-support-form__description h4,
    .request-support-form__description h5,
    .request-support-form__description h6,
    .request-support-form__description p,
    .request-support-form__form legend,
    .request-support-form__form .required,
    .request-support-form__info-box {
      color: white;
    }
  }
  @include m(black) {
    background-color: black;

    .request-support-form__description,
    .request-support-form__description h1,
    .request-support-form__description h2,
    .request-support-form__description h3,
    .request-support-form__description h4,
    .request-support-form__description h5,
    .request-support-form__description h6,
    .request-support-form__description p,
    .request-support-form__form legend,
    .request-support-form__form .required,
    .request-support-form__info-box {
      color: white;
    }
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  fieldset {
    border: 0;
    margin-bottom: 30px;
    legend {
      display: block;
      color: $color-body;
      font-size: 22px;
      line-height: 1.55;
      font-weight: bold;
      font-family: $ted-next;
    }
  }

  @include e(description) {
    padding: 50px 10px 0;
    color: $color-body;
    font-size: 16px;
    line-height: 28px;
    text-align: center;

    h2 {
      color: $color-black;
      font-family: $ted-next;
      font-size: 28px;
      font-weight: normal;
      line-height: 36px;
      margin-bottom: 20px;
    }

    p {
      color: $color-grey;
      font-size: 15px;
    }
  }

  @include e(form) {
    margin-top: 50px;
    p {
      color: $color-grey;
      font-size: 15px;
      &.required {
        margin-bottom: 15px;
      }
    }

    .card {
      margin: 25px 0;

      .card__container {
        padding: 40px;
        display: flex;
        .card__content {
          width: 100%;
          display: grid;
          grid-column-gap: 30px;
          grid-template-columns: repeat(2, 1fr);
          @include media-breakpoint-only(sm) {
            grid-template-columns: 1fr;
          }
        }
      }
      &.w-100 {
        .card__content {
          grid-template-columns: 1fr;
        }
      }
      &.w-auto {
        .card__content {
          grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
          @include media-breakpoint-only(sm) {
            grid-template-columns: 1fr;
          }
        }
      }
      &.list-4 {
        .card__content {
          grid-template-columns: repeat(auto-fit, minmax(22%, auto));
          @include media-breakpoint-only(sm) {
            grid-template-columns: 1fr;
          }
        }
      }
      &.columns-2 {
        .card__content {
          grid-template-columns: repeat(auto-fit, minmax(auto, 46%));
          @include media-breakpoint-only(sm) {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
  @include e(center-block) {
    text-align: center;

    .basic-icon-box__description {
      min-height: auto !important;
    }
  }
  @include e(cancel-info) {
    max-width: 570px;
    align-items: center;
    margin: 50px auto 40px auto;
    line-height: 1.6em;

    .basic-icon-box__title * {
      color: $color-black;
      font-family: $ted-next;
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      margin: 20px 0;
    }
    a:not(.button) {
      color: map-get($rogers-colors, link-light);
      text-decoration: none;
      &:hover {
        color: map-get($rogers-colors, link-light-hover);
        text-decoration: underline;
      }
    }
    .button {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include e(error) {
    color: $color-red;
    display: block;
    font-size: 12px;
    margin-top: 5px;
  }
  @include e(form-element) {
    display: flex;
    flex-direction: column;
    position: relative;

    &.text,
    &.textarea {
      label {
        position: absolute;
      }
      &.has-value label {
        font-size: 12px;
        top: -15px;
      }

      textarea {
        min-height: 120px;
        resize: none;
        border: solid 1px #d4dfe3;
        padding: 14px 20px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
        line-height: 1.5;
        border-radius: 2px;
        background-color: #fff;
      }
      input {
        transform: scale(1);
        &::-webkit-input-placeholder {
          opacity: 0;
        }
        &:focus::-webkit-input-placeholder {
          opacity: 1;
        }
        &:-ms-input-placeholder {
          opacity: 0;
        }

        &:focus:-ms-input-placeholder {
          opacity: 1;
        }
        &::placeholder {
          opacity: 0;
        }
        &:focus::placeholder {
          opacity: 1;
        }
      }
    }

    &.has-value label {
      font-size: 12px;
      top: -15px;
    }
  }

  @include e(radio-label) {
    color: map-get($colors-neutral, dark);
    padding: 10px;
    margin: 10px;
    max-width: 240px;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 20px 1fr;
    grid-template-rows: max-content 1fr;
    grid-template-areas:
      'radio label'
      'radio desc';
    cursor: pointer;

    @include media-breakpoint-only(sm) {
      max-width: none;
    }

    input[type='radio'] {
      grid-area: radio;
      align-self: top;
      transform: translateY(2px);
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: var(--form-background);
      /* Not removed via appearance */
      margin: 0;

      font: inherit;
      color: map-get($colors, primary);
      width: 1.15em;
      height: 1.15em;
      border: 1px solid map-get($rogers-colors, link-dark);
      border-radius: 50%;
      transform: translateY(-0.075em);
      cursor: pointer;
      display: grid;
      place-content: center;
    }

    input[type='radio']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em map-get($rogers-colors, primary-light);
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }

    input[type='radio']:checked::before {
      transform: scale(1);
    }

    input[type='radio']:focus {
      outline: max(0.15em, 0.15em) solid $color-outline;
      outline-offset: max(0.3em, 0.3em);
    }

    span {
      align-self: center;
      font-family: $ted-next;
      font-size: 16px;
      // font-weight: normal;
    }
    small {
      display: block;
      max-width: 240px;
      grid-area: desc;
      font-family: $ted-next;
      font-size: 14px;
    }

    @include m(single) {
      grid-template-areas: 'radio label';
      span {
        font-size: 16px;
        font-family: $ted-next;
      }
    }
  }

  @include e(checkbox-label) {
    color: map-get($colors-neutral, dark);
    padding: 10px;
    margin: 10px 0;
    display: flex;
    input[type='checkbox'] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: white;
      /* Not removed via appearance */

      font: inherit;
      color: currentColor;
      width: 1.3em;
      height: 1.3em;
      border: 1px solid map-get($rogers-colors, link-dark);
      border-radius: 0.15em;
      transform: translateY(-0.075em);

      display: grid;
      place-content: center;
    }
    input[type='checkbox']:checked {
      border-color: map-get($rogers-colors, primary-light);
      background-color: map-get($rogers-colors, primary-light);
    }
    input[type='checkbox']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em white;
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }

    input[type='checkbox']:checked::before {
      transform: scale(1);
    }

    input[type='checkbox']:focus {
      outline: max(0.15em, 0.15em) solid $color-outline;
      outline-offset: max(0.15em, 0.15em);
    }

    span {
      align-self: center;
      font-size: 14px;
      font-family: $ted-next;
      margin-left: 10px;
    }
  }

  @include e(input-label) {
    color: map-get($colors-neutral, dark);
    display: grid;
    grid-template-rows: 10px map-get($component-spacing, md) min-content;
    grid-template-areas: 'label' 'input' 'error';
    margin: map-get($copy-spacing, sm);
    padding-bottom: 10px;

    input,
    textarea {
      outline: none;
      font-size: 18px;
      grid-area: input;
      border: 1px solid map-get($colors-neutral, light);
      padding: map-get($copy-spacing, sm);
      height: map-get($component-spacing, md);
      padding-left: 15px;
      font-family: $ted-next;
      border-radius: 3px;
    }
    textarea {
      height: map-get($component-spacing, xl);
      position: relative;
      z-index: 2;
    }
    span:not(.react-date-picker__inputGroup__divider) {
      grid-area: input;
      align-self: center;
      padding-left: 15px;
      transition-timing-function: ease;
      transition: font-size 0.3s;
      font-size: 16px;
      position: relative;
      z-index: 3;
    }

    @include m(focus) {
      // focus styles
      grid-template-rows: 10px map-get($component-spacing, md) min-content;
      grid-template-areas: 'label' 'input' 'error';

      span:not(.react-date-picker__inputGroup__divider) {
        grid-area: label;
        font-size: 12px;
        transform: translateY(20px);
      }

      input,
      input:focus-visible,
      textarea,
      textarea:focus-visible {
        border: 1px solid map-get($rogers-colors, link-dark);
      }
      &.request-form__input-label--textarea {
        span {
          display: none;
        }
      }
    }
    @include m(filled) {
      // focus styles
      grid-template-rows: 10px map-get($component-spacing, md) min-content;
      grid-template-areas: 'label' 'input' 'error';

      span:not(.react-date-picker__inputGroup__divider) {
        grid-area: label;
        font-size: 12px;
        transform: translateY(20px);
      }
      &.request-form__input-label--textarea {
        span {
          display: none;
        }
      }
    }
    @include m(textarea) {
      grid-template-rows: 10px map-get($component-spacing, xl) min-content;
      grid-column: 1 / span 2;
      @include media-breakpoint-only(sm) {
        grid-column: 1 / span 1;
      }
      span:not(.react-date-picker__inputGroup__divider) {
        align-self: start;
        padding-top: map-get($copy-spacing, md);
      }

      &.request-form__input-label--focus,
      &.request-form__input-label--filled {
        span {
          padding-top: 0;
        }
      }
    }

    @include m(date) {
      span:not(.react-date-picker__inputGroup__divider) {
        width: 80%;
        position: relative;
        z-index: 1;
      }
      .react-date-picker__calendar-button,
      .react-date-picker__button {
        position: relative;
        z-index: 4;
      }
      .react-date-picker__inputGroup__divider {
        display: none;
      }

      &.request-form__input-label--filled,
      &.request-form__input-label--focus {
        .react-date-picker__inputGroup__divider {
          display: inline;
        }
      }
      .react-date-picker {
        grid-area: input;
        background-color: white;

        abbr {
          text-decoration: none;
        }
        .react-calendar__tile--now {
          background-color: $color-light-blue;
        }
        .react-calendar__tile--active {
          background-color: $color-primary;
        }
      }
      .react-date-picker__wrapper {
        width: 100%;
        border-radius: 3px;
        border: 1px solid map-get($colors-neutral, light);
      }
      .react-date-picker__inputGroup {
        transform: translateY(5px);
        input {
          grid-area: none;
          height: 62px;
          border: 0;
          font-size: 16px;
          font-weight: normal;
          font-family: $ted-next;
          color: map-get($colors-neutral, dark);
        }
        .react-date-picker__inputGroup__leadingZero {
          font-size: 16px;
          font-family: $ted-next;
          margin-right: 0;
          padding-right: 0;
        }
        .react-date-picker__inputGroup__input--hasLeadingZero {
          padding-left: 10px;
        }
        .react-date-picker__inputGroup__input {
          height: 40px !important;
          outline: none;
          background-color: white;
        }
      }

      .react-calendar,
      .react-date-picker__calendar {
        z-index: 9999;
      }
    }
  }
  @include e(select-label) {
    display: grid;
    grid-template-rows: 10px map-get($component-spacing, md) min-content;
    grid-template-areas: 'label' 'input' 'error';
    margin: map-get($copy-spacing, sm);
    select:focus-visible {
      outline: 1px solid map-get($rogers-colors, link-dark);
    }
    select {
      color: map-get($colors-neutral, dark);
      font-family: $ted-next;
      font-size: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 16px;
      grid-area: input;
      border: 1px solid map-get($colors-neutral, light);
      padding: map-get($copy-spacing, sm);
      height: map-get($component-spacing, md);
      padding-left: 15px;
      position: relative;
      z-index: 2;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI4LjA3NCIgdmlld0JveD0iMCAwIDE0IDguMDc0Ij4KICA8ZyBpZD0iSWNvbnNfTWlzY19Eb3duQXJyb3ciIGRhdGEtbmFtZT0iSWNvbnMvTWlzYy9Eb3duQXJyb3ciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMS4wNzQpIj4KICAgIDxnIGlkPSJJY29uc19NaXNjX0Rvd25BcnJvdy0yIiBkYXRhLW5hbWU9Ikljb25zL01pc2MvRG93bkFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMikgcm90YXRlKDkwKSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzg2NSIgZGF0YS1uYW1lPSJQYXRoIDg2NSIgZD0iTS4xMTYsMTEuODc3YS40NDUuNDQ1LDAsMCwxLDAtLjZMNS4wNTMsNi4wMDYuMTE2LjcyNGEuNDQ1LjQ0NSwwLDAsMSwwLS42LjM3OC4zNzgsMCwwLDEsLjU2LDBMNS44ODQsNS43QS40MjcuNDI3LDAsMCwxLDYsNmEuNDQ3LjQ0NywwLDAsMS0uMTE2LjNMLjY3NiwxMS44NjdBLjM3MS4zNzEsMCwwLDEsLjExNiwxMS44NzdaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==');
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 94% center;
      border-radius: 3px;
      option {
        font-size: 16px;
      }
    }
    span {
      display: none;
    }
  }

  @include e(error) {
    grid-column: 1/-1;
    color: map-get($colors-alert, error);
    padding-top: map-get($copy-spacing, sm);
    font-size: 12px;
    grid-area: error;
  }

  @include e(cta-button) {
    margin: 0 auto 30px auto;
    justify-self: center;

    .icon {
      margin-right: 5px;
      animation: rotation 2s infinite linear;
      text-decoration: none !important;
      border: none !important;
      &:hover {
        text-decoration: none !important;
        border: none !important;
      }
    }
  }

  @include e(info-box) {
    margin: 30px auto 50px auto;
    display: flex;
    justify-content: center;
    color: $color-dust-grey;
  }

  @include e(submit-error) {
    color: map-get($colors-alert, error);
    padding-top: 0;
    font-size: 14px;
    padding-bottom: map-get($copy-spacing, sm);
  }

  .list-3 .card__container .card__content {
    grid-template-rows: repeat(2, min-content);
    grid-template-areas: 'field1 field2 field3' 'error error error';
  }
}
