// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Component styles
@include b(collapsable-rich-text) {
  background: $color-white;
  position: relative;

  @include m(open) {
    .chevron {
      transform: translateY(2px);
    }
  }

  @include e(container) {
    @include media-breakpoint-only(lg) {
      @include make-container();
      @include make-container-max-widths();
    }

    @include media-breakpoint-down(md) {
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include e(button) {
    font-family: $ted-next;
    font-weight: $demibold;
    color: $color-black;
    width: 100%;
    height: 60px;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    background: $color-white;
    letter-spacing: 0.2px;
    align-items: center;
    justify-content: space-between;

    &:active {
      outline: none;
    }
    .chevron {
      position: relative;
      z-index: 9999;
      &::before,
      &:after {
        background-color: $color-body;
      }
    }
    &:hover {
      .chevron {
        &::before,
        &:after {
          background-color: black;
        }
      }
    }

    @include media-breakpoint-up(md) {
      height: 52px;
    }

    @include media-breakpoint-up(lg) {
      height: 60px;
      padding: 0 8px;
      justify-content: start;
    }
  }

  @include e(chevron) {
    margin-left: 10px;
    &::before,
    &:after {
      background-color: map-get($colors-neutral, black);
    }

    @include media-breakpoint-down(md) {
      margin-left: auto;
    }
  }

  @include e(content) {
    color: $color-bluish-grey;
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    max-height: 0;
    transition: max-height $default-timing;
    will-change: max-height;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }

  @include e(rich-text) {
    border-top: $border-divider;
    padding-top: 22px;
    padding-bottom: 44px;

    div.rich-text {
      margin-bottom: 15px;
      sup {
        top: -0.5em;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 24px 8px 42px;
    }

    a {
      color: map-get($rogers-colors, link-light);
      text-decoration: none;
      &:hover {
        color: map-get($rogers-colors, link-light-hover);
        text-decoration: underline;
      }
    }
  }
}
