$font-path: '/assets/fonts/';

@mixin font-face($font-family, $file-name, $file-path, $weight: normal, $style: normal, $other-format: false) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $other-format == 'full' {
      src: url('#{$file-path}#{$file-name}.eot');
      src: url('#{$file-path}#{$file-name}.eot?#iefix') format('embedded-opentype'),
        url('#{$file-path}#{$file-name}.woff') format('woff'), url('#{$file-path}#{$file-name}.ttf') format('truetype'),
        url('#{$file-path}#{$file-name}.otf') format('opentype'),
        url('#{$file-path}#{$file-name}.svg##{$font-family}') format('svg');
    } @else if $other-format == 'woff2' {
      src: url('#{$file-path}#{$file-name}.woff2') format('woff2');
    } @else {
      src: url('#{$file-path}#{$file-name}.eot');
      src: url('#{$file-path}#{$file-name}.otf') format('opentype');
    }
  }
}
@include font-face('Ted Next', 'ted-light', $font-path, 100, normal, 'woff2');
@include font-face('Ted Next', 'ted-medium', $font-path, 400, normal, 'woff2');
@include font-face('Ted Next', 'ted-regular', $font-path, 500, normal, 'woff2');
@include font-face('Ted Next', 'ted-semibold', $font-path, 600, normal, 'woff2');
@include font-face('Ted Next', 'ted-demibold', $font-path, 650, normal, 'woff2');
@include font-face('Ted Next', 'ted-bold', $font-path, 700, normal, 'woff2');

// Rogers Fonts
$ted-next: 'Ted Next';

// Font Weights
$light: 100;
$medium: 400;
$regular: 500;
$semibold: 600;
$demibold: 650;
$bold: 700;
