@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';

.footer {
  @include media-breakpoint-only(lg) {
    background-color: $color-black;
    color: $color-white;
  }

  &--white {
    @include media-breakpoint-only(lg) {
      border-top: 8px solid map-get($rogers-colors, primary-light);
      background-color: $color-white;
      color: $color-black;
      .collapsable-drawer__toggle,
      .footer__social-container-title,
      .footer__list-section-title {
        color: $color-black !important;
      }
      .footer__social-container {
        background-color: $color-white;
      }
      .footer__copyright-bar {
        border-top: 1px solid map-get($rogers-colors, grey-accent);
      }
    }
    @include media-breakpoint-down(md) {
      border-top: 8px solid map-get($rogers-colors, primary-light);
      .collapsable-drawer__toggle {
        background-color: $color-white;
        border-bottom: 1px solid $color-black;
        &:hover {
          background-color: map-get(
            $rogers-colors,
            background-medium
          ) !important;
        }
        .chevron {
          &::after {
            background-color: $color-black;
          }
          &::before {
            background-color: $color-black;
          }
        }
      }
      .collapsable-drawer__toggle,
      .footer__social-container-title,
      .footer__list-section-title {
        color: $color-black !important;
      }
      .footer__social-container {
        background-color: $color-white;
        // border-top: 1px solid $color-black;
      }
    }
  }
  &--red {
    @include media-breakpoint-only(lg) {
      background-color: map-get($rogers-colors, primary-light);
      color: $color-white;
      .footer__list-section-title {
        color: $color-white !important;
      }
      .footer__social-container {
        background-color: map-get($rogers-colors, primary-light);
      }
    }
    @include media-breakpoint-down(md) {
      .collapsable-drawer__toggle {
        background-color: map-get($rogers-colors, primary-light);
        &:hover {
          background-color: map-get(
            $rogers-colors,
            primary-light-hover
          ) !important;
        }
      }
      .footer__social-container {
        background-color: map-get($rogers-colors, primary-light);
      }
    }
  }
  &--black {
    @include media-breakpoint-only(lg) {
      background-color: $color-black;
      color: $color-white;
    }
  }

  &__container {
    .msie &,
    .msedge & {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 60px;
      padding-bottom: 80px;

      @include make-container();
      @include make-container-max-widths();
    }

    &-row {
      @include media-breakpoint-only(lg) {
        @include make-row();
      }
    }

    &-column {
      .msie &,
      .msedge & {
        padding: 0;
      }

      @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 0;
        letter-spacing: 0.2px;

        @include make-col-ready();
        @include make-col(3);
      }
    }

    &-full-width-column {
      @include media-breakpoint-up(lg) {
        @include make-col-ready();
        @include make-col(12);
      }
    }
  }

  &__list {
    padding: 30px 0;

    @include media-breakpoint-up(lg) {
      padding: inherit;
      line-height: 2em;
    }

    &-item {
      list-style: none;
    }

    &-link {
      color: inherit;
      text-decoration: none;
      display: block;

      @include media-breakpoint-up(lg) {
        display: inline-flex;
      }

      &:hover {
        text-decoration: underline;
        font-weight: 700;
      }

      .link__text {
        padding-left: 0;
      }
    }

    &-section {
      list-style: none;
      margin-bottom: 20px;
      line-height: 2em;

      &-title {
        color: $color-white;
        font-weight: 700;
        height: 35px;
        line-height: 2em;
      }

      &-list {
        list-style: none;
      }
    }
  }

  &__social-container {
    height: 142px;
    background-color: $color-black;
    color: $color-white;

    @include media-breakpoint-up(md) {
      height: 100px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 30px;
    }

    &-title {
      font-family: $ted-next;
      font-weight: $demibold;
      font-size: 20px;
      line-height: 28px;
      color: $color-white;
      padding: 16px 21px;

      @include media-breakpoint-up(md) {
        padding-left: 34px;
      }

      @include media-breakpoint-up(lg) {
        padding: 0;
      }
    }

    &-link {
      text-decoration: none;
      color: $color-cloud;

      .no-touch &:hover {
        .icon {
          &::before {
            color: $color-white;
          }
        }
      }

      & + & {
        @include media-breakpoint-up(large) {
          margin-left: 18px;
        }
      }
    }

    &-icons {
      font-size: 34px;
      padding-left: 21px;

      @include media-breakpoint-up(md) {
        margin-top: -14px;
        margin-left: 34px;
        padding: 0;
      }

      @include media-breakpoint-up(lg) {
        margin: 0;
        line-height: normal;
        margin-top: 8px;
      }

      .icon-youtube {
        @include media-breakpoint-up(large) {
          font-size: 26px;
          position: relative;
          top: -3px;
        }
      }
    }
  }

  .collapsable-drawer__toggle {
    border-radius: 0;

    @include media-breakpoint-up(lg) {
      margin-bottom: 10px;
    }

    .no-touch &:hover {
      background: $color-dark-gray;
    }
  }
}
