// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Module styles
@include b(static-data-visualization) {
  padding-top: 32px;
  padding-bottom: 66px;
  background: $color-white;

  @include media-breakpoint-up(md) {
    padding-top: 62px;
    padding-bottom: 62px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  @include e(container) {
    @include make-container();
    @include make-container-max-widths();
  }

  @include e(title) {
    @include make-col(10);

    color: $color-oxford-blue;
    margin: 0 auto;
    @include h2();
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }

  @include e(description) {
    @include make-col(10);

    color: $color-storm-grey;
    margin: 0 auto;
    display: block;
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      @include make-col(8);

      margin-top: 17px;
      line-height: 1.75;
    }
  }

  @include e(stack) {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      margin-top: 60px;
      flex-direction: row;
    }
  }

  @include e(stack-item-image) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @include e(stack-item-label) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-only(lg) {
      flex-direction: row;
    }
  }

  @include e(image) {
    text-align: center;

    img {
      max-width: 100%;

      @include media-breakpoint-only(sm) {
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }

  @include e(caption) {
    color: $color-storm-grey;
    height: 100%;
    margin: 0 auto;
    display: block;
    font-size: 16px;
    text-align: center;
    line-height: 1.5;

    @include media-breakpoint-only(sm) {
      @include make-col(10);
    }

    @include media-breakpoint-only(md) {
      @include make-col(8);
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      padding: 0 70px;
      text-align: left;
      align-items: center;
    }
  }

  .msie & {
    @include e(caption) {
      @include media-breakpoint-only(md) {
        max-width: calc(500% / 6);
        flex: auto;
      }

      @include media-breakpoint-only(md) {
        max-width: calc(200% / 3);
        flex: auto;
      }
    }
  }

  @include m(split) {
    @include e(stack) {
      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    @include e(stack-item-image) {
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }

    @include e(stack-item-label) {
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }

    @include e(image) {
      @include media-breakpoint-up(lg) {
        position: relative;
        text-align: right;
      }
    }
  }

  @include m(swapped) {
    @include e(stack) {
      flex-direction: row-reverse;
    }

    @include e(image) {
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }
  }
}
