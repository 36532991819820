// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Module styles
@include b(form-success) {
  padding-bottom: 60px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 85px;
  }

  .hero {
    height: 317px;

    @include media-breakpoint-only(md) {
      height: 300px;
    }

    @include media-breakpoint-only(lg) {
      height: 268px;
    }
  }

  @include e(inner) {
    @include make-container();
    @include make-container-max-widths();

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include e(card) {
    margin-top: 48px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-top: 72px;
    }
  }

  .card__container {
    width: 100%;
  }

  .card__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px 20px;

    @include media-breakpoint-up(md) {
      padding: 38px 40px 52px;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      padding: 32px 40px 42px;
      width: 100%;
    }
  }

  @include e(icon) {
    color: map-get($rogers-colors, primary-light);
    font-size: 38px;
    &::before {
      color: map-get($rogers-colors, primary-light);
    }
  }

  @include e(title) {
    font-family: $ted-next;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.36;
    text-align: center;
    color: $color-oxford-blue;
    margin-top: 20px;
    width: 240px;

    @include media-breakpoint-up(md) {
      line-height: 1.2;
      font-size: 20px;
      width: auto;
    }
  }

  @include e(description) {
    font-size: 18px;
    line-height: 1.33;
    text-align: center;
    color: $color-bluish-grey;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 1.5;
      margin-top: 10px;
    }
  }

  @include e(cta) {
    margin-top: 30px;

    @include media-breakpoint-up(md) {
      margin-top: 48px;
    }
  }
}
