// Application mixins
@import 'style/base/type';
@import 'style/globals/animations';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/utils/bem';
.white {
  .global-nav-promo__title,
  .global-nav-promo__description {
    color: white;
  }
}

@include b(global-nav-promo) {
  padding: map-get($copy-spacing, md) map-get($copy-spacing, lg);
  margin-bottom: auto;
  white-space: normal;
  background-color: transparent;

  @include media-breakpoint-down(md) {
    border-top: 1px solid map-get($colors-neutral, light);
  }

  @include media-breakpoint-up(md) {
    padding: map-get($copy-spacing, lg) map-get($copy-spacing, xxl);
  }
  @include media-breakpoint-up(lg) {
    padding: map-get($copy-spacing, sm) map-get($copy-spacing, xxl);
  }
  @include media-breakpoint-down(md) {
    background-color: map-get($colors-neutral, soft) !important;
  }

  @include m(left) {
    text-align: left;
    display: flex;
    align-items: center;
    @include media-breakpoint-up(lg) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @include m(center) {
    text-align: center;
    display: flex;
    align-items: center;
    .global-nav-promo__title {
      justify-content: center;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }
  }
  @include m(right) {
    display: flex;
    align-items: center;
    .global-nav-promo__content {
      text-align: right;
      max-width: 50vw;
      .global-nav-promo__title {
        max-width: 50vw;
        word-wrap: break-word;
        display: inline;
        text-align: right;
        .icon {
          margin-right: 0;
          margin-left: 5px;
        }
      }
      .link.global-nav-promo__link {
        transform: translateY(10px);
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  @include e(image) {
    img {
      max-width: 100%;
      min-width: 145px;
      @include media-breakpoint-down(md) {
        max-width: 156px;
      }
    }
    margin-bottom: map-get($copy-spacing, md);
  }
  @include e(title) {
    font-family: $ted-next;
    font-size: 15px;
    margin: 0;
    margin-bottom: 10px;
    white-space: normal;
    min-width: 200px;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    justify-self: flex-start;
    @include m(no-icon) {
      font-size: 18px;
    }
    @include media-breakpoint-up(lg) {
      align-items: flex-start;
    }

    .icon {
      margin-right: 10px;
      max-width: 40px;
      max-height: 40px;
    }
  }
  @include e(description) {
    font-family: $ted-next;
    font-size: 14px;
    margin-bottom: 10px;
    white-space: normal;
    min-width: 200px;
    max-width: 100%;
  }

  @include e(link) {
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    color: map-get($colors, primary);
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    .link__linkblock {
      display: inline;
    }
    .link__text {
      padding: 0;
    }
    &:hover {
      color: map-get($rogers-colors, link-light-hover);
      font-weight: 700;
      text-decoration: underline;
      &:after {
        margin-left: 7px;
        text-decoration: none;
      }
    }
    &:after {
      content: '\e92f';
      font-family: 'shaw-icons';
      margin-left: 5px;
      font-size: 10px;
      transition: 0.1s margin ease-in;
    }
  }

  @include m(bottom) {
    max-width: none;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      padding-bottom: map-get($copy-spacing, md);
      padding-top: map-get($copy-spacing, md);
      border-radius: 0 0 5px 5px;
    }
    .global-nav-promo__link {
      text-decoration: none;
      border: 0;
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      font-family: $ted-next;
      color: $color-white;
      line-height: 1.4em;
      font-size: 14px;
      a.global-nav-promo__link {
        .link__linkblock {
          display: inline;
        }
      }
      h4,
      p {
        margin-bottom: 0;
      }

      &:hover {
        font-weight: 500;
        p {
          text-decoration: underline;
        }
      }
      &:after {
        margin-left: auto;
      }
    }
  }
}
