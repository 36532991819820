// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

.c-promo-container {
  background: white;
  padding: 30px 0;
  @include media-breakpoint-up(md) {
    padding: 60px 0;
  }
  &--no-margin {
    padding: 0;
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
  &--background-cover {
    .c-promo {
      .c-background {
        background-size: cover !important;
      }
    }
  }
  &--background-contain {
    .c-promo {
      .c-background {
        background-size: contain !important;
      }
    }
  }
  .c-promo {
    .c-promo__title {
      margin-bottom: 20px;
    }
    .c-promo__description {
      line-height: 1.6em;

      ul,
      ol {
        margin: 10px 15px;
      }
    }
    .c-button__scotch-primary {
      margin-top: 30px;
    }
  }

  .c-promo--dark {
    .c-background {
      background-color: map-get($colors-scotch, dark);
    }
  }
  .c-promo--inset {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      padding: 0;
    }
    &.c-promo--desktop-imageLeft {
      @media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr;
        grid-template-areas: 'image content';
      }
    }
    &.c-promo--desktop-imageRight {
      @media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr;
        grid-template-areas: ' content image';
      }
    }
    &.c-promo--mobile-imageTop {
      @media only screen and (max-device-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          'image'
          'content ';
      }
    }

    &.c-promo--mobile-imageBottom {
      @media only screen and (max-device-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          'content '
          'image';
      }
    }
  }
  .c-promo--full-bleed {
    &.c-promo--desktop-imageLeft {
      @media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        grid-template-columns: 50% 50% auto;
        grid-template-rows: 1fr;
        grid-template-areas: 'image content right';
      }
      .c-promo__content {
        @include media-breakpoint-up(lg) {
          padding-right: 20px;
        }
        @include media-breakpoint-down(md) {
          margin-right: 0;
          max-width: none;
        }
      }
    }
    &.c-promo--desktop-imageRight {
      @media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        grid-template-columns: auto 50% 50%;
        grid-template-rows: 1fr;
        grid-template-areas: 'left content image';
      }

      .c-promo__content {
        @include media-breakpoint-up(lg) {
          padding-left: 20px;
        }
        @include media-breakpoint-down(md) {
          margin-left: 0;
          max-width: none;
        }
      }
    }

    &.c-promo--mobile-imageTop {
      @media only screen and (max-device-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          'image'
          'content ';
      }
    }

    &.c-promo--mobile-imageBottom {
      @media only screen and (max-device-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          'content '
          'image';
      }
    }

    .c-promo__content {
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        max-width: none;
      }
    }
    .c-background {
      max-height: none !important;
    }
    .c-responsive-image {
      @media screen and (orientation: landscape) {
        display: flex;
      }
      @include media-breakpoint-down(md) {
        display: flex;
      }
      img {
        align-self: center;
        max-width: 100% !important;
        min-width: 100%;
        @include media-breakpoint-down(md) {
          max-height: 400px;
          margin-left: auto;
          margin-right: auto;
          object-position: center;
        }
      }
    }
  }
}
