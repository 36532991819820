// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/globals/animations';
@import 'style/grid/grid';

// Component styles
@include b(simple-image) {
  .responsive-image {
    text-align: center;
  }
  img {
    max-height: 800px;
    max-width: 90%;
    display: inline-block;

    @include media-breakpoint-up(lg) {
      max-width: $lg-min-width;
    }
  }
  .link__text {
    padding: 0;
    line-height: 0;
  }

  display: flex;
  justify-content: center;
  padding: map-get($component-padding, sm) 0;

  @include media-breakpoint-up(md) {
    padding: map-get($component-padding, md) 0;
  }
  @include media-breakpoint-up(lg) {
    padding: map-get($component-padding, lg) 0;
  }

  @include m(white) {
    background-color: $color-white;
  }
  @include m(black) {
    background-color: $color-black;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
  }
  @include m(primary) {
    background-color: $color-primary;
  }
  @include m(secondary) {
    background-color: $color-secondary;
  }
}
