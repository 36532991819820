// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';
@import 'app/components/IconBadge/IconBadge';
@import 'style/utils/bem';

@import './Calendar.scss';
@import './DatePicker.scss';

@include b(contact-form) {
  @include e(all) {
    font-size: 14px;
    margin-bottom: map-get($copy-spacing, xl);
    color: map-get($colors-neutral, dark);
  }
  @include e(container) {
    @include make-container();
    @include make-container-max-widths();
    padding-bottom: map-get($component-spacing, sm);

    .button {
      display: inline-flex;
      position: relative;
      z-index: 5;
    }
  }
  @include e(intro) {
    width: 70%;
    text-align: center;
    margin: auto;
    line-height: 1.5;
    padding: map-get($component-spacing, md) 0;

    @include media-breakpoint-only(sm) {
      width: 100%;
    }
  }
  @include e(continue) {
    transform: translateX(50px);
    @include media-breakpoint-only(sm) {
      grid-column: 1 / -1;
      transform: translateX(0);
      justify-self: center;
      width: 100%;
    }
  }
  @include e(submit) {
    transform: translateX(50px);
  }
  @include e(radio-label) {
    color: map-get($colors-neutral, dark);
    padding: 10px;
    margin: 10px;
    max-width: 240px;
    display: grid;
    gap: 10px;
    grid-template-columns: 20px 1fr;
    grid-template-rows: max-content 1fr;
    grid-template-areas:
      'radio label'
      'radio desc';
    cursor: pointer;

    input[type='radio'] {
      grid-area: radio;
      align-self: top;
      transform: translateY(2px);
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: var(--form-background);
      /* Not removed via appearance */
      margin: 0;

      font: inherit;
      color: map-get($rogers-colors, primary-light);
      width: 1.15em;
      height: 1.15em;
      border: 1px solid map-get($rogers-colors, link-dark);
      border-radius: 50%;
      transform: translateY(-0.075em);
      cursor: pointer;
      display: grid;
      place-content: center;
    }

    input[type='radio']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em map-get($rogers-colors, primary-light);
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }

    input[type='radio']:checked::before {
      transform: scale(1);
    }

    input[type='radio']:focus {
      outline: max(0.15em, 0.15em) solid $color-outline;
      outline-offset: max(0.3em, 0.3em);
    }

    span {
      align-self: center;
      font-family: $ted-next;
      font-weight: $semibold;
      font-size: 18px;
    }
    small {
      display: block;
      max-width: 240px;
      grid-area: desc;
      font-family: $ted-next;
      font-size: 14px;
    }

    @include m(single) {
      grid-template-areas: 'radio label';
      span {
        font-size: 16px;
        font-family: $ted-next;
      }
    }
  }

  @include e(checkbox-label) {
    color: map-get($colors-neutral, dark);
    padding: 10px;
    margin: 10px 0;
    display: flex;
    input[type='checkbox'] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: white;
      /* Not removed via appearance */

      font: inherit;
      color: currentColor;
      width: 1.3em;
      height: 1.3em;
      border: 1px solid map-get($rogers-colors, link-dark);
      border-radius: 0.15em;
      transform: translateY(-0.075em);

      display: grid;
      place-content: center;
    }
    input[type='checkbox']:checked {
      border-color: map-get($rogers-colors, primary-light);
      background-color: map-get($rogers-colors, primary-light);
    }
    input[type='checkbox']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em white;
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }

    input[type='checkbox']:checked::before {
      transform: scale(1);
    }

    input[type='checkbox']:focus {
      outline: max(0.15em, 0.15em) solid $color-outline;
      outline-offset: max(0.15em, 0.15em);
    }

    span {
      align-self: center;
      font-size: 14px;
      font-family: $ted-next;
      font-weight: $semibold;
      margin-left: 10px;
    }
  }

  @include e(input-label) {
    color: map-get($colors-neutral, dark);
    display: grid;
    grid-template-rows: 10px map-get($component-spacing, md) min-content;
    grid-template-areas: 'label' 'input' 'error';
    margin: map-get($copy-spacing, sm);
    padding-bottom: 10px;

    input,
    textarea {
      outline: none;
      font-size: 18px;
      grid-area: input;
      border: 1px solid map-get($colors-neutral, light);
      padding: map-get($copy-spacing, sm);
      height: map-get($component-spacing, md);
      padding-left: 15px;
      font-family: $ted-next;
      border-radius: 3px;
    }
    textarea {
      height: map-get($component-spacing, xl);
      position: relative;
      z-index: 2;
    }
    span:not(.react-date-picker__inputGroup__divider) {
      grid-area: input;
      align-self: center;
      padding-left: 15px;
      transition-timing-function: ease;
      transition: font-size 0.3s;
      font-size: 16px;
      position: relative;
      z-index: 3;
    }

    @include m(focus) {
      // focus styles
      grid-template-rows: 10px map-get($component-spacing, md) min-content;
      grid-template-areas: 'label' 'input' 'error';

      span:not(.react-date-picker__inputGroup__divider) {
        grid-area: label;
        font-size: 12px;
        transform: translateY(20px);
      }
      input,
      input:focus-visible,
      textarea,
      textarea:focus-visible {
        border: 1px solid map-get($rogers-colors, link-dark);
      }
      &.contact-form__input-label--textarea {
        span {
          display: none;
        }
      }
    }
    @include m(filled) {
      // focus styles
      grid-template-rows: 10px map-get($component-spacing, md) min-content;
      grid-template-areas: 'label' 'input' 'error';

      span:not(.react-date-picker__inputGroup__divider) {
        grid-area: label;
        font-size: 12px;
        transform: translateY(20px);
      }
      &.contact-form__input-label--textarea {
        span {
          display: none;
        }
      }
    }
    @include m(textarea) {
      grid-template-rows: 10px map-get($component-spacing, xl) min-content;
      grid-column: 1 / span 2;
      @include media-breakpoint-only(sm) {
        grid-column: 1 / span 1;
      }
      span:not(.react-date-picker__inputGroup__divider) {
        align-self: start;
        padding-top: map-get($copy-spacing, md);
      }

      &.contact-form__input-label--focus,
      &.contact-form__input-label--filled {
        span {
          padding-top: 0;
        }
      }
    }

    @include m(date) {
      span:not(.react-date-picker__inputGroup__divider) {
        width: 80%;
        position: relative;
        z-index: 1;
      }
      .react-date-picker__calendar-button,
      .react-date-picker__button {
        position: relative;
        z-index: 4;
      }
      .react-date-picker__inputGroup__divider {
        display: none;
      }

      &.contact-form__input-label--filled,
      &.contact-form__input-label--focus {
        .react-date-picker__inputGroup__divider {
          display: inline;
        }
      }
      .react-date-picker {
        grid-area: input;
        background-color: white;

        abbr {
          text-decoration: none;
        }
        .react-calendar__tile--now {
          background-color: $color-light-blue;
        }
        .react-calendar__tile--active {
          background-color: $color-primary;
        }
      }
      .react-date-picker__wrapper {
        width: 100%;
        border-radius: 3px;
        border: 1px solid map-get($colors-neutral, light);
      }
      .react-date-picker__inputGroup {
        transform: translateY(5px);
        input {
          grid-area: none;
          height: 62px;
          border: 0;
          font-size: 16px;
          font-weight: normal;
          font-family: $ted-next;
          color: map-get($colors-neutral, dark);
        }
        .react-date-picker__inputGroup__leadingZero {
          font-size: 16px;
          font-family: $ted-next;
          margin-right: 0;
          padding-right: 0;
        }
        .react-date-picker__inputGroup__input--hasLeadingZero {
          padding-left: 10px;
        }
        .react-date-picker__inputGroup__input {
          height: 40px !important;
          outline: none;
          background-color: white;
        }
      }

      .react-calendar,
      .react-date-picker__calendar {
        z-index: 9999;
      }
    }
  }

  @include e(input) {
    @include m(phone) {
      input {
        ::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #fff;
        }
        :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #fff;
          opacity: 1;
        }
        ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #fff;
          opacity: 1;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #fff;
        }
      }
    }
  }

  @include e(select-label) {
    display: grid;
    grid-template-rows: 10px map-get($component-spacing, md) min-content;
    grid-template-areas: 'label' 'input' 'error';
    margin: map-get($copy-spacing, sm);
    select:focus-visible {
      outline: 1px solid map-get($rogers-colors, link-dark);
    }
    select {
      color: map-get($colors-neutral, dark);
      font-family: $ted-next;
      font-size: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 16px;
      grid-area: input;
      border: 1px solid map-get($colors-neutral, light);
      padding: map-get($copy-spacing, sm);
      height: map-get($component-spacing, md);
      padding-left: 15px;
      position: relative;
      z-index: 2;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI4LjA3NCIgdmlld0JveD0iMCAwIDE0IDguMDc0Ij4KICA8ZyBpZD0iSWNvbnNfTWlzY19Eb3duQXJyb3ciIGRhdGEtbmFtZT0iSWNvbnMvTWlzYy9Eb3duQXJyb3ciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMS4wNzQpIj4KICAgIDxnIGlkPSJJY29uc19NaXNjX0Rvd25BcnJvdy0yIiBkYXRhLW5hbWU9Ikljb25zL01pc2MvRG93bkFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMikgcm90YXRlKDkwKSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzg2NSIgZGF0YS1uYW1lPSJQYXRoIDg2NSIgZD0iTS4xMTYsMTEuODc3YS40NDUuNDQ1LDAsMCwxLDAtLjZMNS4wNTMsNi4wMDYuMTE2LjcyNGEuNDQ1LjQ0NSwwLDAsMSwwLS42LjM3OC4zNzgsMCwwLDEsLjU2LDBMNS44ODQsNS43QS40MjcuNDI3LDAsMCwxLDYsNmEuNDQ3LjQ0NywwLDAsMS0uMTE2LjNMLjY3NiwxMS44NjdBLjM3MS4zNzEsMCwwLDEsLjExNiwxMS44NzdaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==');
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 94% center;
      border-radius: 3px;
      option {
        font-size: 16px;
      }
    }
  }
  @include e(error) {
    grid-column: 1/-1;
    color: map-get($colors-alert, error);
    padding-top: map-get($copy-spacing, sm);
    font-size: 12px;
    @include m(single) {
      grid-area: error;
    }
  }
  @include e(checkboxes) {
    margin: map-get($copy-spacing, sm);
    label + label {
      margin-top: map-get($copy-spacing, md);
    }
  }
  @include e(move-box) {
    grid-column: 1 /-1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #fafafa;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    @include media-breakpoint-only(sm) {
      grid-template-columns: 1fr;
    }
  }

  @include e(submission-error) {
    color: $color-red;
    padding: map-get($copy-spacing, lg) 0;
    margin-left: 60px;
  }
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
::placeholder {
  font-size: 16px !important;
  color: map-get($colors-neutral, dark);
}

/* Internet Explorer 10-11 */
:-ms-input-placeholder {
  font-size: 16px !important;
  color: map-get($colors-neutral, dark);
}

/* Microsoft Edge */
::-ms-input-placeholder {
  font-size: 16px !important;
  color: map-get($colors-neutral, dark);
}

:not(:focus)::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}
:not(:focus):-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent;
}
:not(:focus)::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
}
:not(:focus):-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
}
