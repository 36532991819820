// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b('case-study-articles-list') {
  background: transparent;

  @include e('title') {
    text-align: center;
    padding: 20px 0;

    h2 {
      font-size: 20px;
      font-weight: 500;
      font-family: $ted-next;
    }
  }

  @include e('wrapper') {
    padding: 50px 0 0;
    background-color: $color-white;
  }

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();

    @include media-breakpoint-only(sm) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @include e('list') {
    position: relative;
    margin: 20px 0 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 30px;
    grid-row-gap: 80px;

    @include media-breakpoint-down(md) {
      grid-gap: 15px;
      grid-row-gap: 40px;
      margin-top: 0;
    }

    @include media-breakpoint-only(sm) {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }

  @include e('study-article') {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    // height: 100%;
    align-items: start;

    &:nth-of-type(2n) {
      &.featured {
        grid-column: span 2;

        @include media-breakpoint-only(sm) {
          grid-column: 1 / span 1;
        }

        + .study-article {
          grid-column: 2 / span 2;
        }

        + .study-article.featured {
          grid-column: 1 / span 2;

          @include media-breakpoint-only(sm) {
            grid-column: 1 / span 1;
          }
        }
      }
    }

    &:nth-of-type(2n + 1) {
      &.featured {
        grid-column: span 2;

        @include media-breakpoint-only(sm) {
          grid-column: 1 / span 1;
        }
      }
    }
  }

  @include e('study-body') {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    &.featured {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr repeat(4, min-content);
      align-items: start;
      background-color: $color-feature-bgr;

      @include media-breakpoint-only(md) {
        grid-template-columns: 36% 64%;
      }

      @include media-breakpoint-only(sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
      }
    }
  }

  @include e('study-img') {
    padding: 0 0 40px;
    justify-self: flex-start;
    margin-bottom: auto;

    .featured & {
      padding: 0;
      grid-column: 1 / 2;
      grid-row: 1 / -1;
      height: 100%;

      @include media-breakpoint-only(sm) {
        width: 100%;
        padding-bottom: 30px;
      }

      img.img {
        object-fit: cover;
        opacity: 1;
        max-height: none;
      }
    }

    a {
      height: 100%;
      width: 100%;
      display: flex;
      position: relative;
    }

    .img {
      display: block;
      width: 100%;
      max-height: 100%;
      opacity: 0.4;
      @include media-breakpoint-only(md) {
        min-height: 236px;
        max-height: 236px;
      }
      @include media-breakpoint-only(lg) {
        min-height: 356px;
        max-height: 356px;
      }
    }

    .logo {
      position: absolute;
      display: inline-block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
      z-index: 999;
    }
  }

  @include e('study-logo') {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 140px;

    img {
      display: block;
      max-width: 90%;
      margin-top: 30px;
    }

    .featured & {
      grid-column: 2 / -1;
      grid-row: 1 / 2;
    }
  }

  @include e('study-title') {
    padding: 30px 0 10px;

    h3 {
      font-size: 32px;
      font-weight: 400;
      font-family: $ted-next;
      text-align: center;
      color: $color-promise-grey;
    }

    .featured & {
      grid-column: 2 / -1;
      grid-row: 2 / 3;
    }
  }

  @include e('study-category') {
    text-align: center;
    padding: 0 0 30px;
    text-transform: uppercase;
    letter-spacing: 2px;

    .featured & {
      grid-column: 2 / -1;
      grid-row: 3 / 4;
    }

    h5 {
      margin-bottom: 0;
      color: $color-promise-grey;
    }
  }

  @include e('study-desc') {
    padding: 0 50px 40px;
    text-align: center;

    @include media-breakpoint-down(md) {
      padding: 0 25px 20px;
    }

    p {
      line-height: 1.6;
      font-size: 18px;
      color: $color-oxford-blue;
    }

    .featured & {
      grid-column: 2 / -1;
      grid-row: 4 / 5;
    }
  }

  @include e('study-cta') {
    padding: 0 0 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    display: flex;

    @include media-breakpoint-down(md) {
      margin-top: auto;
    }

    .featured & {
      grid-column: 2 / -1;
      grid-row: 5 / -1;
    }

    .button {
      @include media-breakpoint-down(md) {
        margin: auto;
      }
    }
  }
}
