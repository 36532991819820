// Globals
@import 'globals/colors';
@import 'globals/spacing';

// Utils
@import 'utils/bem';
@import 'utils/responsive';

// Base
@import 'base/reset';
@import 'base/type';

// Grid
@import 'grid/grid';

body {

  font-family: $ted-next;
  font-weight: $medium;

  [data-lastpass-icon-root='true'] {
    display: none !important;
  }

  &.light-grey-bg {
    background-color: $default-bg-color;
  }
  &.white-bg {
    background-color: white;
  }

  &.noscroll {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
  }
  sup {
    top: 0;
  }

  .c-four-up-item sup,
  .c-disclaimer__content sup {
    top: -0.6em;
  }

  .c-disclaimer-cta {
    transform: translate(2px, -0.4em);
    letter-spacing: normal;
    top: -0.4em;
    &:hover {
      text-decoration: none;
    }
  }
}

@include b(app) {
  color: black;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  overflow-x: hidden;

  .msie &,
  .msedge & {
    overflow-x: hidden;
  }

  @include m(noscroll) {
    overflow: hidden;
  }
}

.container {
  width: 1200px;
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;

  @include media-breakpoint-only(sm) {
    padding: 0;
  }
}

a:focus,
.checkbox__label:focus,
.radio-group__label:focus {
  outline: $color-outline solid 3px !important;
  outline-offset: 2px;
}

.footer {
  // sticky footer
  margin-top: auto;
}

.sm-hidden {
  @include media-breakpoint-only(sm) {
    display: none !important;
  }
}
.md-hidden {
  @include media-breakpoint-only(md) {
    display: none !important;
  }
}
.lg-hidden {
  @include media-breakpoint-only(lg) {
    display: none !important;
  }
}
.st0 {
  stroke: transparent !important;
}
