// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Component requirements
@import '../GlobalNav';

// Module styles
@include b(global-nav-menu) {
  font-family: $ted-next;
  position: relative;

  @include e(items) {
    list-style-type: none;
    min-width: 450px;
    @include media-breakpoint-down(md) {
      padding-top: 10px;
    }
    @include media-breakpoint-down(sm) {
      min-width: 300px;
    }
  }
  @include e(input) {
    display: block;
    padding: 0 10px 10px 10px;
    .region-selector__location {
      height: 48px;
      background-color: map-get($colors-neutral, light);
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 10px;
      border-radius: 5px;
      color: $color-black;
      svg {
        margin-right: 5px;
      }
    }
    .search-bar {
      border-radius: 5px;
      background-color: map-get($colors-neutral, light);

      .icon.icon-Nav_Search {
        margin-left: 0;
      }
    }
  }
  // Desktop styles
  @include media-breakpoint-up(lg) {
    flex: 1;
    height: 100%;
    margin-left: 10px;

    @include e(items) {
      height: 100%;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
    }

    @include e(search) {
      display: none;
    }

    @include e(item) {
      height: 100%;
      &:last-child {
        display: none;
      }

      @include m(scotch) {
        .region-selector__location {
          &:hover {
            color: map-get($rogers-colors, primary-light);
          }
        }
        // .global-nav-dropdown__label {
        //   &.global-nav-dropdown__label--expanded,
        //   &:hover {
        //     font-weight: $demibold;
        //   }
        // }
        .global-nav-dropdown--selected .global-nav-dropdown__label:after {
          background: map-get($rogers-colors, primary-light);
        }
        .global-nav-dropdown--selected .global-nav-dropdown__drawer {
          border-bottom-color: map-get($rogers-colors, primary-light);
        }

        .global-nav-dropdown__link a {
          letter-spacing: 0px;
          font-family: $ted-next;

          color: map-get($rogers-colors, body);

          &:hover {
            font-weight: $medium;
            color: map-get($rogers-colors, primary-light);
          }
          &.is-active {
            font-weight: $demibold;
            color: map-get($rogers-colors, primary-light);
          }
        }
        .global-nav-menu__link {
          outline: none;
          &:hover {
            color: map-get($rogers-colors, primary-light-hover);
            font-weight: $medium;
          }
        }
      }
    }

    @include e(input) {
      display: flex;
      height: 30px;
    }
    @include e(input) {
      display: none;
    }

    @include e(additional-links) {
      display: none;
    }
  }

  // Touch styles
  @include media-breakpoint-down(md) {
    $transition-slide: 100ms;
    $transition-fade: 100ms;

    top: 100%;
    right: 0;
    height: 0;
    z-index: 100;
    position: absolute;
    background: $color-white;
    overflow-x: hidden;
    overflow-y: scroll;
    opacity: 0;
    transition: opacity $transition-fade ease-in;

    .search-bar__input-wrapper {
      color: $color-black;
      border-bottom: none;
      margin-left: 14px;
      height: 58px;
      padding-bottom: 10px;

      @include media-breakpoint-only(md) {
        height: 48px;
        padding-bottom: 0;
      }
      @include media-breakpoint-only(sm) {
        border: 0;
        margin-left: 6px;
        height: 48px;
        padding-bottom: 0;
      }

      .search-bar__close-button {
        margin-right: 15px;

        &::before,
        &::after {
          background-color: $color-black;
        }
      }

      .search-bar__input {
        color: $color-black;
        display: block;
        align-items: center;
        padding-top: 2px;

        &::placeholder {
          color: $color-black;
        }
      }
    }

    .search-bar {
      color: $color-black;

      .icon-Nav_Search {
        color: $color-black;
        margin-left: 22px;
      }
    }

    .link__linkblock {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
    }

    @include e(items) {
      list-style-type: none;

      @include media-breakpoint-only(sm) {
        width: 100vw;
      }
    }

    @include e(item) {
      border-top: 1px solid map-get($colors-neutral, light);

      &:last-child {
        padding: 30px 0 calc(100% / 4);
        display: flex;
        flex-direction: column;

        @media (min-width: 330px) {
          padding: 30px 0 225px;
        }
      }

      @include m(scotch) {
        .region-selector__location {
          &:hover {
            color: map-get($rogers-colors, primary-light);
          }
        }
        // .global-nav-dropdown__label {
        //   &.global-nav-dropdown__label--expanded,
        //   &:hover {
        //     font-weight: $demibold;
        //   }
        // }
        .global-nav-dropdown--selected .global-nav-dropdown__label:after {
          background: map-get($rogers-colors, primary-light);
        }
        .global-nav-dropdown--selected .global-nav-dropdown__drawer {
          border-bottom-color: map-get($rogers-colors, primary-light);
        }

        .global-nav-dropdown__link a {
          letter-spacing: 0px;
          font-family: $ted-next;
          color: map-get($rogers-colors, body);

          &:hover {
            color: map-get($rogers-colors, primary-light);
          }
          &.is-active {
            font-weight: $demibold;
            color: map-get($rogers-colors, primary-light);
          }
        }
        .global-nav-menu__personal-site-link,
        .global-nav-menu__link {
          outline: none;
          &:hover,
          &:focus {
            color: map-get($rogers-colors, primary-light-hover);
          }
        }
      }
    }

    @include e(additional-links) {
      margin: 30px 0;

      @include media-breakpoint-down(md) {
        .global-nav-menu__link.is-active {
          color: map-get($rogers-colors, link-light);
        }
      }
    }

    @include e(link) {
      display: block;
      color: $color-black;
      padding: 0 map-get($copy-spacing, lg);
      font-size: 14px;
      line-height: 40px;
      font-weight: $medium;

      .link__text {
        padding: 0 !important;
      }

      &:hover {
        color: map-get($rogers-colors, link-light-hover);
        text-decoration: underline;
      }
      &.is-active {
        font-weight: $demibold;
      }

      @include media-breakpoint-only(md) {
        padding: 0 40px;

        &:first-child {
          padding: 0 40px;
        }

        &:last-child {
          padding: 0 40px;
        }
      }
    }

    @include e(personal-site-link) {
      display: block;
      padding: 0 map-get($copy-spacing, sm);
      font-size: 14px;
      font-weight: $medium;
      color: $color-primary-cta;
      cursor: pointer;

      &:hover {
        color: map-get($rogers-colors, link-light-hover);
        text-decoration: underline;
      }

      @include media-breakpoint-only(md) {
        padding: 0 40px 12px;
      }

      @include media-breakpoint-only(lg) {
        display: none;
      }
    }

    @include m(enter) {
      height: 100vh;
      opacity: 1;
    }
  }
}
