// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.white {
  .slider-arrow {
    .icon {
      &:before {
        color: white !important;
      }
    }
  }
  .global-alert-message__message {
    color: white;
    a {
      color: white;
      &:hover {
        color: white !important;
        text-decoration: underline;
      }
    }
  }
}

.dark {
  .icon-Link_Alert {
    &:before {
      color: black !important;
    }
  }
  .slider-arrow {
    .icon {
      &:before {
        color: black !important;
      }
    }
  }
  .global-alert-message__message {
    color: black;
    a {
      color: black;
      &:hover {
        color: black !important;
        text-decoration: underline;
      }
    }
  }
}

@include b(global-alert-message-group) {
  background-color: $color-primary;
  max-width: 100vw;
  position: relative;

  .global-alert-message-group-carousel {
    @include make-container();
    @include make-container-max-widths();
    overflow: hidden;

    .global-alert-message {
      background: none;
    }
  }
  .slider-arrow {
    z-index: 999999;
    &--disabled {
      visibility: hidden;
    }
    .icon {
      &:before {
        font-size: 12px;
        font-weight: normal;
        color: $color-white;
      }
    }
  }

  @include m(red) {
    background-color: map-get($rogers-colors, primary-light);
    @extend .white;
  }
  @include m(rogers) {
    background-color: map-get($rogers-colors, primary-light);
    @extend .white;
  }
  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
    @extend .dark;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
    @extend .dark;
  }
  @include m(white) {
    background-color: #fff;
    @extend .dark;
  }
  @include m(transparent) {
    background-color: transparent;
    @extend .dark;
  }
}

//
