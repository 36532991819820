// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';
@import 'app/components/IconBadge/IconBadge';
.white {
  color: white;
}
// Module styles
.full-bleed-image-card {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  padding: map-get($component-spacing, sm) 0;

  @include media-breakpoint-only(sm) {
    grid-template-rows: 200px 128px max-content;
    grid-template-columns: 24px 20px auto 20px 24px;
  }

  @include media-breakpoint-up(md) {
    grid-template-rows: 208px 208px max-content;
    grid-template-columns: 30px 50px auto 50px 30px;
    padding: map-get($component-spacing, md) 0;
  }

  @include media-breakpoint-up(lg) {
    grid-template-rows: 232px 188px max-content;
    grid-template-columns: 1fr 86px 988px 86px 1fr;
    padding: map-get($component-spacing, lg) 0;
  }

  background-color: transparent;
  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  .full-bleed-background {
    grid-row: 1 / span 2;
    grid-column: 2 / span 3;
  }

  &__product-add-on-card {
    grid-row: 2 / span 2;
    grid-column: 3 / span 1;
  }
}
