// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';
@import 'style/utils/bem';

@include b(legal-disclaimer) {
  margin-bottom: 15px;

  sup {
    top: -0.5em;
  }
}
