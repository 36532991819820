// Application mixins
@import 'style/globals/colors';
@import 'style/grid/breakpoints';

// Component variables
$icon-size: 70px;
$full-icon-size: 64px;

// Component styles
.icon-badge {
  width: $icon-size;
  height: $icon-size;
  display: flex;
  padding: 12px;
  font-size: 30px;
  background: $color-white;
  box-shadow: 0 0 2px 1px rgba($color-black, 0.1);
  align-items: center;
  border-radius: 50%;
  justify-content: center;

  &.no-icon-border {
    border-radius: 0;
    box-shadow: none;
    border: 0;

    > span {
      &::before {
        font-size: $full-icon-size;
      }
    }
  }

  @include media-breakpoint-only(md) {
    width: $icon-size;
    height: $icon-size;
  }

  @include media-breakpoint-only(lg) {
    width: $icon-size;
    height: $icon-size;
    font-size: 34px;
  }
}
