// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.white {
  color: white;
}

@include b(demo) {
  background: white;
  padding: 60px 0;
  @include e(container) {
    @include make-container();
    @include make-container-max-widths();
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, auto));
    @include media-breakpoint-only(sm) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(auto-fit);
      grid-template-areas: 'light1' 'light2' 'dark1' 'dark2' 'link1' 'link2';
      .demo-item--white:nth-child(1) {
        grid-area: light1;
      }
      .demo-item--white:nth-child(2) {
        grid-area: light2;
      }
      .demo-item--black:nth-child(1) {
        grid-area: dark1;
      }
      .demo-item--black:nth-child(2) {
        grid-area: dark2;
      }
    }
  }
}

@include b(demo-item) {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > * {
    margin-bottom: 20px;
  }
  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  @include e(title) {
    margin-bottom: 20px;
    font-weight: bold;
  }
}
