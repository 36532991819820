// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

@include b(search-results) {
  background-color: $color-white;

  @include e(container) {
    @include make-container();
    @include make-container-max-widths();
  }

  @include e(headline) {
    font-family: $ted-next;
    font-size: 36px;
    line-height: 1.39;
    font-weight: 100;
    letter-spacing: -1px;
    color: $color-oxford-blue;
    margin: 42px 0 0;
  }

  @include e(counter) {
    font-family: $ted-next;
    font-size: 12px;
    font-weight: 500;
    color: $color-dust-grey;
  }

  @include e(results-container) {
    padding: 42px 0;
  }

  @include e(result-item) {
    list-style: none;

    & + & {
      border-top: solid 1px rgba(212, 212, 212, 0.5);
      margin-top: 24px;
      padding-top: 24px;
    }
  }

  @include e(result-title) {
    margin-bottom: 5px;
  }

  @include e(result-link) {
    font-family: $ted-next;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-azure;
    text-decoration: none;

    .no-touch &:hover {
      color: $color-azure-dark;
    }
  }

  @include e(result-description) {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $color-bluish-grey;
  }

  @include e('message') {
    padding: 42px 20px;
    font-size: 16px;
    line-height: 24px;
    color: $color-bluish-grey;

    h3 {
      margin: 0 0 20px;
      font-family: $ted-next;
      font-size: 36px;
      font-weight: 100;
      color: $color-oxford-blue;
      line-height: normal;
    }

    h4 {
      margin: 0 0 20px;
      font-family: $ted-next;
      font-size: 20px;
      font-weight: 500;
      color: $color-oxford-blue;
      line-height: normal;
    }

    ul {
      list-style-type: disc;
      padding-left: 15px;
    }
  }
}
