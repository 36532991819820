// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Module styles
@include b('simple-icon-carousel') {
  background-color: $color-white;

  @include e('wrapper') {
    padding: 0 0 50px;
    background-color: $color-white;
  }

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();
  }

  @include e('items') {
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 50px 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    @include media-breakpoint-only(sm) {
      padding: 0;
    }

    > div:not(.simple-icon-carousel__item) {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    .carousel__slide {
      flex: 1;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        border-left: 1px solid $color-geyser;
      }

      &:first-child {
        border-left-color: transparent;
        border-top-color: transparent;
      }
    }
  }

  @include e('item') {
    display: flex;
    width: 200px;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    flex: 1;
    border-top: 1px solid $color-geyser;

    @include media-breakpoint-up(md) {
      width: 280px;
      border-top-color: transparent;
      border-left: 1px solid $color-geyser;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 60px;
      justify-self: stretch;
    }

    &:first-child {
      border-left-color: transparent;
      border-top-color: transparent;
    }

    .slick-slide & {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.5em;
    }

    .icon-badge {
      display: flex;
      align-items: flex-end;
      width: 120px;
      height: 120px;

      svg {
        object-fit: contain;
      }
    }
  }

  @include e('item-description') {
    font-family: $ted-next;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-top: 1em;
    min-height: 46px;
    color: $color-oxford-blue;
    margin-bottom: auto;
  }
}
