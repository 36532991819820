// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

@include b('wysdom-search') {
  background-color: $color-white;
  position: relative;
  padding-bottom: 30px;
  font-family: $ted-next;

  @include e('hero-bg') {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
  }

  @include e('init') {
    background: #fff;
    height: 64px;
    flex-grow: 1;
    box-sizing: border-box;
    box-shadow: 0 2px 10px 0 rgba(205, 143, 143, 0.2);
    border-radius: 32px;
    overflow-wrap: break-word;
    text-align: center;
    line-height: 64px;
  }

  @include e('results-header') {
    @include make-container();
    @include make-container-max-widths();

    margin: 80px auto 75px;

    h1 {
      color: $color-white;
      @include h1();
    }

    @include media-breakpoint-down(sm) {
      margin: 60px auto 65px;
    }
  }

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();

    padding: 170px 0 0;

    @include media-breakpoint-down(sm) {
      padding: 125px 0 0;
    }
  }

  @include e('bar') {
    position: relative;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      padding: 0 10px;
    }
  }

  @include e('results') {
    padding: 20px 20px 0;
  }

  .wysdom-search-result-pagination-next,
  .wysdom-search-result-pagination-prev {
    background-color: map-get($rogers-colors, primary-light);
    &:hover {
      background-color: map-get($rogers-colors, primary-light-hover);
      opacity: 0.95;
    }

    &:disabled {
      color: map-get($rogers-colors, body);
      pointer-events: none;
    }
  }
}

.wysdom-search
  .wysdom-solution-list-container
  .wysdom-solution-preview
  .wysdom-solution-preview-header {
  color: $color-primary;
  @include h3();
  text-align: left;
  font-weight: 700;
  line-height: 1.25em;

  &:hover {
    text-decoration: underline;
  }
}

.wysdom-search
  .wysdom-search-form
  .wysdom-search-input-group
  .wysdom-search-icon {
  background-color: map-get($rogers-colors, primary-light);

  &:hover {
    background-color: map-get($rogers-colors, primary-light-hover);
  }
}
.wysdom-search-bar-wysdom-search-bar-input,
.wysdom-search-bar-wysdom-search-bar-input :placeholder-shown,
.wysdom-solution-section-header,
.wysdom-solution-preview-excerpt  {
  font-family: $ted-next !important;
}
