// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.c-grand-hero--white {
  .c-grand-hero__title {
    sup {
      position: static;
      transform: none;
      vertical-align: top;
      text-decoration: underline;
    }
  }
}
.c-grand-hero {
  z-index: 99;
  min-height: 400px;
  justify-items: stretch;
  align-items: stretch;
  max-height: none !important;
  @include media-breakpoint-up(md) {
    max-height: none !important;
  }
  @media screen and (min-width: 1280px) {
    .c-responsive-image {
      grid-column: span 2 !important;
    }
  }

  &.c-grand-hero--60-40 {
    @include media-breakpoint-up(lg) {
      grid-template-columns: auto 60% 40% auto;
    }
  }

  .c-responsive-image {
    @media screen and (min-width: 540px) and (max-width: 767px) {
      max-height: 380px !important;
      img {
        max-height: 380px !important;
        object-position: top !important;
      }
    }
    /* iPad Mini in portrait only */
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      height: 100% !important;
      max-height: none !important;
      img {
        height: 100% !important;
        max-height: none !important;
      }
    }
    @include media-breakpoint-up(md) {
      justify-self: end;
      img {
        justify-self: center;
      }
    }
  }
  .c-background {
    max-height: none;
  }
  .c-grand-hero__content-container {
    .c-grand-hero__button-container {
      display: flex;
      gap: 15px;
      align-items: flex-start;

      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    @include media-breakpoint-only(sm) {
      min-height: 400px;
      padding: 24px 12px;

      .c-grand-hero__app-store-container {
        justify-content: center;
        max-width: 98%;
      }
      .c-grand-hero__button-container {
        align-items: center;
        justify-content: center;
        max-width: 98%;
      }

      a {
        margin: 0;
      }
      a + a {
        margin: 0;
      }
    }
  }
  .c-grand-hero__content-container--center {
    .c-grand-hero__app-store-container {
      justify-content: center;
    }
  }
  .c-grand-hero__title,
  .c-grand-hero__subtitle {
    margin-bottom: 4px;
    word-break: keep-all;
  }
  .c-grand-hero__title {
    span + span {
      display: block;
    }
  }
}
